import React, {useEffect, useState} from 'react';
import './VideoControlWrap.styles.css';
import {Text} from '../../atoms/Text';
import {Colors} from '../../../configs/colors';
import {ReactComponent as CaretDownImg} from '../../../assets/images/caret-down.svg';
import {ReactComponent as CaretUpImg} from '../../../assets/images/caret-up.svg';
import {Button, ButtonVariant} from '../../atoms/Button';
import {VideoControlProps} from './VideoControlWrap.props';

export const VideoControlWrap: React.FC<VideoControlProps> = ({
  sessionDuration,
  currentBattery,
  onShow,
  isShowSessionDetails
}) => {
  const [showSessionDetails, setShowSessionDetails] = useState(false);
  useEffect(()=>{
    if (isShowSessionDetails) {
      setShowSessionDetails(!showSessionDetails);
    }
  }, [isShowSessionDetails]);

  const handleShowMore = () => {
    setShowSessionDetails(!showSessionDetails);
    onShow(!showSessionDetails);
  };
  return (
    <div className="video-control-wrap">
      <div className="watch-me-timer-session-three-column">
        <Text fontSize={12} fontWeight="400px" color={Colors.LSTealAccent}>
          Session Duration
        </Text>
        <Text fontSize={15} fontWeight="500px" color={Colors.LSWhite}>
          {sessionDuration}
        </Text>
      </div>
      <div className="watch-me-timer-session-three-column">
        <Text fontSize={12} fontWeight="400px" color={currentBattery > 20 ? Colors.LSTealAccent : Colors.RCCriticalBG}>
          Battery
        </Text>
        <Text fontSize={15} fontWeight="500px" color={currentBattery > 20 ? Colors.LSWhite : Colors.RCCriticalBG}>{`${currentBattery}%`}</Text>
      </div>
      <div className="watch-me-timer-session-column-button-wrap">
        <Text fontSize={12} fontWeight="400px" margin="0 8px 0 " color={Colors.LSTealAccent}>
          More
        </Text>
        <Button variant={ButtonVariant.Icon} onClick={handleShowMore} color={Colors.NeutralGrey1}>
          {!showSessionDetails && <CaretDownImg stroke={Colors.LSWhite} width={20} height={20} />}
          {showSessionDetails && <CaretUpImg stroke={Colors.LSWhite} width={20} height={20} />}
        </Button>
      </div>
    </div>
  );
};
