import React, {useEffect, useState} from 'react';
import './NotesPanel.styles.css';
import {NotesPanelProps} from '.';
import CheckCircle from '../../../assets/images/check-circle.svg';
import {Button, ButtonVariant} from '../../atoms/Button';
import {Text} from '../../atoms/Text';
import {Colors} from '../../../configs/colors';
import {Image} from '../../atoms/Image';
import {NoteControl} from '../../organisms/NoteControl';
import {NoteItem} from './NotesPanel.props';
import {DropdownSelect} from '../../molecules/DropdownSelect';
import {incidentOptionList, statusOptionList} from '../../../constants/appConstantData';

export const NotesPanel: React.FC<NotesPanelProps> = ({
  data,
  onAddNotes,
  onSelectStatus,
  onSelectIncident,
  selectedStatusValue,
  selectedResolutionValue
}) => {
  const [noteValue, setNoteValue] = useState<string>('');
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true);
  const [isShowNoteTitle, setShowNoteTitle] = useState<boolean>(false);

  const handleAddNote = () => {
    if(onAddNotes){
      onAddNotes(noteValue);
    }
  };

  const handleNoteOnchangeValue = (event: any) => {
    setNoteValue(event.target.value);
    event.target.value ? setDisabledBtn(false) : setDisabledBtn(true);
  };
  const handleNoteOnFocus = (event: any) => {
    if (event.target.value === '')
      setShowNoteTitle(true);
  };
  const handleNoteOnBlur = (event: any) => {
    if (event.target.value === '')
      setShowNoteTitle(false);
  };

  const handelOnSearchStatus = (value: string) => {
    onSelectStatus && onSelectStatus(value);
  };
  const handelOnSearchIncident = (value: string) => {
    onSelectIncident && onSelectIncident(value);
  };

  useEffect(() => {
    setNoteValue('');
  }, [data]);

  return (
    <div className="notes-panel-style">
      <div className="notes-panel-header">
        <div className="note-dropdown-status">
          <DropdownSelect onSelected={handelOnSearchStatus} selectedItemValue={selectedStatusValue} padding="8px" title="Status:" backgroundColor="white" height="48px" optionsList={statusOptionList} selectImage />
        </div>
        <div className="note-dropdown-incident">
          <DropdownSelect onSelected={handelOnSearchIncident} selectedItemValue={selectedResolutionValue} padding="8px" title="Incident:" backgroundColor="white" height="48px" optionsList={incidentOptionList} selectImage />
        </div>
      </div>
      <div className="notes-panel-content">
        <div className="notes-panel-box">
          {
            isShowNoteTitle && <div className="note-detail-label"><Text fontSize={12} color="#007FA1">Enter new note details</Text></div>
          }
          <textarea
            value={noteValue}
            placeholder={!isShowNoteTitle ? 'Enter new note details' : ''}
            onChange={handleNoteOnchangeValue}
            cols={30}
            onFocus={handleNoteOnFocus}
            onBlur={handleNoteOnBlur}
          />
        </div>
        <div className="btn-add-note">
          <Button
            color={Colors.LSDarkTeal}
            disabled={disabledBtn}
            variant={ButtonVariant.ButtonImage}
            textColor={Colors.LSDarkTeal}
            width="53%"
            onClick={handleAddNote}
          >
            <Image margin="0 8px 0 0" width="20px" url={CheckCircle} />
            <Text fontSize={15} fontWeight="600" color={Colors.LSWhite}>Add new note</Text>
          </Button>
        </div>
        {
          data?.map((item: NoteItem, index: number) =>
            <div key={index}>
              <NoteControl noteInformation={item} />
            </div>
          )
        }
        {/* hide this button for topgun */}
        {/* <div className="btn-view-call-log">
          <Button
            color={Colors.LSWhite}
            variant={ButtonVariant.Secondary}
            textColor={Colors.LSWhite}
            height="40px"
            width="auto"
            onClick={handleViewCallLog}
          >
            <Image margin="0 8px 0 0" width="20px" url={PhoneHistory} />
            <Text fontSize={15} fontWeight="600" color={Colors.LSDarkTeal}>View call log</Text>
          </Button>
        </div> */}
      </div>
    </div>
  );
};
