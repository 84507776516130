import React from 'react';
import {Colors} from '../../../configs/colors';
import './MapMarkerInfo.styles.css';
import {MapMarkerInfoProps} from './MapMarkerInfo.props';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import {CircleImage} from '../../molecules/CircleImage';
import ClockIcon from '../../../assets/images/icon-clock-counter-clockwise-inactive.svg';

export const MapMarkerInfo: React.FC<MapMarkerInfoProps> = ({
  imageUrl,
  isShowTime,
  nameText = '',
  dateText = '',
  timeText = '',
}) => {
  const getImageBorderColor = (): string => {
    if (isShowTime) {
      return Colors.LSWhite;
    }

    return Colors.RCCritical;
  };
  const classValue = isShowTime ? 'selected' : 'not-selected';

  return <div className={`outer-wrapper ${classValue}`}>
    <div className={`popup-wrap ${classValue}`}>
      <CircleImage url={imageUrl} borderColor={getImageBorderColor()} size="40px" borderWidth="3px"></CircleImage>
      <div className="text-body">
        <div className="text-wrap">
          <Text fontSize={15} margin="5px" color={Colors.NeutralGrey1}>{nameText}</Text>
        </div>
        <div className="text-wrap">
          <Text fontSize={12} margin="5px" color={Colors.NeutralGrey2}>{dateText}</Text>
          {isShowTime &&
            <>
              <Text fontSize={12} margin="2px" color={Colors.NeutralGrey1}>.</Text>
              <div className="time-text-wrap">
                <Image url={ClockIcon} width="12px" margin="2px" />
                <div className="time-text-value">
                  <Text fontSize={12} margin="5px" color={Colors.NeutralGrey2}>{timeText}</Text>
                </div>
              </div>
            </>
          }
        </div>
      </div>
      <div className="popup-pointer">
      </div>
    </div>
  </div>;
};