import React from 'react';
import {Button, ButtonVariant} from '../../atoms/Button';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';
import {incomingIconTypeCaller} from '../../../utils/commonFunction';
import {ReactComponent as PhoneRejectIcon} from '../../../assets/images/phone-reject-button-icon.svg';
import {ReactComponent as ChatEndIcon} from '../../../assets/images/icon-chat-end.svg';
import {ReactComponent as BellSlash} from '../../../assets/images/icon-bell-slash.svg';
import {AnswerDialogProps} from './AnswerDialog.props';
import './AnswerDialog.styles.css';
import {CONSTANTS} from '../../../constants/constants';

export const AnswerDialog: React.FC<AnswerDialogProps> = ({
  isChild = false,
  callerType,
  onAccept,
  onReject,
}) => {
  return (
    <div className="answer-dialog-container">
      <Button 
        variant={ButtonVariant.Primary} 
        width="209px"
        height="56px"
        color={isChild ? Colors.RCInfo : Colors.RCCritical} 
        onClick={onAccept} 
      >
        <Image width="20px" margin="0 4px 0" url={incomingIconTypeCaller(callerType).icon} />
        <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.LSWhite}>{incomingIconTypeCaller(callerType).text}</Text>
      </Button>
      <Button
        variant={ButtonVariant.Secondary} 
        width="155px"
        height="56px"
        margin="0 0 0 16px"
        color={isChild ? Colors.RCInfo : Colors.RCCritical}
        textColor={Colors.RCCritical}
        onClick={onReject} 
      >
        {(CONSTANTS.CALL_TYPES.SOS_VIDEO_CALL === callerType || CONSTANTS.CALL_TYPES.PSTN_CALL === callerType) && <PhoneRejectIcon width={24} height={24}/>}
        {CONSTANTS.CALL_TYPES.MESSAGE === callerType && <ChatEndIcon width={24} height={24} stroke={Colors.RCCritical}/>}
        {CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN === callerType && <BellSlash width={24} height={24}/>}
        <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.RCCritical}>Reject</Text>
      </Button>
    </div>
  );
};
