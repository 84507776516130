export enum VideoControlVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface VideoControlProps {
  sessionDuration: string;
  currentBattery: number;
  onShow: (value: boolean) => void;
  isShowSessionDetails?: boolean;
}
