import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {getNexmoJwtToken} from '../api/interactions';

export interface InteractionChatTokenObjectState {
  data?: string;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionChatTokenObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const nexmoJwtToken = createAsyncThunk('interactionChatTokenForSubscriber', async () => {
  const response = await getNexmoJwtToken();
  return response.lifestreamResponse.data;
});

const interactionChatTokenSlice = createSlice({
  name: 'interactionChatToken',
  initialState,
  reducers: {
    setInteractionChatToken: (state, action: PayloadAction<string | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as string;
      state.error = undefined;
    },
    setInteractionChatTokenClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(nexmoJwtToken.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(nexmoJwtToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as string;
      state.error = undefined;
    });

    builder.addCase(nexmoJwtToken.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInteractionChatToken,
  setInteractionChatTokenClear,
} = interactionChatTokenSlice.actions;

export const interactionChatTokenSelector = (state: RootState) => state.interactionChatToken;

export default interactionChatTokenSlice.reducer;
