import React, {useState} from 'react';
import {InfoPanel} from '../../molecules/InfoPanel';
import './GeneralNotes.styles.css';
import {GeneralNotesProps} from './GeneralNotes.props';
import {CONSTANTS} from '../../../constants/constants';

export const GeneralNotes: React.FC<GeneralNotesProps> = ({
  notes
}) => {
  // const [isEdit, setIsEdit] = useState<boolean>(false);
  const [generalNotes, setGeneralNotes] = useState<string>(notes);
  const [isShowNoteTitle, setShowNoteTitle] = useState<boolean>(false);

  const onChangeInput = (event: any) => {
    setGeneralNotes(event.target.value);
  };

  const onGeneralNoteFocus = (event: any) => {
    if (event.target.value === '')
      setShowNoteTitle(true);
  };

  const onGeneralNoteBlur = (event: any) => {
    if (event.target.value === '')
      setShowNoteTitle(false);
  };

  return (
    <div className="general-notes-style">
      <InfoPanel width="100%" title={CONSTANTS.CALLERS.GENERAL_NOTES} />
      <div className="general-notes-detail">
        <div className="general-notes">
          <textarea
            disabled={true}
            value={generalNotes}
            placeholder={!isShowNoteTitle ? 'Please add any other important here (non-incident related)' : ''}
            onChange={onChangeInput}
            onFocus={onGeneralNoteFocus}
            onBlur={onGeneralNoteBlur}
            rows={3}
          />
        </div>
      </div>
    </div>
  );
};
