import React, {useEffect, useState} from 'react';
import {ChatOptionsProps, TypeOptions, ActiveClass} from '.';
import './ChatOptions.styles.css';
import {Text} from '../../atoms/Text';
import {ReactComponent as AnythingElse} from '../../../assets/images/chat-option-icon/anything-else2-icon.svg';
import {ReactComponent as FirstAid} from '../../../assets/images/chat-option-icon/first-aid-icon.svg';
import {ReactComponent as HandWaving} from '../../../assets/images/chat-option-icon/hand-waving-icon.svg';
import {ReactComponent as Location} from '../../../assets/images/chat-option-icon/map-pin-line-icon.svg';
import {ReactComponent as ChatEnd} from '../../../assets/images/chat-option-icon/chat-end-icon.svg';
import {ReactComponent as Pause} from '../../../assets/images/chat-option-icon/pause-icon.svg';
import {ReactComponent as Siren} from '../../../assets/images/chat-option-icon/siren-icon.svg';
import {Colors} from '../../../configs/colors';
import {CONSTANTS} from '../../../constants/constants';
import {useAppSelector} from '../../../redux/hooks';
import {subscriberLocationSelector} from '../../../redux/subscriberLocationSlice';

export const ChatOptions: React.FC<ChatOptionsProps> = ({
  automaticResponse,
  automaticResponseHover,
  reset,
  onEndChat,
  isConnect,
  callerName='',
  operatorName='',
}) => {
  const subscriberLocation = useAppSelector(subscriberLocationSelector);
  const [active, setActive] = useState<ActiveClass>({
    handWaving: '',
    pause: '',
    firstAid: '',
    anythingElse: '',
    chatEnd: '',
    siren: '',
    location: '',
  });
  const hiMessage = `Hi ${callerName}, this is ${operatorName} from Lifestream. How can I help you?`;

  useEffect(() => {
    setActive({
      handWaving: '',
      pause: '',
      firstAid: '',
      anythingElse: '',
      chatEnd: '',
      siren: '',
      location: '',
    });
  }, [reset]);

  const handleOnclick = (type: number) => {
    const locationMessage = `Can you confirm your location is “${subscriberLocation.address}”?`;

    switch (type) {
    case TypeOptions.HandWaving:
      setActive({
        handWaving: CONSTANTS.CHAT_OPTIONS.TYPE_CLASS.HI,
        pause: '',
        firstAid: '',
        anythingElse: '',
        chatEnd: '',
        siren: '',
        location: '',
      });
      automaticResponse(hiMessage);
      break;
    case TypeOptions.Pause:
      setActive({
        handWaving: '',
        pause: CONSTANTS.CHAT_OPTIONS.TYPE_CLASS.HOLD,
        firstAid: '',
        anythingElse: '',
        chatEnd: '',
        siren: '',
        location: '',
      });
      automaticResponse(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.HOLD);
      break;
    case TypeOptions.FirstAid:
      setActive({
        handWaving: '',
        pause: '',
        firstAid: CONSTANTS.CHAT_OPTIONS.TYPE_CLASS.AMBO,
        anythingElse: '',
        chatEnd: '',
        siren: '',
        location: '',
      });
      automaticResponse(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.AMBO);
      break;
    case TypeOptions.AnythingElse:
      setActive({
        handWaving: '',
        pause: '',
        firstAid: '',
        anythingElse: CONSTANTS.CHAT_OPTIONS.TYPE_CLASS.ANYTHING_ELSE,
        chatEnd: '',
        siren: '',
        location: '',
      });
      automaticResponse(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.ANYTHING_ELSE);
      break;
    case TypeOptions.ChatEnd:
      setActive({
        handWaving: '',
        pause: '',
        firstAid: '',
        anythingElse: '',
        chatEnd: CONSTANTS.CHAT_OPTIONS.TYPE_CLASS.CHAT_END,
        siren: '',
        location: '',
      });
      automaticResponse('');
      onEndChat('');
      break;
    case TypeOptions.Siren:
      setActive({
        handWaving: '',
        pause: '',
        firstAid: '',
        anythingElse: '',
        chatEnd: '',
        siren: CONSTANTS.CHAT_OPTIONS.TYPE_CLASS.POLICE,
        location: '',
      });
      automaticResponse(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.POLICE);
      break;
    case TypeOptions.Location:
      setActive({
        handWaving: '',
        pause: '',
        firstAid: '',
        anythingElse: '',
        chatEnd: '',
        siren: '',
        location: CONSTANTS.CHAT_OPTIONS.TYPE_CLASS.LOCATION,
      });      
      automaticResponse(locationMessage);
      break;

    default:
      setActive({
        handWaving: '',
        pause: '',
        firstAid: '',
        anythingElse: '',
        chatEnd: '',
        siren: '',
        location: '',
      });
      automaticResponse('');
      break;
    }
  };

  const handleOnMouseOver = (type: number) => {
    const locationMessage = `Can you confirm your location is “${subscriberLocation.address}”?`;
    switch (type) {
    case TypeOptions.HandWaving:
      automaticResponseHover(hiMessage);
      break;
    case TypeOptions.Pause:
      automaticResponseHover(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.HOLD);
      break;
    case TypeOptions.FirstAid:
      automaticResponseHover(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.AMBO);
      break;
    case TypeOptions.AnythingElse:
      automaticResponseHover(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.ANYTHING_ELSE);
      break;
    case TypeOptions.ChatEnd:
      automaticResponseHover('');
      break;
    case TypeOptions.Siren:
      automaticResponseHover(CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.POLICE);
      break;
    case TypeOptions.Location:
      automaticResponseHover(locationMessage);
      break;

    default:
      automaticResponseHover('');
      break;
    }
  };

  const handleOnMouseOut = () => {
    automaticResponseHover('');
  };

  return (
    <div className="chat-options-style">
      <div
        className={`btn-chat-option ${active.handWaving}`}
        onClick={() => handleOnclick(TypeOptions.HandWaving)}
        onMouseOver={() => handleOnMouseOver(TypeOptions.HandWaving)}
        onMouseOut={handleOnMouseOut}
      >
        <HandWaving width={32} height={32} />
        <Text
          margin="4px 0px 0px 0px"
          fontSize={12}
          fontWeight="400"
          color={active.handWaving ? Colors.LSTeal : Colors.NeutralGrey2}
        >
          Hi
        </Text>
      </div>
      <div
        className={`btn-chat-option ${active.pause}`}
        onClick={() => handleOnclick(TypeOptions.Pause)}
        onMouseOver={() => handleOnMouseOver(TypeOptions.Pause)}
        onMouseOut={handleOnMouseOut}
      >
        <Pause width={32} height={32} />
        <Text
          margin="4px 0px 0px 0px"
          fontSize={12}
          fontWeight="400"
          color={active.pause ? Colors.LSTeal : Colors.NeutralGrey2}
        >
          Hold
        </Text>
      </div>
      <div
        className={`btn-chat-option location ${active.location} ${subscriberLocation.address ? '' : 'disabled'}`}
        onClick={() => handleOnclick(TypeOptions.Location)}
        onMouseOver={() => handleOnMouseOver(TypeOptions.Location)}
        onMouseOut={handleOnMouseOut}
      >
        <Location width={32} height={32} />
        <Text
          margin="4px 0px 0px 0px"
          fontSize={12}
          fontWeight="400"
          color={active.location ? Colors.LSTeal : Colors.NeutralGrey2}
        >
          Location
        </Text>
      </div>
      <div
        className={`btn-chat-option ${active.firstAid}`}
        onClick={() => handleOnclick(TypeOptions.FirstAid)}
        onMouseOver={() => handleOnMouseOver(TypeOptions.FirstAid)}
        onMouseOut={handleOnMouseOut}
      >
        <FirstAid width={32} height={32} />
        <Text
          margin="4px 0px 0px 0px"
          fontSize={12}
          fontWeight="400"
          color={active.firstAid ? Colors.LSTeal : Colors.NeutralGrey2}
        >
          Ambo
        </Text>
      </div>
      <div
        className={`btn-chat-option ${active.siren}`}
        onClick={() => handleOnclick(TypeOptions.Siren)}
        onMouseOver={() => handleOnMouseOver(TypeOptions.Siren)}
        onMouseOut={handleOnMouseOut}
      >
        <Siren width={32} height={32} />
        <Text
          margin="4px 0px 0px 0px"
          fontSize={12}
          fontWeight="400"
          color={active.siren ? Colors.LSTeal : Colors.NeutralGrey2}
        >
          Police
        </Text>
      </div>
      <div
        className={`btn-chat-option anything ${active.anythingElse}`}
        onClick={() => handleOnclick(TypeOptions.AnythingElse)}
        onMouseOver={() => handleOnMouseOver(TypeOptions.AnythingElse)}
        onMouseOut={handleOnMouseOut}
      >
        <AnythingElse width={32} height={32} />
        <Text
          margin="4px 0px 0px 0px"
          fontSize={12}
          fontWeight="400"
          color={active.anythingElse ? Colors.LSTeal : Colors.NeutralGrey2}
        >
          End?
        </Text>
      </div>
      <div
        className={`btn-chat-option btn-end ${!
        isConnect ? 'disabled' : ''}`}
        onClick={() => handleOnclick(TypeOptions.ChatEnd)}
        onMouseOver={() => handleOnMouseOver(TypeOptions.ChatEnd)}
        onMouseOut={handleOnMouseOut}
      >
        <ChatEnd width={32} height={32} />
        <Text
          margin="4px 0px 0px 0px"
          fontSize={12}
          fontWeight="400"
          color={Colors.NeutralGrey2}
        >
          End
        </Text>
      </div>
    </div>
  );
};
