import React, {useEffect, useState} from 'react';
import {ContactProps, DataContacts} from '.';
import {ReactComponent as ArrowClockwise} from '../../assets/images/arrows-clockwise.svg';
import {ReactComponent as ArrowUpDown} from '../../assets/images/arrows-down-up.svg';
import {ReactComponent as CaretDown} from '../../assets/images/caret-down.svg';
import {ReactComponent as InfoIcon} from '../../assets/images/info-icon.svg';
import {Button, ButtonVariant} from '../../components/atoms/Button';
import {Image} from '../../components/atoms/Image';
import {MapLocation} from '../../components/atoms/Map';
import {Text} from '../../components/atoms/Text';
import {Tooltip} from '../../components/atoms/Tooltip/Tooltip';
import {ContactMethod} from '../../components/layouts/ContactMethod';
import {MapControl, MapType} from '../../components/layouts/MapControl';
import {CircleImage} from '../../components/molecules/CircleImage';
import {SearchLocation} from '../../components/molecules/SearchLocationInput';
import {TableHeader} from '../../components/molecules/TableHeader';
import {ContactInformationPanel} from '../../components/organisms/ContactInformationPanel';
import {Colors} from '../../configs/colors';
import {Images} from '../../configs/images';
import {CONSTANTS} from '../../constants/constants';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {
  setEmergencyContactListDefaultSort,
  sortSubscriberContactData,
  subscriberDetailsSelector,
  updateSubscriberContactLocation,
} from '../../redux/subscriberDetailsSlice';
import {
  lastKnownLocationBySubscriberIds,
  lastKnownLocationDataSelector,
} from '../../redux/userLastKnowLocationSlice';
import {getPositionList} from '../../utils/commonFunction';
import {formatDate, getMinutesStartToNow} from '../../utils/dateTimeHelper';
import {SortColumn} from './Caller.props';
import './Caller.styles.css';

export const Contact: React.FC<ContactProps> = ({
  mapFullScreenState,
  setMapFullScreen,
  userLocation,
  userInfo,
  subscriberId,
  onClickMobile,
  onClickChat,
  onVideoClick,
  isInteractionButtonDisabled = false,
}) => {
  const dispatch = useAppDispatch();
  const lastKnownLocationSelect = useAppSelector(lastKnownLocationDataSelector);
  const subscriberDetailsSelect = useAppSelector(subscriberDetailsSelector);
  const [showContactMethod, setShowContactMethod] = useState<boolean>(false);
  const [contactMethod, setContactMethod] = useState<DataContacts>();
  const [coordinate, setCoordinate] =
    useState<MapLocation | undefined>(undefined);
  const [emergencyContacts, setEmergencyContacts] = useState<DataContacts[]>(
    []
  );
  const [subscriberIds, setSubscriberIds] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<SortColumn>(SortColumn.ContactName);
  const [isAscending, setIsAscending] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<DataContacts>();
  const [showContactStatus, setShowContact] = useState<string>('');
  const [locationsContact, setLocationsContact] = useState<MapLocation[]>([]);

  const setDefaultSort = () => {
    dispatch(setEmergencyContactListDefaultSort());
  };

  const dataHeader = [
    {
      type: 'Contact name',
      showIcon: true,
      widthType: '15%',
      Icon: (
        <ArrowUpDown
          onClick={() => handleSort(SortColumn.ContactName)}
          stroke={Colors.NeutralGrey1}
          width={16}
          height={16}
          className="caller-header-icon"
        />
      ),
    },
    {
      type: 'Group',
      showIcon: true,
      widthType: '9%',
      Icon: (
        <ArrowUpDown
          onClick={() => handleSort(SortColumn.Group)}
          stroke={Colors.NeutralGrey1}
          width={16}
          height={16}
          className="caller-header-icon"
        />
      ),
    },
    {
      type: 'Relationship',
      showIcon: true,
      widthType: '11%',
      Icon: (
        <ArrowUpDown
          onClick={() => handleSort(SortColumn.Relationship)}
          stroke={Colors.NeutralGrey1}
          width={16}
          height={16}
          className="caller-header-icon"
        />
      ),
    },
    {
      type: 'Contact',
      showIcon: false,
      widthType: '15%',
    },
    {
      type: 'Current location',
      showIcon: true,
      widthType: '22%',
      Icon: (
        <ArrowClockwise
          onClick={() => getLastLocationData()}
          stroke={Colors.LSDarkTeal}
          width={16}
          height={16}
          className="caller-header-icon"
        />
      ),
    },
    {
      type: 'Last fix',
      showIcon: true,
      widthType: '11%',
      Icon: (
        <ArrowUpDown
          onClick={() => handleSort(SortColumn.LastFix)}
          stroke={Colors.NeutralGrey1}
          width={16}
          height={16}
          className="caller-header-icon"
        />
      ),
    },
    {
      type: 'Distance',
      showIcon: true,
      widthType: '8%',
      onClick: setDefaultSort,
      Icon: (
        <CaretDown
          stroke={Colors.LSDarkTeal}
          width={16}
          height={16}
          className="caller-header-icon"
        />
      ),
    },
    {
      type: '',
      showIcon: false,
      widthType: '2%',
    },
  ];

  const onChangeLocation = (data: SearchLocation) => {
    const position = {
      lat: data.lat,
      lng: data.lng,
    };
    setCoordinate(position);
  };

  const handleSort = (sortColumn: SortColumn) => {
    if (sortBy === sortColumn) {
      setIsAscending(!isAscending);
    } else {
      setSortBy(sortColumn);
      setIsAscending(true);
    }
    dispatch(
      sortSubscriberContactData({SortBy: sortBy, IsAscending: isAscending})
    );
  };

  const onMobileCall = (number: string, subscriberId?: string) => {
    setShowContact('hide');
    onClickMobile(number, false, subscriberId ? true : false, subscriberId);
  };
  const onVideoCall = (contact: DataContacts) => {
    setShowContact('hide');
    onVideoClick && onVideoClick(true, contact.contactSubscriberId);
  };
  const onChatCall = (contact: DataContacts) => {
    setShowContact('hide');
    onClickChat && onClickChat(contact.contactSubscriberId);
  };

  const handleShowECs = (item: DataContacts) => {
    setSelectedContact(item);
    setShowContact('show');
  };

  const getTypeContact = (item: DataContacts) => {
    if (item.contactSubscriberId) {
      return Images.VideoIcon;
    } else {
      return Images.VoiceIcon;
    }
  };

  const handleContactMethod = (data: DataContacts) => {
    setContactMethod(data);
    setShowContactMethod(true);
  };

  const positionList = getPositionList(userLocation);
  const zoom =
    positionList.length > 0
      ? CONSTANTS.MAP_ZOOMS.Locate
      : CONSTANTS.MAP_ZOOMS.Default;
  const lastLocationInfo = positionList.length > 0 && {
    imageUrl: userInfo.profileImageUrl,
    fullName: userInfo.name,
    date: userInfo.dateInfoOnMap || '',
    time: userInfo.timeInfoOnMap || '',
    timeCounter: getMinutesStartToNow(
      positionList[positionList.length - 1].createdDate
    ),
    firstName: userInfo.firstName,
  };

  const originLocationInfo = positionList.length > 0 && {
    imageUrl: userInfo.profileImageUrl,
    fullName: userInfo.name,
    date: formatDate(positionList[0].timestamp, 'DD/MM/YYYY'),
    time: formatDate(positionList[0].timestamp, 'HH:mm'),
    timeCounter: getMinutesStartToNow(positionList[0].createdDate),
  };

  const getLastLocationData = () => {
    const subscriberPosition =
      positionList.length > 0
        ? {
          lat: positionList[0].lat,
          lng: positionList[0].lng,
        }
        : undefined;
    if (subscriberIds.length > 0) {
      dispatch(
        lastKnownLocationBySubscriberIds({
          subscriberIds: subscriberIds.toString(),
          position1: subscriberPosition,
        })
      );
    }
  };

  useEffect(() => {
    if (
      subscriberDetailsSelect.data &&
      subscriberDetailsSelect.data.emergencyContactList
    ) {
      setEmergencyContacts(subscriberDetailsSelect.data?.emergencyContactList);

      const subIds: string[] = [];
      subscriberDetailsSelect.data?.emergencyContactList.length > 0 &&
        subscriberDetailsSelect.data?.emergencyContactList.forEach(
          (contact) => {
            if (contact.contactSubscriberId) {
              subIds.push(contact.contactSubscriberId);
            }
          }
        );
      setSubscriberIds(subIds);
    }
  }, [subscriberDetailsSelect.data]);

  useEffect(() => {
    getLastLocationData();
  }, [subscriberIds.toString()]);

  useEffect(() => {
    if (lastKnownLocationSelect.data) {
      dispatch(updateSubscriberContactLocation(lastKnownLocationSelect.data));
    }
  }, [lastKnownLocationSelect.data]);

  useEffect(() => {
    const locations =
      userLocation && userLocation.pathData && userLocation.pathData.length > 0
        ? userLocation.pathData.map((x) => ({
          lng: x?.longitude,
          lat: x?.latitude,
        }))
        : [];
    setLocationsContact(locations);
  }, [userLocation]);

  return (
    <div
      className={`contact-container ${
        mapFullScreenState ? 'set-map-fullscreen' : 'set-map-minimize'
      }`}
    >
      <div className={`contact-info-panel ${showContactStatus} `}>
        {showContactStatus === 'show' && selectedContact && (
          <ContactInformationPanel
            dataContact={selectedContact}
            onMobileClick={onMobileCall}
            onVideoClick={onVideoCall}
            onLocationUpdateClick={getLastLocationData}
            onClose={() => setShowContact('hide')}
            isInteractionButtonDisabled={isInteractionButtonDisabled}
          />
        )}
      </div>
      <div className="contact-content">
        <div className="contact-table">
          <TableHeader dataHeader={dataHeader} />
          <div className="contact-table-content scroll-custom">
            {emergencyContacts.length > 0 &&
              emergencyContacts.map((item, index) => (
                <div
                  className="contact-table-row"
                  key={item.contact}
                  onDoubleClick={() => handleContactMethod(item)}
                >
                  <div
                    className="contact-table-column"
                    style={{width: dataHeader[0].widthType}}
                  >
                    <CircleImage
                      border="none"
                      size="32px"
                      url={item.url || ''}
                    />
                    <Text fontSize={14} color={Colors.NeutralBlack}>
                      {item.contactName}
                    </Text>
                  </div>
                  <div
                    className="contact-table-column"
                    style={{width: dataHeader[1].widthType}}
                  >
                    <Text fontSize={14} color={Colors.NeutralBlack}>
                      {item.group}
                    </Text>
                  </div>
                  <div
                    className="contact-table-column"
                    style={{width: dataHeader[2].widthType}}
                  >
                    <Text fontSize={14} color={Colors.NeutralBlack}>
                      {item.relationship}
                    </Text>
                  </div>
                  <div
                    className="contact-table-column contact"
                    style={{width: dataHeader[3].widthType}}
                  >
                    <Button
                      variant={ButtonVariant.ButtonImage}
                      disabled={isInteractionButtonDisabled}
                    >
                      <Image
                        width="24px"
                        margin="0px 10px 0px 0px"
                        url={getTypeContact(item)}
                        onClick={() =>
                          item.contactSubscriberId
                            ? onVideoCall(item)
                            : onMobileCall(item.contact)
                        }
                      />
                    </Button>
                    <Text fontSize={14} color={Colors.NeutralBlack}>
                      <div className="ellipsis">{item.contact}</div>
                    </Text>
                    <Tooltip position="top" content="Contact options">
                      <div
                        onClick={() => handleContactMethod(item)}
                        className="contact-options"
                      >
                        ...
                      </div>
                    </Tooltip>
                    {showContactMethod &&
                      contactMethod &&
                      contactMethod.subScriberID === item.subScriberID && (
                      <div className="contact-method">
                        <ContactMethod
                          onClose={() => setShowContactMethod(false)}
                          onMobileCall={onMobileCall}
                          onVideoCall={onVideoCall}
                          onChatCall={onChatCall}
                          contactMethod={contactMethod}
                          isInteractionButtonDisabled={
                            isInteractionButtonDisabled
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="contact-table-column contact-address"
                    style={{width: dataHeader[4].widthType}}
                  >
                    <Text
                      fontSize={14}
                      color={Colors.NeutralBlack}
                      className={
                        item.contactSubscriberId
                          ? item.currentLocation === 'N/A' ||
                            !item.currentLocation
                            ? 'value-not-available'
                            : ''
                          : item.homeAddress === 'N/A'
                            ? 'value-not-available'
                            : ''
                      }
                    >
                      <Tooltip
                        position={`${index === 0 ? 'bottom' : 'top'}`}
                        content={
                          !item.contactSubscriberId
                            ? item.homeAddress || ''
                            : item.currentLocation || ''
                        }
                      >
                        <div className="ellipsis">
                          {!item.contactSubscriberId
                            ? item.homeAddress || 'N/A'
                            : item.currentLocation || 'N/A'}
                        </div>
                      </Tooltip>
                    </Text>
                  </div>
                  <div
                    className="contact-table-column"
                    style={{width: dataHeader[5].widthType}}
                  >
                    <Text
                      fontSize={14}
                      color={Colors.NeutralBlack}
                      className={
                        item.lastFix === 'N/A' || !item.lastFix
                          ? 'value-not-available'
                          : ''
                      }
                    >
                      {item.lastFix || 'N/A'}
                    </Text>
                  </div>
                  <div
                    className="contact-table-column"
                    style={{width: dataHeader[6].widthType}}
                  >
                    <Text
                      fontSize={14}
                      color={Colors.NeutralBlack}
                      className={
                        item.distance === 'N/A' || !item.distance
                          ? 'value-not-available'
                          : ''
                      }
                    >
                      {item.distance || 'N/A'}
                    </Text>
                  </div>
                  <div
                    className="contact-table-column"
                    style={{width: dataHeader[7].widthType}}
                  >
                    <InfoIcon
                      onClick={() => handleShowECs(item)}
                      width={24}
                      height={24}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="map-detail">
        <div
          className="map-fullscreen"
          onClick={() => setMapFullScreen(!mapFullScreenState)}
        >
          <Image
            width="16px"
            height="16px"
            url={
              mapFullScreenState ? Images.MiniGoogleMap : Images.MapFullScreen
            }
          />
        </div>
        <div className="map-container">
          <MapControl
            dataContacts={emergencyContacts}
            type={MapType.Large}
            subscriberId={subscriberId}
            lastLocationInfo={lastLocationInfo || undefined}
            originLocationInfo={originLocationInfo || undefined}
            coordinate={coordinate}
            zoomLevel={zoom}
            onChangeLocation={onChangeLocation}
            noOfCctvItems={16}
            noOfChatItems={14}
            noOfVideoItems={24}
            heightMap="100%"
            fullscreenControl={false}
            isFullScreenControl={true}
            tabName="contacts"
            onToggleInfo={(value: DataContacts) => handleShowECs(value)}
            path={locationsContact}
          />
        </div>
      </div>
    </div>
  );
};
