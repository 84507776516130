/* eslint-disable @typescript-eslint/ban-ts-comment */
import OT from '@opentok/client';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {parseTokenData} from '../utils/parsers';
import {callOutInteraction} from '../api/interactions';
import {NXMCall} from 'nexmo-client';

export interface AudioCallState {
  call?: NXMCall;
  isMute: boolean;
  isHold: boolean;
}

const initialState: AudioCallState = {
  call: undefined,
  isMute: false,
  isHold: false
};

export interface SubscriberProperties {
  mainCallerProperties: OT.SubscriberProperties;
  secondCallerProperties: OT.SubscriberProperties;
  stream: OT.Stream
} 

export interface InteractionVideoCallOutObject {
  incidentId: string;
  interactionId: string;
  token: string;
}

const audioCallSlice = createSlice({
  name: 'audioCall',
  initialState,
  reducers: {
    setAudioCall: (state, action: PayloadAction<NXMCall>) => {
      state.call = action.payload;
    },
    setAudioCallAudioState: (state, action: PayloadAction<boolean>) => {
      state.isMute = action.payload;
      state.call?.conversation?.me?.mute(action.payload);
    },
    setAudioCallEnd: (state) => {
      state.call = undefined;
      state.isMute = false;
    },
    setAudioCallHold: (state, action: PayloadAction<boolean>) => {
      state.isHold = action.payload;
    }
  },
  extraReducers: (builder) => {
    //extra Reducers
  }
});

export const {
  setAudioCall,
  setAudioCallAudioState,
  setAudioCallHold,
  setAudioCallEnd,
} = audioCallSlice.actions;

export const audioCallSelector = (state: RootState) => state.audioCall;

export default audioCallSlice.reducer;
