import React, {useState, useEffect} from 'react';
import {UserInfoPanelProps} from './UserInfoPanel.props';
import './UserInfoPanel.styles.css';
import {CircleImage} from '../../molecules/CircleImage';
import {Text} from '../../atoms/Text';
import {ReactComponent as ChatCallButton} from '../../../assets/images/chat-caller-button-icon.svg';
import {ReactComponent as HomeCallButton} from '../../../assets/images/home-caller-button-icon.svg';
import {ReactComponent as MobileCallButton} from '../../../assets/images/mobil-caller-button-icon.svg';
import {ReactComponent as VideoCallButton} from '../../../assets/images/video-caller-button-icon.svg';
import {ReactComponent as WorkCallButton} from '../../../assets/images/work-caller-button-icon.svg';
import {Images} from '../../../configs/images';
import {Colors} from '../../../configs/colors';
import {useAppSelector} from '../../../redux/hooks';
import {userTaskSelector} from '../../../redux/userTaskSlice';

export const UserInfoPanel: React.FC<UserInfoPanelProps> = ({
  userContactdata,
  userBasicdata,
  onClickMobile,
  onClickChat,
  onVideoClick,
  isInteractionButtonDisabled: isMobileButtonDisabled = false,
  isVideoCallDisabled = false,
}) => {

  const userTask = useAppSelector(userTaskSelector);
  const [activeButton, setActiveButton] = useState<string>('');
  const handleOnClickMobile = (value: string, type: string) => {
    setActiveButton(type);
    onClickMobile(value, false, true);
  };

  const handleOnClickVideo = (type: string) => {
    setActiveButton(type);
    onVideoClick();
  };

  const handleOnClickChat = (type: string) => {
    setActiveButton(type);
    onClickChat && onClickChat();
  };

  useEffect(() => {
    if (userTask.isEnded) {
      setActiveButton('');
    }
  }, [userTask.isEnded]);

  return (
    <div className="user-info-panel-style">
      <div className="user-info-left-style">
        <CircleImage
          border="none"
          size={'72px'}
          url={
            userContactdata.profileImageUrl
              ? userContactdata.profileImageUrl
              : Images.NoProfilePictureIcon
          }
        />
        <div className="user-info-panel-child-style">
          <Text fontSize={18} margin="0px" fontWeight="600" color={Colors.NeutralGrey1}>
            {userContactdata.fullName}
            {userContactdata.age && <span>, {userContactdata.age}</span>}
          </Text>
          <Text fontSize={15} margin="2px 0px" fontWeight="400" color={Colors.NeutralGrey1}>
            {userContactdata.address}
          </Text>
          <div className="date-time-style">
            <Text fontSize={14} color={Colors.NeutralGrey2}>
              {new Date().toLocaleString('en-US', {weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric'})}
            </Text>
            <Text fontSize={14} margin="0px 16px" color={Colors.NeutralGrey2}>
              {new Date().toLocaleTimeString('en-US', {hour12: false})}
            </Text>
          </div>
        </div>
      </div>
      <div className="call-button-style">
        {userContactdata.mobile && (
          <div
            onClick={() => handleOnClickMobile(userContactdata.mobile, 'mobile')}
            className={`call-button-child-style ${activeButton === 'mobile' ? activeButton : ''} ${isMobileButtonDisabled ? 'disabled' : ''}`}
          >
            <div className="btn-image">
              <MobileCallButton
                width={21}
                height={21}
                stroke={activeButton === 'mobile' ? Colors.LSWhite : Colors.LSDarkTeal}
              />
            </div>
            <Text
              color={activeButton === 'mobile' ? Colors.LSWhite : Colors.LSDarkTeal}
              fontSize={14}
              fontWeight={'500'}
            >
              Mobile
            </Text>
          </div>
        )}
        {userContactdata.home && (
          <div
            onClick={() => handleOnClickMobile(userContactdata.work, 'home')}
            className={`call-button-child-style ${activeButton === 'home' ? activeButton : ''} ${isMobileButtonDisabled ? 'disabled' : ''}`}
          >
            <div className="btn-image">
              <HomeCallButton
                width={21}
                height={21}
                stroke={activeButton === 'home' ? Colors.LSWhite : Colors.LSDarkTeal}
              />
            </div>
            <Text
              color={activeButton === 'home' ? Colors.LSWhite : Colors.LSDarkTeal}
              fontSize={14}
              fontWeight={'500'}
            >
              Home
            </Text>
          </div>
        )}
        {userContactdata.work && (
          <div
            onClick={() => handleOnClickMobile(userContactdata.work, 'work')}
            className={`call-button-child-style ${activeButton === 'work' ? activeButton : ''} ${isMobileButtonDisabled ? 'disabled' : ''}`}
          >
            <div className="btn-image">
              <WorkCallButton
                width={21}
                height={21}
                stroke={activeButton === 'work' ? Colors.LSWhite : Colors.LSDarkTeal}
              />
            </div>
            <Text
              color={activeButton === 'work' ? Colors.LSWhite : Colors.LSDarkTeal}
              fontSize={14}
              fontWeight={'500'}
            >
              Work
            </Text>
          </div>
        )}
        {userContactdata.video && (
          <div
            onClick={() => handleOnClickVideo('video')}
            className={`call-button-child-style ${activeButton === 'video' ? activeButton : ''} ${(isVideoCallDisabled || isMobileButtonDisabled) ? 'disabled' : ''}`}
          >
            <div className="btn-image">
              <VideoCallButton
                width={21}
                height={21}
                stroke={activeButton === 'video' ? Colors.LSWhite : Colors.LSDarkTeal}
              />
            </div>
            <Text
              color={activeButton === 'video' ? Colors.LSWhite : Colors.LSDarkTeal}
              fontSize={14}
              fontWeight={'500'}
            >
              Video
            </Text>
          </div>
        )} 
        {userContactdata.chat && (
          <div
            onClick={() => handleOnClickChat('chat')}
            className={`call-button-child-style ${activeButton === 'chat' ? activeButton : ''} ${isMobileButtonDisabled ? 'disabled' : ''}`}
          >
            <div className="btn-image">
              <ChatCallButton
                width={21}
                height={21}
                stroke={activeButton === 'chat' ? Colors.LSWhite : Colors.LSDarkTeal}
              />
            </div>
            <Text
              color={activeButton === 'chat' ? Colors.LSWhite : Colors.LSDarkTeal}
              fontSize={14}
              fontWeight={'500'}
            >
              Chat
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
