import React from 'react';
import {CircleImage} from '../../molecules/CircleImage';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';
import {MapAvatarProps, AvatarStatus} from './MapAvatar.props';
import CallerInbox from '../../../assets/images/caller-inbox.svg';
import {Text} from '../../atoms/Text';
import './MapAvatar.styles.css';

export const MapAvatar: React.FC<MapAvatarProps> = ({
  imageUrl,
  name,
  size = '40px',
  borderWidth = '3px',
  onClick,
  borderColor = Colors.LSWhite
}) => {

  return (
    <div className="avatar-outer-wrap">
      <div className="avatar-wrap" style={{width: size, height: size}}>
        <CircleImage
          url={imageUrl}
          size={size}
          borderColor={borderColor}
          borderWidth={borderWidth}
          onClick={onClick}
        />
        <div className="avatar-caller-image">
          <Image url={CallerInbox} width="26px" height="26px" onClick={onClick} />
        </div>
      </div>
      <div className="avatar-name">
        <Text fontSize={11} fontWeight={'700'} margin="5px" color={Colors.NeutralGrey1}>{name}</Text>
      </div>
    </div>
  );
};
