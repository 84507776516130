import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {getLastLocation, getLocationInTimePeriod} from '../api/locations';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import appConfig from '../configs/appConfig.json';
import {getAccessToken} from '../api/httpClient';
import {CONSTANTS} from '../constants/constants';

export interface LocationObject {
  latitude: number;
  longitude: number;
  altitude: number;
  heading: number;
  verticalAccuracyEstimate: number;
  provider: string;
  horizontalAccuracyEstimate: number;
  speed: number;
  timestamp: string;
  deviceBatteryLevel: number;
  createdDate: Date;
}

export interface UserLocationState {
  data?: LocationObject;
  isLoading: boolean;
  error?: string;
  pathData: LocationObject[]
}

const initialState: UserLocationState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  pathData: []
};

interface LocationHistoryItem {
  location: LocationObject;
  deviceBatteryLevel: number;
  createdDate: Date;
}

export const userLocationFetch = createAsyncThunk('userLocation/fetch', async (subscriberId: string) => {
  const response = await getLastLocation(subscriberId);
  return {
    ...response.lifestreamResponse.data.location,
    deviceBatteryLevel: response.lifestreamResponse.data.deviceBatteryLevel,
    createdDate: response.lifestreamResponse.data.createdDate
  };
});

export const userLocationHostoryFetch = createAsyncThunk('userLocationHistory/fetch', async (params: any) => {
  const response = await getLocationInTimePeriod(params.subscriberId, params.startTime, params.endTime);
  return response.lifestreamResponse.data;
});

export const locationApi = createApi({
  reducerPath: 'lastLocation',
  baseQuery: fetchBaseQuery({
    baseUrl: `${appConfig.Api.baseUrl}/locations/`,
    prepareHeaders: async (headers) => {
      let accessToken: string | void = '';
      await getAccessToken(CONSTANTS.SERVICE_TYPES.LOCATIONS).then(token =>{
        accessToken = token;
      });
      // If we have a token set in state, let's assume that we should be passing it.
      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLocation: builder.query({
      query: (subscriberId) =>  `getlastlocation?subscriberId=${subscriberId}`,
      transformResponse: (response: any) => {
        const data = response.lifestreamResponse.data.location;
        return {
          ...data,
          createdDate: response.lifestreamResponse.data.createdDate
        };
      },
    })
  }),
});

const userLocationSlice = createSlice({
  name: 'userLocation',
  initialState,
  reducers: {
    setUserLocationData: (state, action: PayloadAction<LocationObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as LocationObject;
      state.error = undefined;
    },
    setUserLocationDataClear: (state) => {
      state.data = undefined;
      state.isLoading = false;
      state.error = undefined;
      state.pathData = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLocationFetch.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLocationFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as LocationObject;
      state.error = undefined;
      state.pathData = state.data ? [...state.pathData, state.data] : [];
    });

    builder.addCase(userLocationFetch.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });

    builder.addCase(userLocationHostoryFetch.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLocationHostoryFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      const data = action.payload as LocationHistoryItem[];
      state.pathData = data.length > 0 ? data.map(x => ({...x.location, deviceBatteryLevel:x.deviceBatteryLevel, createdDate: x.createdDate})) : [];
    });

    builder.addCase(userLocationHostoryFetch.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export const {useGetLocationQuery} = locationApi;

export const {setUserLocationData, setUserLocationDataClear} = userLocationSlice.actions;

export const userLocationSelector = (state: RootState) => state.userLocation;

export default userLocationSlice.reducer;
