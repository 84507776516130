import React, {useEffect, useRef} from 'react';
import appConfig from '../../configs/appConfig.json';
import {WebSocketServiceProps} from './WebSocketService.props';
import {useAppDispatch} from '../../redux/hooks';
import {interactionStatisticsActiveInteractions, interactionStatisticsGetCallStatistics, interactionStatisticsOpenInteractions} from '../../redux/interactionStatisticsSlice';
import {getCurrentIsoDate} from '../../utils/dateTimeHelper';
import {setInteractionUpdateStatus} from '../../redux/interactionHistorySlice';


export const WebSocketService: React.FC<WebSocketServiceProps> = ({
  url
}) => {
  const dispatch = useAppDispatch();
  const listenerRef = useRef<WebSocket>();

  const connect = () =>{
    const interactionListner = new WebSocket(appConfig.Interactions.WebsocketUrl);

    interactionListner.onopen = () => {
      const header = {
        // TODO : For now any token would work, once finalized we'll have to update this
        headers: {
          'Authorization': 'Bearer TOKEN',
        }
      };
      interactionListner.send(JSON.stringify(header));
      console.log('^^^^^WSS:Connected');
    };

    interactionListner.onmessage = function () {
      console.log('^^^^^WSS: Message Reveived');

      dispatch(interactionStatisticsActiveInteractions());
      console.log('^^^^^WSS: dispatch interactionStatisticsActiveInteractions');
      dispatch(interactionStatisticsOpenInteractions());
      console.log('^^^^^WSS: dispatch interactionStatisticsOpenInteractions');
      dispatch(interactionStatisticsGetCallStatistics(getCurrentIsoDate()));
      console.log('^^^^^WSS: dispatch interactionStatisticsGetCallStatistics');
      dispatch(setInteractionUpdateStatus(true));
    };

    interactionListner.onclose = () => {
      // once the connection is closed we reconnect it
      setTimeout(() => {
        console.log('^^^^^WSS:Reconnected');
        listenerRef.current = connect();
      }, 2000);
    };

    return interactionListner;
  };

  useEffect(() => {
    listenerRef.current = connect();

    return () => listenerRef.current && listenerRef.current.close();
  }, []);

  return (
    <>
    </>
  );
};
