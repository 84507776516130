import React, {useState} from 'react';
import {Colors} from '../../../configs/colors';
import {Button, ButtonVariant} from '../../atoms/Button';
import {SwitcherButtonProps} from './SwitcherButton.props';
import './SwitcherButton.styles.css';

export const SwitcherButton: React.FC<SwitcherButtonProps> = ({
  backgroundColor,
  activeColor,
  labels,
  onChange,
  width = '200px',
  height = '50px',
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div
      className="switcher-button-wrap"
      style={{
        backgroundColor,
      }}
    >
      {labels.map((item, index) => (
        <Button
          onClick={() => {
            setActiveIndex(index);
            onChange(item, index);
          }}
          variant={ButtonVariant.Primary}
          text={item}
          color={activeIndex === index ? activeColor : backgroundColor}
          height={height}
          width={width}
          key={item}
          textColor={Colors.NeutralGrey1}
          borderRadius={'10px'}
          border={activeIndex === index ? '1px solid #9DD9E0' : ''}
          fontSize={14}
          fontWeight={600}
        />
      ))}
    </div>
  );
};
