import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';

export interface TrackingState {
  isTracking: boolean,
  map: any;
}

const initialState: TrackingState = {
  isTracking: false,
  map: null
};

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    setTrackingLocation: (state, action: PayloadAction<TrackingState>) => {
      state.isTracking = action.payload.isTracking;
      state.map = action.payload.map;
    },
  }
});

export const {
  setTrackingLocation
} = trackingSlice.actions;

export const TrackingLocationSelector = (state: RootState) => state.trackingLocation;

export default trackingSlice.reducer;