import React from 'react';
import {TrackingToolProps} from '.';
import {Colors} from '../../../configs/colors';
import {Button, ButtonVariant} from '../../atoms/Button';
import './TrackingTool.styles.css';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import {Images} from '../../../configs/images';
import {Tooltip} from '../../atoms/Tooltip/Tooltip';

const backgroundBtn = 'rgba(255, 255, 255, 0.16)';

export const TrackingTool: React.FC<TrackingToolProps> = ({
  onDirecting,
  onLastKnown,
  onLocate,
  onTracking,
  tabName,
  isTracking,
  isLastKnown,
}) => {
  return (
    <div >
      {tabName !== 'contacts' ? (
        <div className="tracking-style">
          <div className="button-container">
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onLocate}
            >
              <Image
                width="20px"
                margin="0 4px 0"
                url={Images.LocateOnMap}
              />
              <Text fontSize={15} fontWeight="400" color={Colors.LSWhite}>
                Locate
              </Text>
            </Button>
          </div>
          <div className={`button-container ${isLastKnown && 'last-known-active'}`}>
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onLastKnown}
            >
              <Image
                width="20px"
                margin="0 4px 0"
                url={Images.LastKnownOnMap}
              />
              <Text fontSize={15} fontWeight="400" color={Colors.LSWhite}>
                Last known
              </Text>
            </Button>
          </div>
          <div className="button-container">
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onDirecting}
            >
              <Image
                width="20px"
                margin="0 4px 0"
                url={Images.DirectionsOnMap}
              />
              <Text fontSize={15} fontWeight="400" color={Colors.LSWhite}>
                Directions
              </Text>
            </Button>
          </div>
          <div className={`button-container ${isTracking && 'tracking-active'}`}>
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onTracking}
            >
              <Image width="20px" margin="0 4px 0" url={Images.TrackOnMap} />
              <Text fontSize={15} fontWeight="400" color={Colors.LSWhite}>
                Track
              </Text>
            </Button>
          </div>
        </div>
      ) :
        <div className="tracking-style">
          <div  className="button-container">
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onLocate}
            >
              <Tooltip className="tooltip-custom" position="top" content="Locate">
                <Image width="20px" url={Images.LocateOnMap}/>
              </Tooltip>
            </Button>
          </div>
          <div className={`button-container ${isLastKnown && 'last-known-active'}`}>
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onLastKnown}
            >
              <Tooltip className="tooltip-custom" position="top" content="Last known">
                <Image width="20px" url={Images.LastKnownOnMap}/>
              </Tooltip>
            </Button>
          </div>
          <div className="button-container">
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onDirecting}
            >
              <Tooltip className="tooltip-custom" position="top" content="Directions">
                <Image width="20px" url={Images.DirectionsOnMap}/>
              </Tooltip>
            </Button>
          </div>
          <div className={`button-container ${isTracking && 'tracking-active'}`}>
            <Button
              variant={ButtonVariant.Primary}
              width="auto"
              height="28px"
              color={backgroundBtn}
              onClick={onTracking}
            >
              <Tooltip className="tooltip-custom" position="top" content="Track">
                <Image width="20px" url={Images.TrackOnMap}/>
              </Tooltip>
            </Button>
          </div>
        </div>
      }
    </div>
  );
};
