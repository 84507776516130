import React, {useState} from 'react';
import {StandaloneSearchBox} from '@react-google-maps/api';
import {SearchLocationInputProps} from './SearchLocationInput.props';
import {Image} from '../../atoms/Image/Image';
import SearchIcon from '../../../assets/images/search-icon.svg';
import './SearchLocationInput.styles.css';

export const SearchLocationInput: React.FC<SearchLocationInputProps> = ({
  onChangeLocation
}) => {

  const [onloadSearch, setOnloadSearch] = useState<any>(null);

  const onPlacesChanged = () => {
    const selectedPlace = onloadSearch.getPlaces();
    const location: string = selectedPlace?.[0]?.formatted_address;
    const lat = selectedPlace[0]?.geometry.location.lat();
    const lng = selectedPlace[0]?.geometry.location.lng();
    onChangeLocation({lat, lng, location});
  };

  const onLoad = (ref: google.maps.places.SearchBox) => setOnloadSearch(ref);
  return (
    <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
      <div className="map-search">
        <input defaultValue="" autoComplete="off" type="search" placeholder="Enter an address or location" />
        <div className="search-field-icon">
          <Image url={SearchIcon} backgroundColor="transparent" border="0" width="24px" />
        </div>
      </div>
    </StandaloneSearchBox>
  );
};
