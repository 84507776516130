import React, {useState} from 'react';
import {Colors} from '../../../configs/colors';
import {CheckBoxProps} from './CheckBox.props';
import './CheckBox.styles.css';

export const CheckBox: React.FC<CheckBoxProps> = ({
  color = Colors.LSTeal,
  value,
  onChange,
  disabled = false,
}) => {
  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(value);

  const onValueChange = (newValue: boolean) => {
    setCheckBoxValue(newValue);
    onChange(newValue);
  };
  return (
    <div
      className={`checkbox-style ${disabled ? 'disabled' : ''}`}
      style={{borderColor: color, backgroundColor: checkBoxValue ? color : 'white'}}
      onClick={() => onValueChange(!checkBoxValue)}
    >
      {checkBoxValue && <p className="checkbox-text-style">✓</p>}
    </div>
  );
};
