import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import {Provider} from 'react-redux';
import {MsalAuthenticationTemplate} from '@azure/msal-react';
import {InteractionType} from '@azure/msal-browser';
import {Home} from './pages/Home';
import {Caller} from './pages/Caller';
import {HeaderControl} from './containers/HeaderControl';
import {WebSocketService} from './containers/WebSocketService';
import {Footer} from './containers/Footer';
import {CCP} from './containers/CCP';
import {store} from './redux/store';

const App: React.FC = () => {
  const [debug, setDebug] = useState(false);
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Provider store={store}>
        <CCP debug={debug}>
          <WebSocketService/>
          <Router>
            <div style={{height: '56px'}}>
              <HeaderControl onToggleDebugMode={() => setDebug(!debug)} />
            </div>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/home" replace />}
              />
              <Route path="/home" element={<Home />} />
              <Route path="/incident" element={''} />
              <Route path="/callers" element={<Caller />}>
                <Route path="/callers/:id" element={<Caller />} />
                <Route path="/callers/:id/:interaction" element={<Caller />} />
              </Route>
            </Routes>
            <div className="home-footer">
              <Footer />
            </div>
          </Router>
        </CCP>
      </Provider>
    </MsalAuthenticationTemplate>
  );
};

export default App;
