import React from 'react';
import {ButtonProps, ButtonVariant} from './Button.props';
import './Button.styles.css';

export const Button: React.FC<ButtonProps> = ({
  children,
  margin,
  onClick,
  color = '',
  width = 'auto',
  variant = 'primary',
  height = variant === ButtonVariant.Icon ? 'auto' : '40px',
  disabled = false,
  textColor,
  text,
  fontSize,
  fontWeight,
  border,
  borderRadius,
  boxShadow,
}) => {
  const getStyle = (variant: string) => {
    switch (variant) {
    case ButtonVariant.Primary:
      return 'button-style-primary';
    case ButtonVariant.Secondary:
      return 'button-style-secondary';
    case ButtonVariant.ButtonImage:
      return 'button-style-image-text';
    case ButtonVariant.Cancel:
      return 'button-style-cancel';
    case ButtonVariant.OnlyImage:
      return 'button-style-only-image';
    case ButtonVariant.TableRow:
      return 'table-row-button-style';
    case ButtonVariant.Profile:
      return 'button-style-profile';
    case ButtonVariant.Icon:
      return 'icon-button-style';
    case ButtonVariant.Round:
      return 'rounded-button-wrap';
    case ButtonVariant.Square:
      return 'square-button-wrap';
    case ButtonVariant.ButtonImageRound:
      return 'button-image-round-style';
    default:
      return 'button-style-primary';
    }
  };

  if (variant === ButtonVariant.Icon) {
    return (
      <div onClick={onClick} className={`icon-button-wrap-style${disabled ? ' icon-button-wrap-disabled-style' : ''}`} style={{color: textColor, margin}}>
        <div className={`icon-button-style${text ? ' icon-button-style-text' : ''}`} style={{backgroundColor: color, width, height, color: textColor}} >
          {children}
        </div>
        {text}
      </div>
    );
  }

  if (variant === ButtonVariant.Secondary) {
    return (
      <div className={`button-style-secondary ${disabled ? 'disabled' : ''}`} style={{borderColor: color, width, height, margin, color: textColor}} onClick={onClick}>
        {children || text}
      </div>
    );
  }

  return (
    <div
      className={`${getStyle(variant)} ${disabled ? 'disabled' : ''}`}
      style={{
        backgroundColor: color,
        width,
        height,
        margin,
        color: textColor,
        fontSize,
        fontWeight,
        border,
        borderRadius,
        boxShadow
      }}
      onClick={onClick}
    >
      {variant !== ButtonVariant.Round && children}
      {text}
      {variant === ButtonVariant.Round && children}
    </div>
  );
};
