import React, {useCallback, useEffect, useRef, useState} from 'react';
import {MapLocation, MapProps} from './Map.props';
import {
  GoogleMap,
  Marker,
  StreetViewPanorama,
  StreetViewService,
  MarkerClusterer,
} from '@react-google-maps/api';
import './Map.styles.css';
import {MapDotsTracking} from '../../organisms/MapDotsTracking';
import {SearchLocationInput} from '../../molecules/SearchLocationInput';
import {PolylineComponent} from '../../molecules/Polylines';
import {MakerType, MarkerInfo} from '../../molecules/MarkerInfo/MapMarker';
import {Colors} from '../../../configs/colors';
import {ContactMarker} from '../../organisms/ContactMarker';
import {sortDistance} from '../../../utils/commonFunction';
import {MapAvatar} from '../../organisms/MapAvatar';
import {DataContacts} from '../../../pages/Caller';
import {CONSTANTS} from '../../../constants/constants';
import {Images} from '../../../configs/images';

const MapCustom = ({
  mapCenter,
  zoomLevel = 18,
  width = '100%',
  height = '100vh',
  zoomControl = true,
  allowFullScreen = true,
  mapTypeControl = true,
  rotateControl = false,
  fullscreenControl = true,
  mapTypeControlPosition = 3,
  fullscreenControlPosition = 12,
  controlSize = 25,
  path = [],
  mapMarkerTracking = [],
  onDoubleClickDot,
  onChangeLocation,
  coordinate,
  isTracking,
  isLastKnown,
  lastLocationInfo,
  originLocationInfo,
  onLoad,
  streetView,
  streetViewOffCallback,
  onLoadStreetview,
  onZoomChanged,
  tabName = '',
  dataContacts,
  onToggleInfo,
  onDrag,
}: MapProps) => {
  const mapContainerStyle = {
    width,
    height,
  };

  const mapControlOptions = {
    zoomControl,
    allowFullScreen,
    mapTypeControl,
    streetViewControl: false,
    rotateControl,
    fullscreenControl,
    mapTypeControlOptions: {
      position: mapTypeControlPosition,
    },
    fullscreenControlOptions: {
      position: fullscreenControlPosition,
    },
    controlSize: controlSize,
  };

  const CircleMarker = {
    path: 'M 0, 0 m -1, 0 a 1,1 0 1,0 2,0 a 1,1 0 1,0 -2,0',
    strokeColor: '#2670c0',
    strokeWeight: 4,
  };

  const streetViewRef = useRef<StreetViewPanorama>(null);
  const [dataEcs, setDataEcs] = useState<DataContacts[]>([]);
  const [contactSubId, setContactSubId] = useState<string>('');
  const [map, setMap] = useState<any>(null);
  const [showOnlySub, setShowOnlySub] = useState<boolean>(false);
  const [lcCluster, setCluster] = useState<any>();

  useEffect(() => {
    if (dataContacts) {
      setDataEcs(sortDistance(dataContacts));
    }
  }, [dataContacts]);

  const autoZoomAndPanto = (location: MapLocation[]) => {
    if (map && location.length > 0) {
      map.panTo(location[0]);
      map.setZoom(CONSTANTS.MAP_ZOOMS.Locate);
      map.panTo(location[0]);
    }
  };

  const fitBound = (location: MapLocation[]) => {
    if (location.length > 0) {
      const newBounds = new google.maps.LatLngBounds();
      const locationsInBound: any = [];

      location.forEach((positions) => {
        if (positions.lat && positions.lng) {
          const loc = new google.maps.LatLng(positions.lat, positions.lng);
          newBounds.extend(loc);
          locationsInBound.push(loc);
        }
      });

      if (locationsInBound.length < 2) {
        autoZoomAndPanto(location);
      } else {
        map && map.fitBounds(newBounds, 100);
      }
    }
  };

  const checkSubscriberMoving = (): boolean => {
    let isCheck = false;
    if (path.length > 1) {
      path.map((item) => {
        if (path[0].lat !== item.lat || path[0].lng !== item.lng) {
          isCheck = true;
        }
      });
    }
    return isCheck;
  };

  const checkOriginAndLastKnow = () => {
    if (
      path.length > 0 &&
      isLastKnown &&
      path[path.length - 1].lat === path[0].lat &&
      path[path.length - 1].lng === path[0].lng
    ) {
      return false;
    }
    return true;
  };

  const onToggleSubscriber = () => {
    map.panTo(path[0]);
    map.setZoom(CONSTANTS.MAP_ZOOMS.Locate);
    map.setCenter(path[0]);
    setContactSubId('');
    updateStateDataEC(undefined);
  };

  const updateStateDataEC = (dataEC: DataContacts | undefined) => {
    dataEC && setContactSubId(dataEC.contactSubscriberId || '');
    const data: DataContacts[] = dataEcs.map((item) => {
      if (dataEC && item.contactSubscriberId === dataEC.contactSubscriberId) {
        return {...item, showContactMarker: false};
      }
      return {...item, showContactMarker: true};
    });
    setDataEcs(data);
  };

  const toggleECsContactBottom = (dataEC: DataContacts) => {
    if (dataEcs) {
      updateStateDataEC(dataEC);
      let filterDataEcs: MapLocation[] = [];
      filterDataEcs = [path[0]];
      dataEcs.filter((fil) => {
        if (fil.distance <= dataEC.distance && fil.location) {
          filterDataEcs.push(fil?.location);
        }
      });
      fitBound(filterDataEcs);
    }
  };

  const handleOnLoad = useCallback((loadMap: any) => {
    onLoad && onLoad(loadMap);
    setMap(loadMap);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const checkLocation = () => {
    const locationsInBound: any = [];
    const currentBounds = map && map.getBounds();
    dataEcs.length > 0 &&
      dataEcs.forEach((positions) => {
        if (positions.location?.lat && positions.location.lng) {
          const loc = new google.maps.LatLng(
            positions.location.lat,
            positions.location.lng
          );
          if (currentBounds && currentBounds.contains(loc)) {
            locationsInBound.push(loc);
          }
        }
      });
    setShowOnlySub(locationsInBound.length === 0 ? true : false);
  };

  useEffect(()=>{
    checkLocation();
  }, [dataEcs]);

  useEffect(() => {
    if (map && tabName === 'contacts') {
      google.maps.event.addListener(map, 'bounds_changed', checkLocation);
    }
    return () => map && google.maps.event.clearListeners(map, 'bounds_changed');
  }, [map, tabName, dataEcs]);

  const isMarkerClustered = (index: number): boolean => {
    const newMap =
      lcCluster &&
      lcCluster.getMarkers().length > 0 &&
      lcCluster.getMarkers()[index].getMap();
    if (newMap !== null && map) {
      return true;
    }
    return false;
  };

  const renderContactMarker = (item: DataContacts, index: number) => {
    if (item.location?.lat && isMarkerClustered(index)) {
      return (
        <ContactMarker
          imageUrl={item.url || ''}
          contactInfo={{
            name: item.firstName,
            distance: item.distance,
            timestamp: item.times,
            fullName: item?.contactName,
          }}
          position={item.location}
          borderColor={Colors.LSWhite}
          showContactMarker={item.showContactMarker}
          showOnlySub={false}
          onToggleInfo={() => onToggleInfo && onToggleInfo(item)}
        />
      );
    }
    return null;
  };

  return (
    <>
      <GoogleMap
        id="guardian-map"
        mapContainerStyle={mapContainerStyle}
        center={coordinate ? coordinate : mapCenter}
        zoom={zoomLevel}
        options={mapControlOptions}
        onLoad={(map) => handleOnLoad(map)}
        onZoomChanged={onZoomChanged}
        onUnmount={onUnmount}
        onDrag={onDrag}
      >
        {!streetView && (
          <SearchLocationInput onChangeLocation={onChangeLocation} />
        )}
        {isTracking && <PolylineComponent path={path} />}
        {mapMarkerTracking.length > 0 && (
          <MapDotsTracking
            mapMarkerTracking={mapMarkerTracking}
            onDoubleClickDot={onDoubleClickDot}
          />
        )}
        {coordinate && (
          <Marker position={coordinate} icon={CircleMarker} visible={true} />
        )}
        {tabName === 'details' &&
          path.length > 0 &&
          checkOriginAndLastKnow() && (
          <MarkerInfo
            stroke={
              originLocationInfo && originLocationInfo?.timeCounter > 10
                ? Colors.RCCritical
                : Colors.RCSuccess
            }
            path={path[0]}
            locationInfo={originLocationInfo}
          />
        )}
        {isLastKnown && path.length > 0 && (
          <MarkerInfo
            stroke={
              lastLocationInfo && lastLocationInfo?.timeCounter > 10
                ? Colors.RCCritical
                : Colors.RCSuccess
            }
            path={path[path.length - 1]}
            locationInfo={lastLocationInfo}
          />
        )}
        {isTracking &&
          !isLastKnown &&
          path.length > 0 &&
          checkSubscriberMoving() && (
          <MarkerInfo
            stroke={
              lastLocationInfo && lastLocationInfo?.timeCounter > 10
                ? Colors.RCCritical
                : Colors.RCSuccess
            }
            path={path[path.length - 1]}
            locationInfo={lastLocationInfo}
            type={MakerType.Current}
          />
        )}
        <StreetViewService onLoad={onLoadStreetview} />
        <StreetViewPanorama
          ref={streetViewRef}
          onVisibleChanged={() => {
            if (
              streetView &&
              streetViewOffCallback &&
              !streetViewRef?.current?.state.streetViewPanorama?.getVisible()
            ) {
              streetViewOffCallback();
            }
          }}
          options={{
            position: mapCenter,
            visible: streetView,
            enableCloseButton: false,
            panControl: false,
            fullscreenControl: false,
            linksControl: false,
            addressControl: false,
          }}
        />
        {/* Contact Map */}
        {tabName === 'contacts' && (
          <>
            {showOnlySub ? (
              <ContactMarker
                imageUrl={originLocationInfo?.imageUrl || ''}
                contactInfo={{
                  timestamp: originLocationInfo?.time,
                  fullName: originLocationInfo?.fullName,
                }}
                dateCall={originLocationInfo?.date}
                position={path[0]}
                borderColor={Colors.LSWhite}
                showOnlySub={false}
                stroke={
                  originLocationInfo && originLocationInfo?.timeCounter > 10
                    ? Colors.RCCritical
                    : Colors.RCSuccess
                }
              />
            ) : (
              <ContactMarker
                imageUrl={originLocationInfo?.imageUrl || ''}
                position={path[0]}
                showOnlySub={true}
              />
            )}
            <MarkerClusterer
              minimumClusterSize={2}
              clusterClass="marker-clusterer"
              imagePath={Images.Clusterer}
              styles={[
                {
                  url: Images.Clusterer,
                  height: 26,
                  width: 26,
                  textColor: '#FFF',
                },
              ]}
              onLoad={(cluster) => setCluster(cluster)}
            >
              {(cluster) => (
                <>
                  {dataEcs.map(
                    (item, i) =>
                      item.location?.lat && (
                        <Marker
                          key={item.contactSubscriberId}
                          position={item.location}
                          clusterer={cluster}
                        >
                          {renderContactMarker(item, i)}
                        </Marker>
                      )
                  )}
                </>
              )}
            </MarkerClusterer>

            <div className="map-avatar-wrap">
              {dataEcs.length > 0 && (
                <MapAvatar
                  imageUrl={lastLocationInfo?.imageUrl || ''}
                  name={lastLocationInfo?.firstName || ''}
                  borderColor={Colors.LSDarkTeal}
                  onClick={onToggleSubscriber}
                ></MapAvatar>
              )}
              {dataEcs.map(
                (item) =>
                  item.contactSubscriberId && (
                    <MapAvatar
                      imageUrl={item.url || ''}
                      name={item.firstName || ''}
                      borderColor={
                        item.contactSubscriberId === contactSubId
                          ? Colors.LSDarkTeal
                          : Colors.LSWhite
                      }
                      key={item.id}
                      onClick={() => toggleECsContactBottom(item)}
                    ></MapAvatar>
                  )
              )}
            </div>
          </>
        )}
      </GoogleMap>
    </>
  );
};

const Map = React.memo(MapCustom);
export default Map;
