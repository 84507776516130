import React from 'react';
import {CircleTextProps} from './CircleText.props';
import './CircleText.styles.css';
import {Text} from '../Text';
import {Colors} from '../../../configs/colors';

export const CircleText: React.FC<CircleTextProps> = ({
  text = '',
  backgroundColor,
  width,
  height
}) => {

  return <div
    className="circle-text-wrap"
    style={{background: backgroundColor, width, height}}>
    <Text fontSize={15} color={Colors.LSWhite}>{text}</Text>
  </div>;

};