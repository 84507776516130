import React from 'react';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';
import {CircleImageProps} from './CircleImage.props';
import './CircleImage.styles.css';

export const CircleImage: React.FC<CircleImageProps> = ({
  url,
  size = '80px',
  borderColor = Colors.LSTeal,
  borderWidth = '2px',
  zIndex,
  background,
  border,
  onClick,
}) => {

  return (
    <div
      className="circle-image-style"
      style={{
        height: size,
        width: size,
        zIndex,
        background,
        border: `${border ? border : `${borderWidth} solid ${borderColor}`}`,
      }}
    >
      <Image url={url} width={size} height={size} borderRadius="50%" onClick={onClick} />
    </div>
  );
};
