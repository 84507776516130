import React from 'react';
import {MenuDockProps} from '.';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';
import './MenuDock.styles.css';
import {CONSTANTS} from '../../../constants/constants';
import {Images} from '../../../configs/images';

export const MenuDock: React.FC<MenuDockProps> = ({
  onClickCCTVHistory,
  onClickActiveAgents,
  onClickStatistic,
  onClickOperatorHistory
}) => {
  return (
    <div className="menu-dock-style">
      <div className="menu-dock">
        <div className="dock-item" onClick={onClickStatistic}>
          <Image width="40px" url={Images.ActiveGauge} />
          <Text fontSize={13} fontWeight="600" color={Colors.NeutralGrey1}>
            {CONSTANTS.DOCK_MENU.CALL_STATISTIC}
          </Text>
        </div>
        <div className="dock-item" onClick={onClickActiveAgents}>
          <Image width="40px" url={Images.ActiveAgents} />
          <Text fontSize={13} fontWeight="600" color={Colors.NeutralGrey1}>
            {CONSTANTS.DOCK_MENU.ACTIVE_AGENTs}
          </Text>
        </div>
        <div className="dock-item" onClick={onClickCCTVHistory}>
          <Image width="40px" url={Images.ActiveCCTV} />
          <Text fontSize={13} fontWeight="600" color={Colors.NeutralGrey1}>
            {CONSTANTS.DOCK_MENU.CCTV_HISTORY}
          </Text>
        </div>
        <div className="dock-item" onClick={onClickOperatorHistory}>
          <Image width="40px" url={Images.ActivePhone} />
          <Text fontSize={13} fontWeight="600" color={Colors.NeutralGrey1}>
            {CONSTANTS.DOCK_MENU.OPERATOR_HISTORY}
          </Text>
        </div>
      </div>
    </div>
  );
};
