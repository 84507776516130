import React, {useEffect, useState} from 'react';
import {Colors} from '../../../configs/colors';
import {getWaitingSeconds} from '../../../utils/dataPreparationHelper';
import {Text} from '../../atoms/Text';
import {CallAddressInfoProps} from './CallAddressInfo.props';
import './CallAddressInfo.styles.css';

export const CallAddressInfo: React.FC<CallAddressInfoProps> = ({address, date, time, startTime}) => {
  const [waitingSeconds, setWaitingSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWaitingSeconds(value => value + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div className="mobile-call-info-user-info">
    <Text color={Colors.LSWhite} margin="5px">{address}</Text>
    <div className="date-time-duration">
      <div className="date-time">
        <Text color={Colors.LSWhite}>{date}</Text>
        <Text color={Colors.LSWhite}>{time}</Text>
      </div>
      {
        startTime ? <Text key={waitingSeconds} color={Colors.LSWhite}>{getWaitingSeconds(startTime)}</Text> : null
      }
    </div>
  </div>;
};