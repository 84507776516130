import moment from 'moment';
import {IncidentInteractionHistory, InteractionHistory} from '../pages/Caller';
import {NoteItem} from '../components/layouts/NotesPanel';
import {formatDate, getTimeDuration} from './dateTimeHelper';

export const sortIncidentData = (data: IncidentInteractionHistory[], sortDirection:string): IncidentInteractionHistory[] => {
  switch (sortDirection) {
  case 'asc':
    return data.sort((a, b) => +new Date(a.createdDate) - +new Date(b.createdDate));
  case 'desc':
    return data.sort((a, b) => +new Date(b.createdDate) - +new Date(a.createdDate));

  default:
    return data.sort((a, b) => +new Date(a.createdDate) - +new Date(b.createdDate));
  }
};

export const sortInteractionHistoryData = (data: InteractionHistory[], sortDirection:string): InteractionHistory[] => {
  switch (sortDirection) {
  case 'asc':
    return data.sort((a, b) => +new Date(a.interactionStartDateTime) - +new Date(b.interactionStartDateTime));
  case 'desc':
    return data.sort((a, b) => +new Date(b.interactionStartDateTime) - +new Date(a.interactionStartDateTime));

  default:
    return data.sort((a, b) => +new Date(a.interactionStartDateTime) - +new Date(b.interactionStartDateTime));
  }
};

export const sortInteractionNotesData = (data: NoteItem[]): NoteItem[] => {
  return data && data.length > 0 ? data.sort((a, b) => +new Date(b.createdDateTime) - +new Date(a.createdDateTime)): data;
};

export const getPartialNoteRef = (noteRef: string|number): string => {
  const refStrList = noteRef.toString().split('.');
  return refStrList.length > 0 ? refStrList[refStrList.length-1] : '';
};

export const getNameInIntials = (name: string|undefined): string => {
  const initialsList = name?.match(/(\b\S)?/g);
  const initials = initialsList? initialsList.join('').toUpperCase(): '-';
  return initials.toUpperCase();
};

export const getWaitingSeconds = (receivedDate: string): string => {
  const received = moment(receivedDate, 'DD/MM/YYYY HH:mm:ss');
  const current = moment();
  const duration = moment.duration(current.diff(received));
  return `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
};

export const getInteractions = (interactions: any, incidentId: string) => {
  return interactions.map((item: any, index: number) => ({
    interactionId: item.interactionId,
    incidentId: incidentId,
    service: '',
    type: item.channel,
    contact: `${item.contactNumber.countryCode}${item.contactNumber.number}`,
    location: item.address,
    received: formatDate(item.interactionStartDateTime, 'DD/MM/yy HH:mm:ss'),
    status: item.mainStatus.toLowerCase(),
    subStatus: item.subStatus.toLowerCase(),
    callRef: item.callRefNo,
    callOp: item.operator,
    interactionStartDateTime: item.interactionStartDateTime,
    interactionEndDateTime: item.interactionEndDateTime,
    subscriberId: item.subscriberId,
    externalSessionData: item.externalSessionData,
    duration: item.interactionEndDateTime && getTimeDuration(item.interactionStartDateTime, item.interactionEndDateTime),
    resolution: item.resolutionType,
    interactions: [],
    interactionsForIncident: true,
    notes: sortInteractionNotesData(item.notes?.map((note: any) => ({
      name: note.addedByOperatorName,
      date: formatDate(note.createdDate.toString(), 'ddd DD/MM/yy HH:mm'),
      refNo: note.noteRefNo,
      note: note.notes,
      createdDateTime: note.createdDate
    })))
  }));
};
