import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Colors} from '../../../configs/colors';
import {CircleImage} from '../../molecules/CircleImage';
import {AudioPlayerProps} from './AudioControl.props';
import {Text} from '../../atoms/Text';
import './AudioControl.styles.css';

export const AudioControl: React.FC<AudioPlayerProps> = ({
  url,
  profileImage,
  width = '120px',
  height = '160px',
  borderRadius = '0px',
  address = 'Melbourne, Australia',
  date,
  time,
  duration
}) => {
  return (
    <div style={{width, height, borderRadius, backgroundColor: '#2C2E2E'}} className="audio-control-wrapper">
      <div className="image-animation-wrap">
        <div className="ripple">
          <div style={{position: 'absolute'}}>
            <CircleImage url={profileImage} size="96px" border="none"/>
          </div>
        </div>
      </div>
      <AudioPlayer className="audio-control"
        src={url}
      />
      <Text className="audio-player-call-address" color={Colors.LSWhite}>{address}</Text>
      <div className="audio-player-call-datetime">
        <Text color={Colors.LSWhite}>{date}</Text>
        <Text color={Colors.LSWhite}>{time}</Text>
        {duration ? <Text color={Colors.LSWhite}>{duration}</Text> : null }
      </div>
    </div>
  );
};