import React from 'react';
import {Colors} from '../../../configs/colors';
import './WatchMeInfoControl.styles.css';
import {WatchMeInfoControlProps} from './WatchMeInfoControl.props';
import {Text} from '../../atoms/Text';

export const WatchMeInfoControl: React.FC<WatchMeInfoControlProps> = ({
  leftHeader,
  leftValue,
  rightHeader,
  rightValue,
}) => {
  return (
    <div className="watchme-info-control-wrap">
      <div className="watch-me-info-control-wrap">
        <div className="watch-me-info-control-left-column">
          <Text fontSize={12} margin="5px" color={Colors.LSTealAccent}>
            {leftHeader}
          </Text>
          <Text fontSize={12} margin="5px" color={Colors.LSWhite}>
            {leftValue}
          </Text>
        </div>
        <div>
          <Text fontSize={12} margin="5px" color={Colors.LSTealAccent}>
            {rightHeader}
          </Text>
          <Text fontSize={12} margin="5px" color={Colors.LSWhite}>
            {rightValue}
          </Text>
        </div>
      </div>
    </div>
  );
};
