import React, {useState} from 'react';
import {Colors} from '../../../configs/colors';
import {InputFieldProps, InputFieldVariant} from './InputField.props';
import './InputField.styles.css';
import {Text} from '../Text';
import {Images} from '../../../configs/images';
import {Image} from '../Image';

export const InputField: React.FC<InputFieldProps> = ({
  alt = '',
  type,
  border,
  value = '',
  boxShadow,
  placeholder,
  width = '200px',
  height = '55px',
  onChange,
  variant = InputFieldVariant.General,
  label = '',
  labelColor = Colors.NeutralBlack,
  Icon = null,
  disabled = false,
  textColor = false,
  onKeyPress,
  onCheckFocus,
  closeIcon,
  onClearValue,
}) => {
  const [isShowLabel, setShowLabel] = useState<boolean>(false);
  const handleNoteOnFocus = (event: any) => {
    if (event.target.value === '')
      setShowLabel(true);
    onCheckFocus && onCheckFocus(true);
  };
  const handleNoteOnBlur = (event: any) => {
    if (event.target.value === '')
      setShowLabel(false);
    onCheckFocus && onCheckFocus(false);
  };

  const handleOnKeyPress = (event: any) => {
    onKeyPress && onKeyPress(event);
  };

  const handleClearValue = () => {
    onClearValue && onClearValue();
    setShowLabel(false);
  };

  switch (variant) {
  case InputFieldVariant.Labelled:
    return (
      <div className="input-labelled-wrap" style={{width,border,boxShadow,height}}>
        {Icon && <Icon style={{marginLeft: 15}} stroke={labelColor} width={20} height={20} />}
        <div className="input-container">
          <input
            className={`input-style ${textColor ? 'text-color' : ''} ${isShowLabel?'input-filled':''}`}
            type={type}
            value={value}
            placeholder={!isShowLabel ? placeholder : ''}
            onChange={onChange}
            onFocus={handleNoteOnFocus}
            onBlur={handleNoteOnBlur}
            onKeyPress={handleOnKeyPress}
          />
          {
            isShowLabel &&
            <div className="filled">
              <Text fontSize={12} color="#007FA1">{label}</Text>
            </div>
          }
          {
            closeIcon && <div><Image onClick={handleClearValue} height="24px" width="24px" url={Images.ActiveClose} /></div>
          }
        </div>
      </div>
    );

  default:
    return (
      <input
        disabled={disabled}
        type={type}
        value={value}
        placeholder={placeholder}
        className={`input-style ${textColor ? 'text-color' : ''}`}
        style={{width}}
        onChange={onChange} alt={alt}
      />
    );
  }
};