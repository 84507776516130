import React from 'react';
import {ContactMethodProps} from '.';
import {Text} from '../../atoms/Text';
import {Button, ButtonVariant} from '../../atoms/Button';
import {Image} from '../../atoms/Image';
import './ContactMethod.styles.css';
import {Images} from '../../../configs/images';
import {CircleImage} from '../../molecules/CircleImage';
import {Colors} from '../../../configs/colors';
import WorkCallButton from '../../../assets/images/phone-work-icon.svg';
import VideoCallButton from '../../../assets/images/video-camera-icon.svg';
import HomeCallButton from '../../../assets/images/phone-home-icon.svg';
import MobileCallButton from '../../../assets/images/phone-mobile-icon.svg';
import ChatCallButton from '../../../assets/images/chat-caller-button-icon.svg';

export const ContactMethod: React.FC<ContactMethodProps> = ({
  contactMethod,
  onClose,
  onMobileCall,
  onVideoCall,
  onChatCall,
  isInteractionButtonDisabled = false
}) => {
  return (
    <div className="contact-method-style">
      <div className="close">
        <Image width="24px" url={Images.ActiveClose} onClick={onClose} />
      </div>
      <div className="profile">
        <CircleImage size="56px" border="none" url={contactMethod.url} />
        <span className="profile-icon"><Image height="16px" width="16px" url={Images.ProfileIcon} /></span>
      </div>
      <Text fontSize={18} fontWeight="600" margin="16px 0 0" color={Colors.NeutralGrey1}>Select your contact method</Text>
      <Text fontSize={15} fontWeight="400" color={Colors.NeutralGrey2}>{contactMethod.contactName}, {contactMethod.relationship}</Text>
      <div className="btn-groups">
        {contactMethod.contactSubscriberId && <Button
          disabled = {isInteractionButtonDisabled}
          variant={ButtonVariant.Square}
          onClick={() => onVideoCall(contactMethod)} width="64px" height="64px" text="video">
          <Image width="24px" url={VideoCallButton} />
        </Button>}
        <Button
          disabled = {isInteractionButtonDisabled}
          variant={ButtonVariant.Square}
          onClick={() => onMobileCall(contactMethod.contact, contactMethod.contactSubscriberId)} width="64px" height="64px" text="mobile">
          <Image width="24px" url={MobileCallButton} />
        </Button>
        {contactMethod.home && <Button
          disabled = {isInteractionButtonDisabled}
          variant={ButtonVariant.Square}
          onClick={() => onMobileCall(contactMethod.home??'', contactMethod.contactSubscriberId)} width="64px" height="64px" text="home">
          <Image width="24px" url={HomeCallButton} />
        </Button>}
        {contactMethod.work &&<Button
          disabled = {isInteractionButtonDisabled}
          variant={ButtonVariant.Square}
          onClick={() => onMobileCall(contactMethod.work??'', contactMethod.contactSubscriberId)} width="64px" height="64px" text="work">
          <Image width="24px" url={WorkCallButton} />
        </Button>}
        {contactMethod.contactSubscriberId &&<Button
          disabled = {isInteractionButtonDisabled}
          variant={ButtonVariant.Square}
          onClick={() => onChatCall(contactMethod)} width="64px" height="64px" text="Chat">
          <Image width="24px" url={ChatCallButton} />
        </Button>}
      </div>
    </div>
  );
};
