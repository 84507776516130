import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {getReferenceData} from '../api/referenceData';

export interface MedicalCondition {
  id: string;
  name: string;
}

export interface MedicalConditionsObjectState {
  data?: MedicalCondition[];
  isLoading: boolean;
  error?: string;
}

const initialState: MedicalConditionsObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const medicalConditionsReferenceData = createAsyncThunk('medicalConditionsReferenceData', async () => {
  const response = await getReferenceData();
  const data = response.lifestreamResponse.data;
  return data.medicalConditions;
});

const medicalConditionsReferenceDataSlice = createSlice({
  name: 'medicalConditionsData',
  initialState,
  reducers: {
    setMedicalConditionsReferenceData: (state, action: PayloadAction<MedicalCondition[] | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as MedicalCondition[];
      state.error = undefined;
    },
    setMedicalConditionsReferenceDataClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(medicalConditionsReferenceData.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(medicalConditionsReferenceData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as MedicalCondition[];
      state.error = undefined;
    });

    builder.addCase(medicalConditionsReferenceData.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setMedicalConditionsReferenceData,
  setMedicalConditionsReferenceDataClear,
} = medicalConditionsReferenceDataSlice.actions;

export const medicalConditionsReferenceDataSelector = (state: RootState) => state.referenceDataMedicalConditions;

export default medicalConditionsReferenceDataSlice.reducer;
