import React, {useEffect, useState} from 'react';
import {Text} from '../../atoms/Text';
import {VideoControlWrap} from '../../atoms/VideoControlWrap';
import {WatchmeTimerSessionProps} from './WatchMeTimerSession.props';
import './WatchMeTimerSession.styles.css';
import {Colors} from '../../../configs/colors';
import {ReactComponent as CaretDownImg} from '../../../assets/images/caret-down.svg';
import {WatchMeInfoControl} from '../../molecules/WatchMeInfoControl';
import {MissedCheckinProcedure} from '../../molecules/MissedCheckinProcedure';
import {NoResponseProcedure} from '../../molecules/NoResponseProcedure';
import {CompleteProcedureOptions} from '../../molecules/CompleteProcedureOptions';
import {Button, ButtonVariant} from '../../atoms/Button';
import {WatchMeBulletListItemProps} from '../../molecules/WatchMeBulletListItem';
import {ReactComponent as User} from '../../../assets/images/user.svg';
import {ReactComponent as Siren} from '../../../assets/images/siren.svg';
import {ReactComponent as VideoCamera} from '../../../assets/images/video-camera.svg';
import {ReactComponent as PhoneCall} from '../../../assets/images/phone-call.svg';
import {CONSTANTS} from '../../../constants/constants';
import {formatDate, getFormattedDuration, getTimeStamp} from '../../../utils/dateTimeHelper';
import moment from 'moment';

export const WatchMeTimerSession: React.FC<WatchmeTimerSessionProps> = ({
  name,
  noReponseProcedures,
  onCompleteClick,
  onButtonClick,
  subscriberActiveStatus,
  interactionsDetailsSelect,
  callerType,
  subscriberDetailsSelect,
  emergencyContacts,
  userLocation
}) => {
  const [showSessionDetails, setShowSessionDetails] = useState(false);
  const [isShowSessionDetails, setIsShowSessionDetails] = useState(false);

  useEffect(() => {
    setIsShowSessionDetails(false);
  }, [showSessionDetails]);

  const handleHideShow = () => {
    setShowSessionDetails(!showSessionDetails);
    setIsShowSessionDetails(true);
  };

  const watchMeConfig = subscriberDetailsSelect?.watchmeConfig;
  const watchMeDisabled =
    interactionsDetailsSelect?.status === CONSTANTS.INTERACTION_STATUS.Closed ||
    callerType === CONSTANTS.CALL_TYPES.WATCH_ME;

  const missedCheckingProcedure: WatchMeBulletListItemProps[] = [
    {
      index: 1,
      labelText: 'Call back immediately : ',
      callType: 'Video call',
      ButtonIcon: VideoCamera,
      buttonText: 'Call',
      onButtonClick: () => onButtonClick && onButtonClick(CONSTANTS.CALL_TYPES.VIDEO_CALL),
      buttonColor: Colors.RCGreyRGB,
      disabled: !subscriberActiveStatus || watchMeDisabled,
      className: 'video-call',
    },
    {
      index: 2,
      labelText: 'Call back immediately : ',
      callType: 'Mobile call',
      ButtonIcon: PhoneCall,
      buttonText: 'Call',
      onButtonClick: () => onButtonClick && onButtonClick(CONSTANTS.CALL_TYPES.VOICE_CALL),
      buttonColor: Colors.RCGreyRGB,
      disabled: watchMeDisabled,
      className: 'mobile-call',
    },
  ];

  let emergencyContactNo = 1;
  if (watchMeConfig && watchMeConfig.primaryContactId) {
    const contact =
      emergencyContacts && emergencyContacts.find((x) => x.id == watchMeConfig.primaryContactId);

    if (contact) {
      missedCheckingProcedure.push({
        index: missedCheckingProcedure.length + 1,
        labelText: 'Call back Primary Emergency Contact ',
        callType: `EC${emergencyContactNo}`,
        contact: {
          name: contact.contactName,
          relationship: contact.relationship,
        },
        ButtonIcon: User,
        buttonText: `EC${emergencyContactNo}`,
        onButtonClick: () => onButtonClick && onButtonClick('EC'),
        buttonColor: Colors.RCGreyRGB,
        disabled: watchMeDisabled,
        className: 'emergency-contact',
      });
      emergencyContactNo++;
    }
  }

  if (watchMeConfig && watchMeConfig.secondaryContactId) {
    const contact =
      emergencyContacts && emergencyContacts.find((x) => x.id == watchMeConfig.secondaryContactId);
    if (contact) {
      missedCheckingProcedure.push({
        index: missedCheckingProcedure.length + 1,
        labelText: 'Call back Secondary Emergency Contact ',
        callType: `EC${emergencyContactNo}`,
        contact: {
          name: contact.contactName,
          relationship: contact.relationship,
        },
        ButtonIcon: User,
        buttonText: `EC${emergencyContactNo}`,
        onButtonClick: () => onButtonClick && onButtonClick('EC'),
        buttonColor: Colors.RCGreyRGB,
        disabled: watchMeDisabled,
        className: 'emergency-contact',
      });
      emergencyContactNo++;
    }
  }

  if (watchMeConfig && watchMeConfig.useEmergencyService) {
    missedCheckingProcedure.push({
      index: missedCheckingProcedure.length + 1,
      labelText: 'Call selected Emergency Service ',
      callType: '000',
      contact: {name: '000', relationship: 'Emergency Service'},
      ButtonIcon: Siren,
      buttonText: '000',
      onButtonClick: () => onButtonClick && onButtonClick(CONSTANTS.CALL_TYPES.OOO),
      buttonColor: Colors.RCGreyRGB,
      disabled: true,
    });
  }
  const start = interactionsDetailsSelect?.externalSession?.data?.startDate;
  const duration = interactionsDetailsSelect?.externalSession?.data?.timerSeconds;
  let currentBattery = interactionsDetailsSelect?.externalSession?.data?.deviceBatteryLevel || 0;
  if (userLocation && userLocation.pathData.length > 0) {
    const lastEntry = userLocation?.pathData[userLocation.pathData.length - 1];
    if (lastEntry) {
      currentBattery = lastEntry.deviceBatteryLevel || 0;
    }
  }
  const sessionStart = start ? formatDate(start, 'DD/MM/YYYY HH:mm:ss') : '';
  const sessionEnd = start
    ? formatDate(moment(start).add(duration, 'seconds').toString(), 'DD/MM/YYYY HH:mm:ss')
    : '';
  const sessionDuration = start
    ? getFormattedDuration(moment(start).add(duration, 'seconds').unix() - getTimeStamp(start))
    : '';
  const timerValue = getFormattedDuration(duration);
  const startBattery = interactionsDetailsSelect?.externalSession?.data?.deviceBatteryLevel || 0;

  return (
    <div className="watch-me-timer-session-wrap">
      <div className="watch-me-timer-session-header">
        <Text fontSize={24} color={Colors.LSWhite}>
          Watchme Timer Session
        </Text>
        <Text fontSize={12} margin="8px 0px 0px 0px" color={Colors.LSTealAccent}>
          {name}
        </Text>
      </div>

      <div
        className={`watch-me-timer-session-body scroll-custom ${
          watchMeDisabled && 'session-body-no-completed'
        } ${showSessionDetails && 'session-body-has-detail'}`}
      >
        <VideoControlWrap
          currentBattery={currentBattery}
          sessionDuration={sessionDuration}
          onShow={(value) => setShowSessionDetails(value)}
          isShowSessionDetails={isShowSessionDetails}
        />

        <MissedCheckinProcedure items={missedCheckingProcedure} />

        <NoResponseProcedure items={noReponseProcedures} />
      </div>

      {!watchMeDisabled && <CompleteProcedureOptions onCompleteClick={onCompleteClick} />}

      <div
        className={`watch-me-timer-session-details scroll-custom ${
          !showSessionDetails ? 'collapsed' : ''
        }`}
      >
        <div className="watch-me-timer-session-details-center-align">
          <Text margin="0px 0px 9px 0px" color={Colors.LSTealAccent} fontSize={12}>
            SESSION DURATION
          </Text>
          <Text margin="0px 0px 9px 0px" color={Colors.LSWhite} fontSize={24}>
            {timerValue}
          </Text>
        </div>
        <WatchMeInfoControl
          leftHeader="Start"
          leftValue={sessionStart}
          rightHeader="Expected End"
          rightValue={sessionEnd}
        />
        <WatchMeInfoControl
          leftHeader="Last updated"
          leftValue={'04/03/2022'}
          rightHeader="Last action"
          rightValue={'04/03/2022'}
        />
        <div className="watch-me-timer-session-details-center-align">
          <Text margin="9px 0px 9px 0px" color={Colors.LSTealAccent} fontSize={12}>
            BATTERY
          </Text>
        </div>
        <WatchMeInfoControl
          leftHeader="Level at start"
          leftValue={`${startBattery}%`}
          rightHeader="Level at end"
          rightValue={`${currentBattery}%`}
        />
        <div className="watch-me-timer-session-details-options-wrap">
          <Button variant={ButtonVariant.Icon} onClick={handleHideShow} color={Colors.LSTeal}>
            <CaretDownImg stroke={Colors.LSWhite} width={25} height={25} />
          </Button>
          <Text margin="5px" color={Colors.LSWhite}>
            Hide
          </Text>
        </div>
      </div>
    </div>
  );
};
