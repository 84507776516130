import NexmoClient, {NXMCall} from 'nexmo-client';
import React, {useEffect, useState} from 'react';
import {CallAddressInfo} from '../../components/molecules/CallAddressInfo';
import {CircleImage} from '../../components/molecules/CircleImage';
import {
  MobileCallControl,
  MobileCallControlVariant,
} from '../../components/molecules/MobileCallControl';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {nexmoJwtToken} from '../../redux/interactionChatTokenSlice';
import {MobileCallInfoViewProps} from './MobileCallInfoView.props';
import appConfig from '../../configs/appConfig.json';
import './MobileCallInfoView.styles.css';
import {setUserTaskEnd, userTaskSelector} from '../../redux/userTaskSlice';
import {CONSTANTS} from '../../constants/constants';
import {setAudioCall, setAudioCallEnd} from '../../redux/audioCallSlice';
import {ReactComponent as CircleBlink} from '../../assets/images/circle-blink.svg';
export const MobileCallInfoView: React.FC<MobileCallInfoViewProps> = ({
  profileImage,
  onHoldClick,
  onAddClick,
  onEndClick,
  address,
  date,
  phoneNumber,
  subscriberId,
  interactionId,
}) => {
  const dispatch = useAppDispatch();
  const userTask = useAppSelector(userTaskSelector);
  const [voiceCallStatus, setVoiceCallStatus] = useState<string>('');
  const [isHangUpButtonDisabled, setHangupButtonStatus] = useState<boolean>(true);
  const [pstnCall, setPstnCall] = useState<NXMCall>();
  let nexmoCall: NXMCall;
  let nexmoClient: NexmoClient;

  const initiatePstnCall = (loginToken: string, isOutgoing: boolean) => {
    const nexmo = new NexmoClient({
      nexmo_api_url: 'https://api-sg-1.nexmo.com',
      url: 'wss://ws-sg-1.nexmo.com',
      ips_url: 'https://api-sg-1.nexmo.com/v1/image',
      debug: appConfig.Debug.nexmo === 'true',
    });
    nexmoClient = nexmo;

    nexmo
      .login(loginToken)
      .then((app) => {
        if (isOutgoing) {
          setVoiceCallStatus('Dialing');
          app
            .callServer(phoneNumber, 'phone', {
              interactionId: interactionId,
              subscriberId: subscriberId,
              caller: 'RC',
            })
            .catch((error) => {
              console.log('calling nexmo number error ............');
              console.log(error);
            });
        } else {
          setVoiceCallStatus('Connecting');
          const conversationId = userTask.attributes?.conversationId?.value;
          const conferenceName = `lifestream-incoming-call-${conversationId}`;
          if (conversationId) {
            app
              .callServer('', 'phone', {
                conferenceName: conferenceName,
                caller: 'RC',
                interactionId: interactionId,
                subscriberId: subscriberId,
              })
              .catch((error) => {
                console.log('calling nexmo number error ............');
                console.log(error);
              });
          }
        }

        app.on('call:status:changed', (call: NXMCall) => {
          dispatch(setAudioCall(call));
          setPstnCall(call);
          nexmoCall = call;
          setHangupButtonStatus(false);

          if (isOutgoing && call.status == call.CALL_STATUS.UNANSWERED) {
            setVoiceCallStatus('Completed');
          } else {
            setVoiceCallStatus(call.status);
          }

          if (
            call.status == call.CALL_STATUS.COMPLETED ||
            call.status == call.CALL_STATUS.UNANSWERED ||
            call.status == call.CALL_STATUS.FAILED
          ) {
            setTimeout(() => {
              setVoiceCallStatus('');
              dispatch(setUserTaskEnd());
              onEndClick && onEndClick();
              setHangupButtonStatus(true);
            }, 1500);
          }
        });

        app.on('member:call', (member: any, call: NXMCall) => {
          dispatch(setAudioCall(call));
          setPstnCall(call);
          nexmoCall = call;
          setHangupButtonStatus(false);
          const hangupButton = document.getElementById('mobile-call-end-button');
          hangupButton?.addEventListener('click', () => callHangUp(call));
        });
      })
      .catch((err) => {
        console.log(err);
        setVoiceCallStatus('');
        dispatch(setUserTaskEnd());
        onEndClick && onEndClick();
      });
  };

  const callHangUp = (call: NXMCall) => {
    call.hangUp();
    setHangupButtonStatus(true);
    dispatch(setUserTaskEnd());
  };

  useEffect(() => {
    if (phoneNumber) {
      const isOutgoing =
        (userTask.attributes &&
          userTask.attributes.channel &&
          userTask.attributes.channel.value === CONSTANTS.CALL_OUT_CHANNELS.PSTNOut) ||
        false;

      if (userTask.attributes && userTask.attributes.nexmoToken) {
        initiatePstnCall(userTask.attributes.nexmoToken.value, isOutgoing);
      } else {
        dispatch(nexmoJwtToken()).then((tokenData) => {
          initiatePstnCall(tokenData.payload, isOutgoing);
        });
      }
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (userTask.isEnded) {
      pstnCall?.hangUp();
      nexmoClient?.disconnect();
      dispatch(setAudioCallEnd());
    }
  }, [userTask.isEnded]);

  return (
    <div className="mobile-call-info-view-wrap">
      <div style={{height: 'calc(100vh - 226px'}}>
        <div className="camera-turn-off">
          <div className="circle-blink">
            <CircleBlink width={200} height={200} />
          </div>
          <CircleImage url={profileImage} size="100px" border="none"/>
        </div>
      </div>
      <CallAddressInfo address={voiceCallStatus} date={date}/>
      <MobileCallControl
        variant={MobileCallControlVariant.AudioCall}
        onHoldClick={onHoldClick}
        onAddClick={onAddClick}
        isCallEndButtonDisabled={isHangUpButtonDisabled}
        isHoldButtonDisabled={true}
        isAddCallButtonDisabled={true}
      />
    </div>
  );
};
