import React from 'react';
import {NoteHeaderProps} from './NoteHeader.props';
import {Image} from '../../atoms/Image';
import {Text} from '../../atoms/Text';
import './NoteHeader.styles.css';
import CaretDownImg from '../../../assets/images/caret-down.svg';
import CaretUpImg from '../../../assets/images/caret-up.svg';
import {Button, ButtonVariant} from '../../atoms/Button';
import EditButton from '../../../assets/images/note-pencil.svg';
import {Tooltip} from '../../atoms/Tooltip/Tooltip';
import {getPartialNoteRef} from '../../../utils/dataPreparationHelper';

export const NoteHeader: React.FC<NoteHeaderProps> = ({
  onClickEditNote,
  onClickOpenContent,
  isOpen,
  noteInformation: {
    name = '',
    date = '',
    refNo = ''
  } = {},
}) => {
  return (
    <div className="note-header">
      <div className="note-header-left">
        <Image margin="0 8px 0 0" height="16px" width="16px" url={isOpen ? CaretUpImg : CaretDownImg} onClick={onClickOpenContent} />
        <div className="note-information">
          <Text color="#000000" fontSize={14} fontWeight="700">{date}</Text>
          <Text color="#2C2E2E" fontSize={12} fontWeight="400">{name}</Text>
        </div>
      </div>
      <div className="note-header-right">
        <Text color="#000000" fontSize={14} fontWeight="700">
          <Tooltip position={'top'} content={refNo.toString() || ''}>
            {`REF: ${getPartialNoteRef(refNo)}`}
          </Tooltip>
        </Text>
        <Button variant={ButtonVariant.OnlyImage} height="40px" onClick={onClickEditNote}>
          <Image width="20px" url={EditButton} />
        </Button>
      </div>
    </div>
  );
};
