/* eslint-disable @typescript-eslint/ban-ts-comment */
import OT from '@opentok/client';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {parseTokenData} from '../utils/parsers';
import {callOutInteraction, receiveOutgoingInteraction, refreshSession} from '../api/interactions';

export interface VideoCallState {
  token?: string;
  session?: OT.Session;
  publisher?: OT.Publisher;
  subscriber?: OT.Subscriber;
  isVideo: boolean;
  isAudio: boolean;
  isDeviceVideo: boolean;
  isDeviceAudio: boolean;
  isHold: boolean;
  additionalSubscribers?: OT.Subscriber[];
  isLoading: boolean;
  isLoadingOutgoing: boolean;
  videoOutError?: string;
}
export enum ParticipantType {
  Subscriber = 'SB',
  Operator = 'OP',
  EmergencyContact = 'EC',
  OtherContact = 'OC',
  EmergencyService = 'ES',
}
export type IChannelsForCachedSession = 'VIDEO_CALL' | 'REDUNDANCY_CHECK';
export interface IRefreshTokenRequest {
  userType: ParticipantType;
  channels: IChannelsForCachedSession[]; 
}
const initialState: VideoCallState = {
  token: undefined,
  session: undefined,
  publisher: undefined,
  subscriber: undefined,
  isVideo: true,
  isAudio: true,
  isDeviceVideo: true,
  isDeviceAudio: true,
  isHold: false,
  additionalSubscribers: undefined,
  isLoading: false,
  isLoadingOutgoing: false,
  videoOutError: ''
};

export interface SubscriberProperties {
  mainCallerProperties: OT.SubscriberProperties;
  secondCallerProperties: OT.SubscriberProperties;
  stream: OT.Stream
}

export interface InteractionVideoCallOutObject {
  incidentId: string;
  interactionId: string;
  token: string;
}

export const receiveOutgoingInteractionByInteractionId = createAsyncThunk('receiveInteraction/InteractionId', async (interactionId: string) => {
  const response = await receiveOutgoingInteraction(interactionId);
  return response.lifestreamResponse.data;
});

export const generateRefreshSession = createAsyncThunk('refreshSession', async (data:IRefreshTokenRequest) => {
  const response = await refreshSession(data);
  localStorage.setItem('sessionId',response.lifestreamResponse.data[0]?.session?.sessionId);
  localStorage.setItem('sessionRefId',response.lifestreamResponse.data[0]?.session?.sessionRefId);
  localStorage.setItem('token',response.lifestreamResponse.data[0]?.session?.token);
});

export const interactionVideoCallOut = createAsyncThunk('interactionCallOut/video', async (payload: any) => {
  const response = await callOutInteraction(payload);  
  const data = response.lifestreamResponse.data;
  return {
    incidentId: data.incidentId,
    interactionId: data.interactionId,
    token: data.session.token
  };
});

interface CallSession {
  session: OT.Session;
  token: string;
}

const videoCallSlice = createSlice({
  name: 'videoCall',
  initialState,
  reducers: {
    setVideoCallSession: (state, action: PayloadAction<CallSession>) => {
      const {apiKey, sessionId} = parseTokenData(action.payload.token);
      console.log('Opentok api key:', apiKey);
      console.log('Opentok session id:', sessionId);
      state.token = action.payload.token;
      state.session = action.payload.session;
      state.publisher = undefined;
      state.subscriber = undefined;
      state.isVideo = true;
      state.isAudio = true;
      state.isDeviceVideo = true;
      state.isDeviceAudio = true;
      state.isHold = false;
    },
    setVideoCallPublisher: (state, action: PayloadAction<OT.Publisher>) => {
      const publisher = state.session?.publish(action.payload);
      // @ts-ignore
      state.publisher = publisher;
    },
    setVideoCallSubscriber: (state, action: PayloadAction<SubscriberProperties>) => {

      if (action.payload.stream) {
        let subscriber = undefined;

        if (!state.additionalSubscribers) {
          subscriber = state.session?.subscribe(action.payload.stream, 'subscriber', action.payload.mainCallerProperties, (err)=>{
            if (err) {
              console.log(err);
            }
          });
          // @ts-ignore
          state.subscriber = subscriber;
        } else {
          subscriber = state.session?.subscribe(action.payload.stream, 'additionalSubscriber', action.payload.secondCallerProperties);
        }

        if (subscriber) {
          if (!state.additionalSubscribers) {
            // @ts-ignore
            state.additionalSubscribers = [subscriber];
          }
          else {
            // @ts-ignore
            state.additionalSubscribers = [...state.additionalSubscribers, subscriber];
          }
        }
      }
    },
    removeVideoCallSubscriber: (state, action: PayloadAction<string>) => {
      if (action.payload && state.additionalSubscribers && state.additionalSubscribers.length > 0) {
        console.log(action.payload);
        const index = state.additionalSubscribers.map(x => {
          return x.stream?.streamId;
        }).indexOf(action.payload);

        if (index > -1) {
          state.additionalSubscribers.splice(index, 1);
        }
      }
    },
    setVideoCallConnecting: (state, action: PayloadAction<{isLoading: boolean, isLoadingOutgoing?: boolean}>) => {
      state.isLoading = action.payload.isLoading;
      state.isLoadingOutgoing = action.payload.isLoadingOutgoing || false;
    },

    setVideoCallConnect: (state) => {
      if (state.session && state.token) {
        state.session.connect(state.token, (error) => {
          if (error) {
            console.log('error connecting to session');
          }
        });
      }
    },
    setVideoCallEnd: (state) => {
      state.session?.signal(
        {
          type: 'END_CALL',
          data: ''
        }, (error) => {
          if (error) {
            console.log(`signal error (${error.name}): ${error.message}`);
          }
          else {
            console.log('Signal sent successfully');
          }
        }
      );

      state.session?.unpublish(state.publisher as OT.Publisher);
      state.session?.disconnect();
      state.session?.off();
  
      state.publisher = undefined;
      state.subscriber = undefined;
      state.isVideo = true;
      state.isAudio = true;
      state.isDeviceVideo = true;
      state.isDeviceAudio = true;
      state.isHold = false;
      state.token = undefined,
      state.additionalSubscribers = undefined;
      state.session = undefined;
      state.isLoading = false;
    },
    setVideoCallVideo: (state, action: PayloadAction<boolean>) => {
      state.isVideo = action.payload;
      state.publisher?.publishVideo(action.payload);
    },
    setVideoCallAudio: (state, action: PayloadAction<boolean>) => {
      state.isAudio = action.payload;
      state.publisher?.publishAudio(action.payload);
    },
    setVideoCallHold: (state, action: PayloadAction<boolean>) => {
      state.isHold = action.payload;
      state.isVideo = !action.payload;
      state.isAudio = !action.payload;
      state.publisher?.publishAudio(!action.payload);
      state.subscriber?.subscribeToAudio(!action.payload);
      state.publisher?.publishVideo(!action.payload);
      state.subscriber?.subscribeToVideo(!action.payload);
      if (action.payload) {
        state.session?.signal(
          {
            type: 'HOLD_ON_CALL',
            data: ''
          }, (error) => {
            if (error) {
              console.log(`signal error (${error.name}): ${error.message}`);
            }
          }
        );
      } else {
        state.session?.signal(
          {
            type: 'HOLD_OFF_CALL',
            data: ''
          }, (error) => {
            if (error) {
              console.log(`signal error (${error.name}): ${error.message}`);
            }
          }
        );
      }
    },
    setVideoCallDeviceVideo: (state, action: PayloadAction<boolean>) => {
      state.isDeviceVideo = action.payload;
      state.session?.signal(
        {
          type: 'OperatorControls',
          data: `{"command": "publishVideo", "params": "${action.payload}"}`
        },
        function (error) {
          if (error) {
            console.log(`signal error (${error.name}): ${error.message}`);
          }
        }
      );
    },
    setVideoCallDeviceAudio: (state, action: PayloadAction<boolean>) => {
      state.isDeviceAudio = action.payload;
      state.session?.signal(
        {
          type: 'OperatorControls',
          data: `{"command": "publishAudio", "params": "${action.payload}"}`
        }, (error) => {
          if (error) {
            console.log(`signal error (${error.name}): ${error.message}`);
          }
        }
      );
    },
    setVideoCallCameraFlip: (state) => {
      state.session?.signal(
        {
          type: 'OperatorControls',
          data: '{"command": "switchCamera"}'
        }, (error) => {
          if (error) {
            console.log(`signal error (${error.name}): ${error.message}`);
          }
        }
      );
      if (!state.isDeviceVideo) {
        state.isDeviceVideo = true;
        state.session?.signal( 
          {
            type: 'OperatorControls',
            data: '{"command": "publishVideo", "params": "true"}'
          },
          function (error) {
            if (error) {
              console.log(`signal error (${error.name}): ${error.message}`);
            }
          }
        );
      }
    },
    setAdditionalSubscriber: (state, action: PayloadAction<OT.Subscriber>) => {
      // @ts-ignore
      state.additionalSubscribers = [...state.additionalSubscribers, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(interactionVideoCallOut.pending, (state) => {
      state.isLoadingOutgoing = true;
      state.videoOutError = undefined;
    });

    builder.addCase(interactionVideoCallOut.fulfilled, (state, action) => {
      const videoOutData = action.payload as InteractionVideoCallOutObject;

      //TODO: CALLOUT API WILL BE IMPLEMENTED HERE

      // // instead of picking   token from the payload, read from localstorage
      // const {apiKey, sessionId} = parseTokenData(videoOutData.token);
      // console.log('Opentok api key:', apiKey);
      // console.log('Opentok session id:', sessionId);
      // state.token = videoOutData.token;
      // state.session = OT.initSession(apiKey, sessionId);
      // state.publisher = undefined;
      // state.subscriber = undefined;
      // state.isVideo = true;
      // state.isAudio = true;
      // state.isDeviceVideo = true;
      // state.isDeviceAudio = true;
      // state.isHold = false;

      // state.videoOutError = undefined;
    });

    builder.addCase(interactionVideoCallOut.rejected, (state, action) => {
      state.isLoadingOutgoing = false;
      state.videoOutError = action.payload as string;
    });

    builder.addCase(receiveOutgoingInteractionByInteractionId.fulfilled, (state) => {
      state.isLoadingOutgoing = false;
    });
    builder.addCase(receiveOutgoingInteractionByInteractionId.rejected, (state) => {
      state.isLoadingOutgoing = false;
    });
  }
});

export const {
  setVideoCallConnecting,
  setVideoCallSession,
  setVideoCallPublisher,
  setVideoCallSubscriber,
  removeVideoCallSubscriber,
  setVideoCallConnect,
  setVideoCallEnd,
  setVideoCallVideo,
  setVideoCallAudio,
  setVideoCallHold,
  setVideoCallDeviceVideo,
  setVideoCallDeviceAudio,
  setVideoCallCameraFlip,
  setAdditionalSubscriber
} = videoCallSlice.actions;

export const videoCallSelector = (state: RootState) => state.videoCall;

export default videoCallSlice.reducer;
