import React, {useEffect, useState} from 'react';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import {Button, ButtonVariant} from '../../atoms/Button';
import {ReactComponent as MuteMic} from '../../../assets/images/microphone-muted.svg';
import {ReactComponent as PauseImg} from '../../../assets/images/pause.svg';
import PhoneSlashImg from '../../../assets/images/phone_slash.svg';
import ResumeImg from '../../../assets/images/play.svg';
import {Tooltip} from '../../atoms/Tooltip/Tooltip';
import {Colors} from '../../../configs/colors';
import {incomingIconTypeCaller} from '../../../utils/commonFunction';
import {ReactComponent as PhoneRejectIcon} from '../../../assets/images/phone-reject-button-icon.svg';
import {ReactComponent as ChatEndIcon} from '../../../assets/images/icon-chat-end.svg';
import {ReactComponent as BellSlash} from '../../../assets/images/icon-bell-slash.svg';
import {CONSTANTS} from '../../../constants/constants';
import {CallControlProps} from './CallControls.props';
import './CallControls.styles.css';

export const CallControls: React.FC<CallControlProps> = ({
  showEndCall = false,
  showIncomingCall = false,
  showMic = false,
  showResumeCall = false,
  showPauseCall = false,
  isMute = false,
  isChild = false,
  callerType = 'incoming....',
  onIncomingCall,
  onRejectCall,
  onEndCall,
  onPauseCall,
  onResumeCall,
  onMuteMic,
}) => {

  const [activeButton, setActiveButton] = useState<string>('');

  useEffect(()=> {
    if (isMute) {
      setActiveButton('active');
      if (showResumeCall) {
        setActiveButton('');
      }
    } else {
      setActiveButton('');
    }
  }, [showResumeCall, isMute]);
  return (
    <div className="bottom-right">
      {showMic &&
        callerType !== CONSTANTS.CALL_TYPES.MESSAGE &&
        callerType !== CONSTANTS.CALL_TYPES.WATCH_ME &&
        callerType !== CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN && (
        <Tooltip className="bottom-right-col" width="40px" position="top" content="Mute voice">
          <div
            className={`btn-controls ${activeButton ? 'active' : ''} ${showResumeCall ? 'disabled' : ''}`}
            onClick={onMuteMic}
          >
            <MuteMic width={25} height={25} />
          </div>
        </Tooltip>
      )}
      {showPauseCall &&
        callerType !== CONSTANTS.CALL_TYPES.MESSAGE &&
        callerType !== CONSTANTS.CALL_TYPES.WATCH_ME &&
        callerType !== CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN && (
        <div className="bottom-right-col">
          <div
            className={`btn-controls ${showResumeCall ? 'disabled' : ''}`}
            onClick={onPauseCall}
          >
            <PauseImg stroke={'#0DA9B9'}width={25} height={25} />
          </div>
        </div>
      )}
      {showResumeCall && (
        <div className="bottom-right-col">
          <Button
            variant={ButtonVariant.ButtonImage}
            color={Colors.RCSuccess}
            height="40px"
            width="132px"
            onClick={onResumeCall}
          >
            <Image width="20px" url={ResumeImg} />
            <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.LSWhite}>
              Resume
            </Text>
          </Button>
        </div>
      )}
      {showEndCall && callerType !== CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN && (
        <div className="bottom-right-col">
          <Button
            variant={ButtonVariant.ButtonImage}
            color={Colors.RCCritical}
            height="40px"
            width="150px"
            onClick={onEndCall}
          >
            {callerType === CONSTANTS.CALL_TYPES.MESSAGE ? (
              <ChatEndIcon width={20} height={20} stroke={Colors.LSWhite} />
            ) : (
              <Image width="20px" url={PhoneSlashImg} />
            )}
            <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.LSWhite}>
              {callerType === CONSTANTS.CALL_TYPES.MESSAGE ? 'End Chat' : 'End Call'}
            </Text>
          </Button>
        </div>
      )}
      {showIncomingCall && (
        <div className="bottom-right-col">
          <Button
            variant={ButtonVariant.ButtonImage}
            color={isChild ? Colors.RCInfo : Colors.RCCritical}
            height="40px"
            width="auto"
            onClick={onIncomingCall}
          >
            <Image width="20px" url={incomingIconTypeCaller(callerType).icon} />
            <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.LSWhite}>
              Incoming...
            </Text>
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            margin="0 0 0 8px"
            color={isChild ? Colors.RCInfo : Colors.RCCritical}
            height="40px"
            width="120px"
            onClick={onRejectCall}
          >
            {(CONSTANTS.CALL_TYPES.SOS_VIDEO_CALL === callerType || CONSTANTS.CALL_TYPES.PSTN_CALL === callerType) && <PhoneRejectIcon width={24} height={24}/>}
            {CONSTANTS.CALL_TYPES.MESSAGE === callerType && <ChatEndIcon width={24} height={24} stroke={Colors.RCCritical}/>}
            {CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN === callerType && <BellSlash width={24} height={24}/>}
            <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.RCCritical}>
              Reject
            </Text>
          </Button>
        </div>
      )}
    </div>
  );
};
