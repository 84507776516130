import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {Images} from '../configs/images';
import {getInteractionByIdForSubscriber} from '../api/interactions';
import {formatDate, getDateTimeDifference, getTimeDuration} from '../utils/dateTimeHelper';
import {sortInteractionNotesData} from '../utils/dataPreparationHelper';
import {UserDetails} from '../components/organisms/CallsTable';
import {DataMedicalInformation} from '../components/organisms/MedicalInformation';
import {UserBasicdata} from '../components/organisms/UserInfoPanel';
import {NoteItem} from '../components/layouts/NotesPanel';
import {acceptInteraction} from '../api/interactions';

export interface ExternalSession {
  sessionId: string;
  data: any;
}

export interface InteractionDetailsObject {
  isSubscriberSnapshotAvailable: boolean;
  subscriberSnapshotData: UserDetails;
  medicalData: DataMedicalInformation;
  subscriberBasicInfo: UserBasicdata;
  notes: NoteItem[];
  status: string;
  resolution:string;
  externalSession: ExternalSession;
  location?: SubscriberLocation;
  subscriberId: string;
  incidentId: string;
  interactionId: string;
  channel: string;
  subStatus: string;
}

export interface SubscriberLocation {
  address: string;
  lat: number;
  lng: number;
  time: string;
  name: string;
}

export interface InteractionDetailsObjectState {
  data?: InteractionDetailsObject;
  selectedObjectId?: string;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionDetailsObjectState = {
  data: undefined,
  selectedObjectId: undefined,
  isLoading: false,
  error: undefined
};

export const interactionAnswerByInteractionId = createAsyncThunk('acceptInteraction/InteractionId', async (interactionId: string) => {
  const response = await acceptInteraction(interactionId);
  return response.lifestreamResponse.data;
});

export const interactionDetailsByIdForSubscriber = createAsyncThunk('interactionDetails/BySubscriberId/ByInteractionId', async (interactionDetailsParams: any) => {
  const response = await getInteractionByIdForSubscriber(interactionDetailsParams.SubscriberId, interactionDetailsParams.InteractionId);
  const data = response.lifestreamResponse.data;
  return {
    isSubscriberSnapshotAvailable: data.subscriberSnapshot? true: false,
    subscriberSnapshotData: {
      id: interactionDetailsParams.SubscriberId,
      address: data.subscriberSnapshot?.account.homeAddress,
      email: data.subscriberSnapshot?.account.emailAddress,
      ethnicity: data.subscriberSnapshot?.additionalDetails?.ethnicity,
      fullName: `${data.subscriberSnapshot?.account.firstName??'N/A'} ${data.subscriberSnapshot?.account.lastName??''}`,
      hairColour: data.subscriberSnapshot?.additionalDetails?.hairColor,
      height: data.subscriberSnapshot?.additionalDetails?.heightInCm,
      weight: data.subscriberSnapshot?.additionalDetails?.weightInKg,
      home: `${data.subscriberSnapshot?.additionalDetails?.homeNumber?.countryCode??''}${data.subscriberSnapshot?.additionalDetails?.homeNumber?.number??''}`,
      livingStatus: data.subscriberSnapshot?.additionalDetails?.livingStatus,
      mobile: `${data.subscriberSnapshot?.account.mobileNumber.countryCode??''}${data.subscriberSnapshot?.account.mobileNumber.number??''}`,
      sex: data.subscriberSnapshot?.additionalDetails?.gender,
      work: `${data.subscriberSnapshot?.additionalDetails?.workNumber?.countryCode??''}${data.subscriberSnapshot?.additionalDetails?.workNumber?.number??''}`,
    },
    medicalData: {
      allergies: data.subscriberSnapshot?.additionalDetails?.allergyDetails,
      bloodType: data.subscriberSnapshot?.additionalDetails?.bloodType,
      criticalMedications: data.subscriberSnapshot?.additionalDetails?.criticalMedications,
      doctor: data.subscriberSnapshot?.additionalDetails?.doctorsName,
      doctorMobile: `${data.subscriberSnapshot?.additionalDetails?.doctorsNumber?.countryCode??''}${data.subscriberSnapshot?.additionalDetails?.doctorsNumber?.number??''}`,
      otherInformation: data.subscriberSnapshot?.additionalDetails?.otherInformation,
      conditions: [],
      markedMedicalConditions: data.subscriberSnapshot?.additionalDetails?.medicalConditions
    },
    subscriberBasicInfo:{
      name: `${data.subscriberSnapshot?.account.firstName??'N/A'} ${data.subscriberSnapshot?.account.lastName??''}`,
      age: getDateTimeDifference(data.subscriberSnapshot?.account.dateOfBirth).toString(),
      address: data.location?.formattedValue,
      date: data.initiatedDate? formatDate(data.initiatedDate.toString(), 'ddd DD/MM/YYYY'): '',
      time: data.initiatedDate? formatDate(data.initiatedDate.toString(), 'HH:mm:ss'): '',
      profileImageUrl: data.subscriberSnapshot?.profilePhoto?.image?? Images.NoProfilePictureIcon,
      dateInfoOnMap: data.initiatedDate? formatDate(data.initiatedDate.toString(), 'DD/MM/YYYY'): '',
      timeInfoOnMap: data.initiatedDate? formatDate(data.initiatedDate.toString(), 'HH:mm'): '',
      duration: data.endDate ? getTimeDuration(data.initiatedDate, data.endDate) : '',
      firstName: data.subscriberSnapshot?.account.firstName??'N/A',
    },
    notes: sortInteractionNotesData(data.notes?.map((note:any) => ({
      name: note.addedByOperatorName,
      date: formatDate(note.createdDate.toString(), 'ddd DD/MM/yy HH:mm'),
      refNo: note.noteRefNo,
      note: note.notes,
      interactionId: note.interactionId,
      createdDateTime: note.createdDate
    }))),
    status: data.mainStatus,
    resolution: data.resolutionType,
    location: {
      address: data.location?.formattedValue,
      lat: data.location?.latitude,
      lng: data.location?.longitude,
      time: data.initiatedDate? formatDate(data.initiatedDate.toString(), 'HH:mm'): 'N/A',
      name: `${data.subscriberSnapshot?.account.firstName || 'N/A'} ${data.subscriberSnapshot?.account.lastName || ''}`,
    },
    subscriberId: data.subscriberId || '',
    externalSession: {
      sessionId: data.externalSession.sessionId,
      data: data.externalSession.data
    },
    incidentId: data.incidentId,
    interactionId: data.interactionId,
    channel: data.channel,
    subStatus: data.subStatus,
  };
});

const interactionDetailsSlice = createSlice({
  name: 'interactionDetails',
  initialState,
  reducers: {
    setInteractionDetailsData: (state, action: PayloadAction<InteractionDetailsObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as InteractionDetailsObject;
      state.error = undefined;
    },
    setSelectedInteractionDetailsObjectId: (state, action: PayloadAction<string>) => {
      state.selectedObjectId = action.payload;
    },
    setInteractionDetailsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },
    setSelectedInteractionDetailsObjectClear: (state) => {
      state.selectedObjectId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(interactionDetailsByIdForSubscriber.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionDetailsByIdForSubscriber.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as InteractionDetailsObject;
      state.error = undefined;
    });

    builder.addCase(interactionDetailsByIdForSubscriber.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInteractionDetailsData,
  setSelectedInteractionDetailsObjectId,
  setInteractionDetailsClear,
  setSelectedInteractionDetailsObjectClear
} = interactionDetailsSlice.actions;

export const interactionDetailsSelector = (state: RootState) => state.interactionDetails;

export default interactionDetailsSlice.reducer;
