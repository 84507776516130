import React from 'react';
import {ContactInformationPanelWrapProps} from './ContactInformationPanelWrap.props';
import './ContactInformationPanelWrap.styles.css';

export const ContactInformationPanelWrap: React.FC <ContactInformationPanelWrapProps> = ({
  width,
  children
}) => {
  return (
    <div className= "contact-information-panel-wrap-style">
      {children}
    </div>
  );
};