import React from 'react';
import {TabProps} from '.';
import './Tab.styles.css';

export const Tab: React.FC<TabProps> = ({
  label,
  activeTab,
  onClick,
}) => {
  let className = 'tab-list-item';

  if (activeTab === label) {
    className += ' tab-list-active';
  }
  return (
    <li
      className={className}
      onClick={() => onClick(label)}
    >
      {label}
    </li>
  );
};
