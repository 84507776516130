import React, {useEffect, useState} from 'react';
import './Dropdown.styles.css';
import {DropdownProps} from './Dropdown.props';
import DropdownImg from '../../../assets/images/caret-down.svg';
import CaretUpImg from '../../../assets/images/caret-up.svg';
import {Image} from '../../atoms/Image';
import {Text} from '../../atoms/Text';

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  height = 'auto',
  width = 'auto',
  className = '',
  title = 'Dropdown',
  srcImg,
  widthImg = '20px',
  fontSizeTitle = 15,
  fontWeightTitle = '600',
  dropDownTitle = '',
  show,
  position,
}) => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [show]);
  return (
    <div>
      <div
        style={{width, height}}
        className={`dropdown-style ${className}`}
        onClick={() => setOpen(!isOpen)}>
        {
          dropDownTitle && <Text margin="0 5px">{dropDownTitle}</Text>
        }
        {srcImg && <Image width={widthImg} url={srcImg} />}
        <Text fontSize={fontSizeTitle} fontWeight={fontWeightTitle}>{title}</Text>
        <span className="dropdown-img"><Image width="14px" url={isOpen ? CaretUpImg : DropdownImg} /></span>
      </div>
      {
        isOpen &&
        <div className={`dropdown-content-${position}`} >
          {children}
        </div>
      }
    </div>
  );
};
