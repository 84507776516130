import React, {useEffect, useState} from 'react';
import './DropdownSelect.styles.css';
import {DropdownSelectProps, OptionsList} from './DropdownSelect.props';
import DropdownImg from '../../../assets/images/caret-down.svg';
import CaretUpImg from '../../../assets/images/caret-up.svg';
import {Image} from '../../atoms/Image';
import {Text} from '../../atoms/Text';

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
  height = 'auto',
  width = 'auto',
  padding = '8px 20px',
  backgroundColor = '',
  borderColor = '',
  fontSizeTitle = 15,
  fontWeightTitle = '400',
  optionsList = [],
  selectImage = true,
  title = '',
  onSelected,
  selectedItemValue,
}) => {
  const [showOptionList, setShowOptionList] = useState(false);
  const [selectedValue, setSelectedValue] = useState<OptionsList>();
  const defaultOption = (selectedOption?:OptionsList) => {
    selectedOption = selectedOption? selectedOption : optionsList[0];
    return (
      <div className="select-options-default">
        {selectImage && <Image width={selectedOption.widthImgOption} url={selectedOption.urlImgActiveOption ? selectedOption.urlImgActiveOption : selectedOption.urlImgOption} />}
        <Text fontSize={fontSizeTitle} margin="0 5px 0" fontWeight={fontWeightTitle}>{selectedOption.name}</Text>
      </div>
    );
  };

  const viewContent = (option: OptionsList) => {
    return (
      <div className="select-options-default">
        {selectImage && <Image width={option.widthImgOption} url={option.urlImgOption} />}
        <Text fontSize={fontSizeTitle} margin="0 5px 0" fontWeight={fontWeightTitle}>{option.name}</Text>
      </div>
    );
  };

  const handleOptionClick = (option: OptionsList) => {
    setSelectedValue(option);
    onSelected(option.value);
  };

  useEffect(() => {
    if(selectedItemValue){
      const selecteditem = optionsList.find((i:OptionsList) => i.value.toLowerCase() == selectedItemValue.toLowerCase());
      if(selecteditem){
        setSelectedValue(selecteditem);
      }
    }
  }, [selectedItemValue]);

  return (
    <div
      style={{width, height, backgroundColor, borderColor, padding}}
      className="dropdown-select-style"
      onClick={() => setShowOptionList(!showOptionList)}>
      {title && <span className="dropdown-select-title">{title}</span>}
      {defaultOption(selectedValue)}
      <span className="dropdown-img"><Image width="14px" url={showOptionList ? CaretUpImg : DropdownImg} /></span>
      {
        showOptionList && (
          <ul className="select-options">
            {optionsList.map((option, index) => {
              return (
                <li
                  className="custom-select-option"
                  data-name={option.name}
                  key={index}
                  onClick={() => handleOptionClick(option)}
                >
                  {viewContent(option)}
                </li>
              );
            })}
          </ul>
        )
      }
    </div>
  );
};
