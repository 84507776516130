import React, {useState} from 'react';
import {CallsTableProps, InteractionsData} from '.';
import './CallsTable.styles.css';
import appConfig from '../../../configs/appConfig.json';
import {TableRow} from '../../molecules/TableRow';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import CaretDownImg from '../../../assets/images/caret-down.svg';
import CaretUpImg from '../../../assets/images/caret-up.svg';
import {Colors} from '../../../configs/colors';
import {ReactComponent as CaretDown} from '../../../assets/images/caret-down.svg';
import {TableHeader} from '../../molecules/TableHeader';
import {CheckBox} from '../../atoms/CheckBox';

const dataHeader = [
  {
    type: 'Type',
    showIcon: false,
    widthType: '5%',
  },
  {
    type: 'From',
    showIcon: false,
    widthType: '15%',
  },
  {
    type: 'Contact',
    showIcon: false,
    widthType: '14%',
  },
  {
    type: 'Location',
    showIcon: false,
    widthType: '35%'
  },
  {
    type: 'Received',
    showIcon: true,
    widthType: '16%',
    Icon: <CaretDown stroke={Colors.LSDarkTeal} width={16} height={16} />

  },
  {
    type: 'Dur.',
    showIcon: true,
    widthType: '7%',
  },
  {
    type: 'Call Op',
    showIcon: false,
    widthType: '8%',
  }
];

export const CallsTable: React.FC<CallsTableProps> = ({
  rowClicked,
  doubleRowClick,
  activeInteractions = [],
  openInteractions = [],
}) => {
  const [showActiveCalls, setShowActiveCalls] = useState<boolean>(true);
  const [showCalls, setShowCalls] = useState<boolean>(true);
  const [isAdditionalDataEnable, setAdditionalDataEnableStatus] = useState<boolean>(false);

  const handleRowClick = (event: any, call: InteractionsData, index: number) => {
    rowClicked(event, call);
    const elms = document.querySelectorAll('.table-home-content');
    Array.from(elms).map((elem, idx: number) => {
      if (idx === index) {
        elms[idx].classList.add('active');
      } else {
        elms[idx].classList.remove('active');
      }
    });
  };

  const renderActiveCalls = () => {
    return activeInteractions.map((call, key) =>
      <div key={key} onClick={(e) => rowClicked(e, call)} onDoubleClick={(e) => doubleRowClick(e, call)}>
        <TableRow rowData={call} showAdditionalData={isAdditionalDataEnable}/>
      </div>
    );
  };

  const renderCalls = () => {
    return openInteractions.map((call, key) =>
      <div className="table-home-content" key={key} onClick={(e) => handleRowClick(e, call, key)} onDoubleClick={(e) => doubleRowClick(e, call)}>
        <TableRow rowData={call} showAdditionalData={isAdditionalDataEnable}/>
      </div>
    );
  };

  const onCheckBoxChange = (value: boolean) => {
    setAdditionalDataEnableStatus(value);
    console.log(value);
  };

  return (
    <div className="table-style home-table">
      {appConfig.Debug.lifestreamData === 'true' && <div className="data-toggle">
        <span>Show Additional Data</span>
        <CheckBox value={false} onChange={onCheckBoxChange}></CheckBox>
      </div>}
      <TableHeader dataHeader={dataHeader} />
      <div className="table-content">
        <div className="table-toggle" onClick={() => setShowActiveCalls(!showActiveCalls)}>
          <Image margin="0 8px 0 0" height="16px" width="16px" url={showActiveCalls ? CaretDownImg : CaretUpImg} />
          <div className="note-information">
            <Text color="#000000" fontSize={14} fontWeight="700">Active Calls</Text>
          </div>
        </div>
        {
          showActiveCalls &&
          <div className="calls-table-content">
            {renderActiveCalls()}
          </div>
        }
        <div className="table-toggle" onClick={() => setShowCalls(!showCalls)}>
          <Image margin="0 8px 0 0" height="16px" width="16px" url={showCalls ? CaretDownImg : CaretUpImg} />
          <div className="note-information">
            <Text color="#000000" fontSize={14} fontWeight="700">Open Calls</Text>
          </div>
        </div>
        {
          showCalls &&
          <div className="calls-table-content open-calls-table">
            {renderCalls()}
          </div>
        }
      </div>
    </div >
  );
};