import React from 'react';
import {Text} from '../../atoms/Text';
import {CheckBox} from '../../atoms/CheckBox';
import {Colors} from '../../../configs/colors';
import {CheckBoxFieldProps} from './CheckBoxField.props';
import './CheckBoxField.styles.css';

export const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  title,
  color = Colors.LSTeal,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="checkbox-field-style">
      <CheckBox disabled={disabled} value={value} color={color} onChange={onChange}/>
      <Text fontSize={15} color={Colors.NeutralGrey1} margin="0 0 0 19px">{title}</Text>
    </div>
  );
};
