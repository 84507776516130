import React from 'react';
import {CircleImage} from '../../molecules/CircleImage';
import {Colors} from '../../../configs/colors';
import {ProfileImageProps, ProfileStatus} from './ProfileImage.props';
import './ProfileImage.styles.css';

export const ProfileImage: React.FC<ProfileImageProps> = ({
  url,
  status = ProfileStatus.Active,
  size = '40px',
  borderWidth = '0px',
  borderColor = Colors.Transparent,
  onClick,
  border,
}) => {
  
  const getStatusColor = (): string => {
    switch (status) {
    case ProfileStatus.Active:
    case ProfileStatus.OnInteraction:
      return Colors.RCSuccess;
    case ProfileStatus.Busy:
      return Colors.RCCritical;
    case ProfileStatus.Inactive:
      return Colors.RCWarning;
    default:
      return Colors.NeutralGrey2;
    }
  };

  return (
    <div className="profile-image-style" style={{width: size, height: size}}>
      <CircleImage
        url={url}
        size={size}
        borderColor={borderColor}
        borderWidth={borderWidth}
        onClick={onClick}
        border={border}
      />
      <div className="profile-status-style" style={{backgroundColor: getStatusColor()}} />
    </div>
  );
};
