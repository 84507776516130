import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {getSubscriberStatus} from '../api/notifications';

export interface DialOutCallObjectState {
  data: boolean;
  isLoading: boolean;
  error?: string;
}

const initialState: DialOutCallObjectState = {
  data: false,
  isLoading: false,
  error: undefined
};

export const notificationuserStatusBySubscriberId = createAsyncThunk('notificationuserStatus/subscriberId', async (subscriberid: string) => {
  const response = await getSubscriberStatus(subscriberid);
  return response.lifestreamResponse.data.isUserActive;
});

const notificationuserStatusSlice = createSlice({
  name: 'notificationuserStatus',
  initialState,
  reducers: {
    setNotificationuserStatusData: (state, action: PayloadAction<boolean>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(notificationuserStatusBySubscriberId.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(notificationuserStatusBySubscriberId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(notificationuserStatusBySubscriberId.rejected, (state, action) => {
      state.isLoading = false;
      state.data = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  setNotificationuserStatusData
} = notificationuserStatusSlice.actions;

export const notificationuserStatusDataSelector = (state: RootState) => state.notificationUserStatus;

export default notificationuserStatusSlice.reducer;
