/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios';
import {PublicClientApplication} from '@azure/msal-browser';
import {CONSTANTS} from '../constants/constants';
import appConfig from '../configs/appConfig.json';

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

let accessToken:string|void;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // console.info(`[request] [${JSON.stringify(config)}]`);

  if (accessToken) {
    //@ts-ignore
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  //@ts-ignore
  config.headers['Content-Type'] = 'application/json';
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error] [${JSON.stringify(error)}]`);

  const originalRequest = error.config;

  // AUth URL prevension
  // if (error?.response.status === 401 && originalRequest.url === `${AUTH_URL}/user/login`) {
  //   return Promise.reject(error);
  // }

  //@ts-ignore
  if (error?.response.status === 401 && !originalRequest._retry) {
    //@ts-ignore
    originalRequest._retry = true;
    const serviceType = getServiceType(originalRequest.url);
    getAccessToken(serviceType).then(token => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return axios(originalRequest);
    });
  }
  
  return Promise.reject(error);
};

axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);

export const httpClient = (url: string, method: HttpMethod, data: any, serviceType: string) => {
  return new Promise((resolve, reject) => {
    const params: any = {method, url: `${appConfig.Api.baseUrl}${url}`};
    if (data) {
      params.data = data;
    }
    getAccessToken(serviceType).then(token =>{
      accessToken = token;
      axios(params).then(response => {
        if (response.status > 199 && response.status < 300) {
          resolve(response.data);
        } else {
          reject('Response is not success');
        }
      }).catch(error => {
        reject(error);
      });
    });
  });
};

const logout = () => {
  // add Msal logout call here
  window.location.href = '/';
};

export const getAccessToken = (serviceType:string): Promise<string|void> => {
  const scopes = getAccessTokenScopesObjects(serviceType);
  const msalObject = new PublicClientApplication(appConfig.msalConfig);
  const accessTokenRequest = {
    ...scopes,
    account: msalObject.getAllAccounts()[0]
  };

  const accessToken = msalObject.acquireTokenSilent(accessTokenRequest).then((response) => {
    return response.accessToken;
  }).catch((e) => {
    msalObject.acquireTokenPopup(accessTokenRequest).then((response) => {
      return response.accessToken;
    });
  });

  return accessToken;
};

const getAccessTokenScopesObjects = (serviceType:string) : any =>{
  let scopeObj: any;
  switch (serviceType) {
  case CONSTANTS.SERVICE_TYPES.SUBSCRIBERS:
    scopeObj = appConfig.Services.subscribers;
    break;
  case CONSTANTS.SERVICE_TYPES.INTERACTIONS:
    scopeObj = appConfig.Services.interactions;
    break;
  case CONSTANTS.SERVICE_TYPES.LOCATIONS:
    scopeObj = appConfig.Services.locations;
    break;
  case CONSTANTS.SERVICE_TYPES.NOTIFICATIONS:
    scopeObj = appConfig.Services.notifications;
    break;
  default:
    scopeObj = appConfig.Services.subscribers;
    break;
  }

  return scopeObj;
};

const getServiceType = (url:string|undefined) : string =>{
  if(url){
    if(url.toLowerCase().includes('/subscribers/')){
      return CONSTANTS.SERVICE_TYPES.SUBSCRIBERS;
    }else if(url.toLowerCase().includes('/interactions/')){
      return CONSTANTS.SERVICE_TYPES.INTERACTIONS;
    }else if(url.toLowerCase().includes('/locations/')){
      return CONSTANTS.SERVICE_TYPES.LOCATIONS;
    }
  }

  return CONSTANTS.SERVICE_TYPES.SUBSCRIBERS;
};