import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './Footer.styles.css';
import {CallerLocation, CallerLocationItem} from '../../components/molecules/CallerLocation';
import {CallControls} from '../../components/molecules/CallControls/CallControls';
import {parseAgeAttribute, parseInteractionIdAttribute, parseSubscriberIdAttribute} from '../../utils/parsers';
import {CONSTANTS} from '../../constants/constants';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {
  useGetLocationQuery, userLocationFetch, userLocationSelector,
} from '../../redux/userLocationSlice';
import {
  setUserTaskAccept,
  setUserTaskReject,
  setUserTaskEnd,
  userTaskSelector,
} from '../../redux/userTaskSlice';
import {
  setVideoCallHold,
  setVideoCallAudio,
  videoCallSelector,
  setVideoCallEnd,
  setVideoCallConnecting
} from '../../redux/videoCallSlice';
import {parseNameAttribute} from '../../utils/parsers';
import {formatDate} from '../../utils/dateTimeHelper';
import {getAddressFromGeoLocation} from '../../utils/commonFunction';
import {interactionDetailsSelector} from '../../redux/interactionDetailsSlice';
import {setSubscriberLocation, setSubscriberLocationClear} from '../../redux/subscriberLocationSlice';
import {audioCallSelector, setAudioCallAudioState, setAudioCallHold} from '../../redux/audioCallSlice';
import {pstnCallHoldAndResumeByCallId} from '../../redux/interactionPstnCallOutSlice';
import {subscriberDetailsSelector} from '../../redux/subscriberDetailsSlice';
import {Dropdown} from '../../components/molecules/Dropdown';
import {Images} from '../../configs/images';


export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userTask = useAppSelector(userTaskSelector);
  const videoCall = useAppSelector(videoCallSelector);
  const audioCall = useAppSelector(audioCallSelector);
  const userLocation = useAppSelector(userLocationSelector);
  const subscriberDetails = useAppSelector(subscriberDetailsSelector);
  const interactionsDetailsSelect = useAppSelector(interactionDetailsSelector);
  const [showIncomingCall, setShowIncomingCall] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isChild, setIsChild] = useState(false);
  const [showMic, setShowMic] = useState(false);
  const [showPauseCall, setShowPauseCall] = useState(false);
  const [showResumeCall, setShowResumeCall] = useState(false);
  const [callerLocation, setCallerLocation] = useState<CallerLocationItem>({
    fullName: '',
    location: '',
    time: '',
  });
  const location = useLocation();
  const [showCallerLocation, setShowCallerLocation] = useState<boolean>(true);
  const [callerType, setCallerType] = useState<string>(CONSTANTS.CALL_TYPES.SOS_VIDEO_CALL);
  const [skip, setSkip] = useState<boolean>(true);
  const subscriberId =
    (userTask.attributes && parseSubscriberIdAttribute(userTask.attributes)) || interactionsDetailsSelect?.data?.subscriberId || '';

  const {isFetching} = useGetLocationQuery(subscriberId, {
    pollingInterval: 10000,
    skip,
  });

  useEffect(() => {
    if (userLocation.data && !userTask.isIncomming) {
      const {latitude, longitude, timestamp} = userLocation.data;
      const time = interactionsDetailsSelect?.data?.location?.time || formatDate(timestamp, 'HH:mm');
      const name =  interactionsDetailsSelect?.data?.subscriberBasicInfo?.name || parseNameAttribute(userTask.attributes);
      let subscriberName = '';
      if (subscriberDetails.data && subscriberDetails.data.subscriberData && subscriberDetails.data.subscriberData.fullName) {
        subscriberName = subscriberDetails.data.subscriberData.fullName;
      }
      // We will be removing this once the location API is changed
      getAddressFromGeoLocation(`${latitude}`, `${longitude}`).then(address => {
        dispatch(setSubscriberLocation(address));
        setCallerLocation({
          ...callerLocation,
          lat: latitude,
          lng: longitude,
          location: address,
          fullName: subscriberName || name,
          time
        });
      }).catch(() => {
        dispatch(setSubscriberLocationClear());
        setCallerLocation({
          ...callerLocation,
        });
      });
    }
  }, [userLocation.data]);

  useEffect(()=>{
    if (isFetching) {
      dispatch(userLocationFetch(subscriberId));
    }
  }, [isFetching]);

  useEffect(() => {
    if (userTask.isIncomming) {
      setShowIncomingCall(true);
      setSkip(true);
      if (userTask.attributes) {
        const age = parseAgeAttribute(userTask.attributes);
        const interactionType = userTask.attributes.InteractionType.value;
        setCallerType(interactionType);
        setIsChild(age < 15);
      }
    } else {
      setShowIncomingCall(false);
    }
  }, [userTask.isIncomming]);

  useEffect(() => {
    if (userTask.attributes) {
      const interactionType = userTask.attributes.InteractionType.value;
      setCallerType(interactionType);
    }
  }, [userTask.attributes]);

  useEffect(() => {
    if (userTask.isConnected) {
      setSkip(false);
      setShowIncomingCall(false);
      setIsConnected(true);
      setShowMic(true);
      setShowPauseCall(true);
      setSkip(false);

      if (videoCall.isHold) {
        setShowResumeCall(true);
      } else {
        setShowResumeCall(false);
      }
      navigate('/callers');
    } else {
      setSkip(true);
      setIsConnected(false);
      setShowMic(false);
      setShowPauseCall(false);
      setShowResumeCall(false);
    }
  }, [userTask.isConnected, videoCall.isHold]);

  useEffect(() => {
    if (!userTask.isConnected && !userTask.isIncomming && interactionsDetailsSelect.data && interactionsDetailsSelect.data.location) {
      const {lat, lng, address, time, name} = interactionsDetailsSelect.data.location;
      let subscriberName = '';
      if (subscriberDetails.data && subscriberDetails.data.subscriberData && subscriberDetails.data.subscriberData.fullName) {
        subscriberName = subscriberDetails.data.subscriberData.fullName;
      }
      dispatch(setSubscriberLocation(address));
      setCallerLocation({
        ...callerLocation,
        lat,
        lng,
        location: address,
        fullName: subscriberName || name,
        time,
      });

      const {channel, subStatus} = interactionsDetailsSelect.data;
      if(channel === CONSTANTS.CALL_TYPES.WATCH_ME && subStatus === CONSTANTS.INTERACTION_SUBSTATUS.InProgress)
      {
        setSkip(false);
      }
    }
    if(!interactionsDetailsSelect.data)
    {
      setSkip(true);
    }
  }, [interactionsDetailsSelect.data]);

  useEffect(() => {
    if (userTask.isMissed) {
      setSkip(true);
      setIsConnected(false);
      setShowMic(false);
      setShowPauseCall(false);
      setShowResumeCall(false);
    }
  }, [userTask.isMissed]);

  useEffect(() => {
    if (userTask.isIncomming) {
      location.pathname.includes('/callers') ? setShowCallerLocation(true) : setShowCallerLocation(false);
    } else {
      setShowCallerLocation(true);
    }
  }, [location.pathname]);

  const onAcceptContact = () => {
    dispatch(setUserTaskAccept());
    dispatch(setVideoCallConnecting({isLoading: true}));
    localStorage.setItem('subscriberId', parseSubscriberIdAttribute(userTask.attributes));
    localStorage.setItem('interactionId', parseInteractionIdAttribute(userTask.attributes));
  };

  const onRejectContact = () => {
    dispatch(setUserTaskReject());
  };

  const onEndContact = () => {
    dispatch(setUserTaskEnd());
    dispatch(setVideoCallEnd());
    dispatch(setVideoCallConnecting({isLoading: false, isLoadingOutgoing: false}));
  };

  const onPauseCall = () => {
    dispatch(setVideoCallHold(true));
    if(audioCall.call){
      dispatch(setAudioCallHold(true));
      dispatch(pstnCallHoldAndResumeByCallId({'callId': audioCall.call.id, 'action': 'hold'}));
    }
  };

  const onResumeCall = () => {
    dispatch(setVideoCallHold(false));
    if(audioCall.call){
      dispatch(setAudioCallHold(false));
      dispatch(pstnCallHoldAndResumeByCallId({'callId': audioCall.call.id, 'action': 'resume'}));
    }
  };

  const onMuteMic = () => {
    dispatch(setVideoCallAudio(!videoCall.isAudio));
    dispatch(setAudioCallAudioState(!audioCall.isMute));
  };

  return (
    <div className="footer-style">
      <div className="footer-right">
        <CallControls
          isMute={!videoCall.isAudio}
          isChild={isChild}
          showEndCall={isConnected || userTask.isMissed}
          showMic={showMic}
          showIncomingCall={showIncomingCall && location.pathname.includes('/callers')}
          showPauseCall={showPauseCall}
          showResumeCall={showResumeCall}
          onIncomingCall={onAcceptContact}
          onRejectCall={onRejectContact}
          onEndCall={onEndContact}
          onPauseCall={onPauseCall}
          onResumeCall={onResumeCall}
          onMuteMic={onMuteMic}
          callerType={callerType}
        />
        <div className="caller-hold-status">
          <Dropdown
            position="bottom-right"
            srcImg={Images.Timer}
            height="40px"
            width="200px"
            title="Caller hold status"
          >
          </Dropdown>
        </div>
      </div>
      {(isConnected || interactionsDetailsSelect.data?.subscriberId) && showCallerLocation && <CallerLocation callerLocation={callerLocation} />}
    </div>
  );
};
