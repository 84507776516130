import React, {useState, useEffect} from 'react';
import {SearchBarProps, SearchType} from './SearchBar.props';
import {Colors} from '../../../configs/colors';
import {SwitcherButton} from '../../molecules/SwitcherButton';
import {InputField, InputFieldVariant} from '../../atoms/InputField';
import {Button, ButtonVariant} from '../../atoms/Button';
import './SearchBar.styles.css';
import {ReactComponent as User} from '../../../assets/images/user.svg';
import {ReactComponent as PhoneHistory} from '../../../assets/images/phone-history.svg';

export const SearchBar: React.FC<SearchBarProps> = ({isOpenSearchResult, onSearchPress, isFetching, onKeyPress}) => {

  const [searchType, setSearchType] = useState(SearchType.user);
  const [searchVal, setSearchVal] = useState('');
  const [checkFocus, setCheckFocus] = useState<boolean>(false);

  return <div className="search-bar-outer-wrap">
    <div className="search-bar-wrap">
      <SwitcherButton
        activeColor={Colors.LSWhite}
        height="40px"
        width="auto"
        backgroundColor={Colors.LSSkyLight}
        labels={['Search for a person', 'Search for a call']}
        onChange={(val, index) => {
          setSearchType(index == 0 ? SearchType.user : SearchType.phone);
        }}
      />
      <div className={`search-bar-text-wrap ${checkFocus ? 'focused' : ''}`}>
        <InputField
          variant={InputFieldVariant.Labelled}
          width="100%"
          label={searchType === SearchType.user ? 'ENTER THE PERSON\'S NAME' : 'ENTER THE CALL REFERENCE NUMBER'}
          labelColor={Colors.NeutralGrey2}
          type="text" alt=""
          placeholder={searchType === SearchType.user ? 'Enter the person\'s name' : 'Enter the call reference number'}
          value={searchVal}
          onChange={e => setSearchVal(e.target.value)}
          Icon={searchType === SearchType.user ? User : PhoneHistory}
          onKeyPress={onKeyPress}
          onCheckFocus={(value)=>setCheckFocus(value)}
          closeIcon={!!searchVal}
          onClearValue={() => setSearchVal('')}
        />
        <Button
          onClick={() => onSearchPress(searchVal, searchType)}
          variant={ButtonVariant.Primary}
          text={'Search'}
          color={Colors.LSDarkTeal}
          height="56px"
          width="135px"
          margin="0px 0px 0px 10px"
          disabled={isFetching || !searchVal}
          fontSize={17}
          fontWeight={500}
        />
      </div>
    </div>
  </div>;
};
