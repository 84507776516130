import React from 'react';
import {Colors} from '../../../configs/colors';
import {CircleText} from '../../atoms/CircleText';
import {Text} from '../../atoms/Text';
import {WatchMeBulletListItemProps} from './WatchMeBulletListItem.props';
import './WatchMeBulletListItem.styles.css';
import {Button, ButtonVariant} from '../../atoms/Button';

export const WatchMeBulletListItem: React.FC<WatchMeBulletListItemProps> = ({
  index,
  ButtonIcon,
  contact,
  buttonText,
  buttonColor,
  labelText,
  callType,
  onButtonClick,
  disabled,
  className,
}) => {
  return (
    <div
      className="watch-me-bullet-list-item-wrap"
      style={{backgroundColor: Colors.NeutralGrey1}}
    >
      <div className="left-item-wrap">
        <CircleText text={index.toString()} backgroundColor={'rgba(255, 255, 255, 0.16)'} width="32px" height="32px" />
        <div className="watch-me-bullet-list-item-body">
          <Text color={Colors.LSWhite}>
            {labelText} <strong>{callType}</strong>
          </Text>
          {contact && (
            <div className="watch-me-bullet-list-item-name">
              <Text color={Colors.LSWhite}>&#124;</Text>
              <Text color={Colors.LSTealAccent} margin="0px 5px">
                {' '}
                <>
                  {contact.name} &bull; {contact.relationship}
                </>
              </Text>
            </div>
          )}
        </div>
      </div>
      {buttonText && (
        <div className={className}>
          <Button
            disabled={disabled}
            variant={ButtonVariant.Round}
            onClick={onButtonClick}
            width="auto"
            height="32px"
            text={buttonText}
            color={buttonColor}
            fontSize={12}
          >
            {ButtonIcon && (
              <ButtonIcon stroke={Colors.LSWhite} width={16} height={16} />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};
