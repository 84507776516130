import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {addInteractionsNotes} from '../api/interactions';
import {formatDate} from '../utils/dateTimeHelper';
import {NoteItem} from '../components/layouts/NotesPanel';

export interface InteractionAddNoteObjectState {
  data?: NoteItem;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionAddNoteObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const addInteractionNotes = createAsyncThunk('addInteractionNotes/ById', async (interactionNotesParams: any) => {
  const response = await addInteractionsNotes(interactionNotesParams.InteractionId, interactionNotesParams.Note);
  const data = response.lifestreamResponse.data;
  return {
    name: data.addedByOperatorName,
    date: formatDate(data.createdDate.toString(), 'ddd DD/MM/yy HH:mm'),
    refNo: data.noteRefNo,
    note: data.notes,
    interactionId: data.interactionId,
    createdDateTime: data.createdDate
  };
});

const addInteractionNoteSlice = createSlice({
  name: 'addInteractionNotes',
  initialState,
  reducers: {
    setInteractionNoteData: (state, action: PayloadAction<NoteItem | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as NoteItem;
      state.error = undefined;
    },
    setInteractionNoteClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addInteractionNotes.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addInteractionNotes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as NoteItem;
      state.error = undefined;
    });

    builder.addCase(addInteractionNotes.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInteractionNoteData,
  setInteractionNoteClear
} = addInteractionNoteSlice.actions;

export const interactionAddNoteSelector = (state: RootState) => state.interactionNotes;

export default addInteractionNoteSlice.reducer;
