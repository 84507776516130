export enum ProfileStatus {
  Active = 'active',
  Inactive = 'inactive',
  Busy = 'busy',
  OnInteraction = 'on-interaction',
}

export interface ProfileImageProps {
  url: string;
  status?: ProfileStatus;
  size?: string;
  borderWidth?: string;
  borderColor?: string;
  onClick?: () => void;
  border?: string;
}
