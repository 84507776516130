import React, {useState} from 'react';
import {CircleImage} from '../../molecules/CircleImage';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';
import {ContactMarkerProps} from './ContactMarker.props';
import {Text} from '../../atoms/Text';
import './ContactMarker.styles.css';
import {Images} from '../../../configs/images';
import {OverlayView} from '@react-google-maps/api';
import {MapMarker} from '../../molecules/MapMarker';

export const ContactMarker: React.FC<ContactMarkerProps> = ({
  imageUrl,
  contactInfo,
  position,
  size = '48px',
  borderWidth = '3px',
  showContactMarker = false,
  showOnlySub = false,
  borderColor = Colors.LSDarkTeal,
  isShowBroadcast = false,
  stroke = Colors.RCSuccess,
  onToggleInfo,
  dateCall,
}) => {
  const [showSub, setShowSub] = useState(false);
  const toggleShowSubInfo = () => {
    setShowSub(!showSub);
  };

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div className="contact-marker-container">
        <div className="contact-marker-wrap-container">
          <div className="contact-marker-wrap">
            <CircleImage
              url={imageUrl}
              size={size}
              borderColor={borderColor}
              borderWidth={borderWidth}
              onClick={toggleShowSubInfo}
            />
            {!showOnlySub && (
              <>
                <div className="contact-info-container">
                  <Text
                    fontSize={15}
                    fontWeight="500"
                    color={Colors.NeutralGrey1}
                  >
                    {!showOnlySub && (showSub || !showContactMarker)
                      ? contactInfo?.fullName
                      : contactInfo?.name}
                  </Text>
                  <div className="distance-time">
                    {contactInfo?.distance && (
                      <Text
                        fontSize={12}
                        fontWeight="400"
                        color={Colors.NeutralGrey2}
                      >
                        {contactInfo.distance}
                      </Text>
                    )}
                    {dateCall && (
                      <Text
                        fontSize={12}
                        margin="5px"
                        color={Colors.NeutralGrey2}
                      >
                        {dateCall}
                      </Text>
                    )}
                    {!showOnlySub && (showSub || !showContactMarker) && (
                      <div className="time-of-last">
                        <Image
                          url={Images.ClockCounterInactive}
                          height="12px"
                          width="12px"
                        />
                        <Text
                          fontSize={12}
                          fontWeight="400"
                          color={Colors.NeutralGrey2}
                        >
                          {contactInfo?.timestamp}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
                {!showOnlySub &&
                  (showSub || !showContactMarker) &&
                  !dateCall && (
                  <Image
                    margin="0px 5px"
                    url={Images.ContactInfo}
                    height="24px"
                    width="24px"
                    onClick={onToggleInfo}
                  />
                )}
              </>
            )}
          </div>
          <div className="pointer"></div>
        </div>
        <div className="marker-pointer">
          {isShowBroadcast ? (
            <MapMarker stroke={stroke} isShowBroadcast={isShowBroadcast} />
          ) : (
            <Image url={Images.GreenMarker} width="26px" height="26px" />
          )}
        </div>
      </div>
    </OverlayView>
  );
};
