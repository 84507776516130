/* eslint-disable @typescript-eslint/no-explicit-any */
import {CONSTANTS} from '../constants/constants';
import {IRefreshTokenRequest} from '../redux/videoCallSlice';
import {HttpMethod, httpClient} from './httpClient';

export const getNexmoJwtToken = (): Promise<any> => {
  return httpClient('/interactions/interaction/pstn/jwt', HttpMethod.Post, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const getInteractionsHistory = (subscriberId: string): Promise<any> => {
  return httpClient(`/interactions/interaction/${subscriberId}/history`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const getInteractionByIdForSubscriber = (subscriberId: string, sinteractionId: string): Promise<any> => {
  return httpClient(`/interactions/interaction/${sinteractionId}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const initiateDialoutCall = (data: any): Promise<any> => {
  return httpClient('/interactions/interaction/dialout', HttpMethod.Post, data, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const addInteractionsNotes = (id:string, note: string): Promise<any> => {
  return httpClient(`/interactions/interaction/${id}/note`, HttpMethod.Post, {notes:note}, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const updateInteractionsStatus = (id:string, mainStatus: string, subStatus:string): Promise<any> => {
  return httpClient(`/interactions/interaction/${id}/status`, HttpMethod.Put, {mainStatus: mainStatus, subStatus: subStatus}, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const updateInteractionsResolution = (id:string, resolution: string): Promise<any> => {
  return httpClient(`/interactions/interaction/${id}/resolution`, HttpMethod.Put, {resolutionType: resolution}, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const getCallStatistics = ({from, to}: any): Promise<any> => {
  return httpClient(`/interactions/interaction/call-statistics?fromDateTimeInISO=${from}&toDateTimeInISO=${to}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const activeInteractions = (): Promise<any> => {
  return httpClient('/interactions/interaction/active-interactions', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const openInteractions = (): Promise<any> => {
  return httpClient('/interactions/interaction/open-interactions', HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const acceptInteraction = (interactionId: string): Promise<any> => {
  return httpClient(`/interactions/interaction/${interactionId}/answer`, HttpMethod.Post, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const callOutInteraction = (data:any): Promise<any> => {
  return httpClient('/interactions/callout', HttpMethod.Post, data, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const receiveOutgoingInteraction = (interactionId: string): Promise<any> => {
  return httpClient(`/interactions/interaction/${interactionId}/receive`, HttpMethod.Post, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const pstnCallHoldAndResume = (callId: string, action: string): Promise<any> => {
  return httpClient(`/interactions/interaction/voice/moderate/${callId}/${action}`, HttpMethod.Post, null, CONSTANTS.SERVICE_TYPES.INTERACTIONS);
};

export const refreshSession = (data: IRefreshTokenRequest): Promise<any> =>{
  return httpClient('/interactions/refresh-session', HttpMethod.Post, data, CONSTANTS.SERVICE_TYPES.INTERACTIONS);};