export const Colors = {
  LSNavy: '#252161',
  LSDarkTeal: '#007FA1',
  LSTeal: '#0DA9B9',
  LSTealAccent: '#9DD9E0',
  LSTealBackground: '#E7F6F8',
  LSWhite: '#FFFFFF',
  LSBlack: '#000000',
  LSSkyLight: '#E7F7F8',
  RCInfo: '#1F99CF',
  RCSuccess: '#02A57D',
  RCWarning: '#FBB03F',
  RCAlert: '#DC7835',
  RCCritical: '#DC3545',
  RCInfoBG: '#9DD9E0',
  RCSuccessBG: '#D0F3C6',
  RCWarningBG: '#FEEBCF',
  RCAlertBG: '#F6DDCC',
  RCCriticalBG: '#FFCBCE',
  NeutralBlack: '#000000',
  NeutralGrey1: '#2C2E2E',
  NeutralGrey2: '#626566',
  NeutralGrey3: '#ABB1B2',
  NeutralGrey4: '#D6D8D9',
  NeutralGrey5: '#F5F7F7',
  Transparent: 'tranparent',
  LavaRed:'#DD1F1F',
  None: 'none',
  RCGreyRGB: 'rgba(255, 255, 255, 0.16)'
};
