export const CONSTANTS = {
  'ROLES': {
    OPERATOR: 'operator',
    SUBSCRIBER: 'subscriber'
  },
  'GENERAL_NOTES': {
    'LABEL': {
      DATA_GENERAL_NOTES: ''
    },
    'TYPE_CHANGE': {
      DATA_GENERAL_NOTES: 'dataGeneralNotes'
    }
  },
  'MEDICAL_INFORMATION': {
    'LABEL': {
      DOCTOR_NAME: 'Doctor:',
      DOCTORS_NO: 'Doctors No.:',
      BLOOD_TYPE: 'Blood Type:',
      ALLERGIES: 'Allergies:',
      CRITICAL_MEDICATIONS: 'Critical Medications:',
      OTHER_INFORMATION: 'Other Information:'
    },
    'TYPE_CHANGE': {
      DOCTOR_NAME: 'doctor',
      DOCTORS_NO: 'doctorMobile',
      BLOOD_TYPE: 'bloodType',
      ALLERGIES: 'allergies:',
      CRITICAL_MEDICATIONS: 'criticalMedications:',
      OTHER_INFORMATION: 'otherInformation'
    },
  },
  'CALLER_OVERVIEW': {
    'LABEL': {
      SEX: 'Sex:',
      WEIGHT: 'Weight (kg):',
      HEIGHT: 'Height (cm):',
      HAIR_COLOR: 'Hair color:',
      LIVING_STATUS: 'Living status:',
      ETHNICITY: 'Ethnicity:'
    },
    'TYPE_CHANGE': {
      SEX: 'sex',
      WEIGHT: 'weight',
      HEIGHT: 'height',
      HAIR_COLOR: 'hairColour::',
      LIVING_STATUS: 'livingStatus:',
      ETHNICITY: 'ethnicity:'
    },
  },
  'CALLER_DETAILS': {
    'LABEL': {
      MOBILE: 'Mobile:',
      HOME: 'Home:',
      WORK: 'Work:',
      EMAIL: 'Email:',
      ADDRESS: 'Address:',
    },
    'TYPE_CHANGE': {
      MOBILE: 'mobile',
      HOME: 'home',
      WORK: 'work',
      EMAIL: 'email',
      ADDRESS: 'address',
    },
  },
  'OPERATOR_STATUS': {
    TITLE: 'Operator status',
    'CALL_STATUS': {
      TITLE: 'Call status',
      WAITING: 'Waiting',
      ACTIVE: 'Active',
      AVG_RING: 'Avg. Ring',
      TOTAL: 'Total',
      LOAD: 'Load'
    },
    'OPERATOR': {
      TITLE: 'Operators',
      LOGGED_IN: 'Logged In',
      AVAILABLE: 'Available',
      UN_AVAILABLE: 'Unavailable',
      LUNCH_BREAK: 'Lunch Break',
      ON_CALL: 'On Call'
    },
  },
  'DOCK_MENU': {
    CALL_STATISTIC: 'Call Statistics',
    ACTIVE_AGENTs: 'Active Agents',
    CCTV_HISTORY: 'CCTV History',
    OPERATOR_HISTORY: 'Operator History',
  },
  'CALL_STATE': {
    INCOMING: 'incoming',
    ACTIVE: 'active',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
    FAILED: 'failed',
    OUTGOING: 'outgoing',
    ON_HOLD: 'onhold',
    HUNG_UP: 'hungup',
    NOT_SYNCED: 'notsynced'
  },

  'CALLERS': {
    DETAILS: 'Details',
    HISTORY_NOTE: 'History & Notes',
    CONTACTS: 'Contacts',
    MEDICAL_INFORMATION: 'Medical information',
    CALLER_DETAILS: 'Caller details',
    OVERVIEW: 'Overview',
    GENERAL_NOTES: 'General notes'
  },
  'CALL_TYPES': {
    MESSAGE: 'CHAT',
    VOICE_CALL: 'PSTN',
    VIDEO_CALL: 'VIDEO_CALL',
    PSTN_CALL: 'PSTN',
    CCTV_CALL: 'CCTV',
    WATCH_ME: 'WATCH_ME',
    NO_VIDEO: 'novideo',
    MISSED_CALL: 'MISSED_CALL',
    WATCH_ME_MISSED_CHECKIN: 'WATCH_ME_MISSED_CHECKIN',
    SOS_VIDEO_CALL: 'SOS_VIDEO_CALL',
    RECORDED_VIDEO_CALL:'RECORDED_VIDEO_CALL',
    PSTN_OUT: 'PSTN_OUT',
    RECORDED_PSTN_CALL:'RECORDED_PSTN_CALL',
    OOO:'000',
  },
  'CALL_STATUS': {
    NONE: 'none',
    OPEN: 'open',
    CLOSED: 'closed',
    RE_OPEN: 're-open',
    BUSY: 'busy'
  },
  'AUTHENTICATION': {
    SUBSCRIBER_ACCESS_TOKEN_KEY: 'subscriber-api-access-token',
    INTERACTION_ACCESS_TOKEN_KEY: 'interaction-api-access-token'
  },
  'CHAT_MESSAGE_TYPES':{
    TEXT: 'text',
    FILE: 'file',
    CHAT_END: 'chat-end'
  },
  'CHAT_OPTIONS': {
    'TEXT_HOVER': {
      HI: 'Hi CallerFirstName, this is OperatorFirstName from Lifestream. How can I help you?”',
      HOLD: 'I’m putting you on hold for a moment.',
      LOCATION: 'Can you confirm your location is “CurrentLocation”?',
      AMBO: 'Please stay where you are. I’ll put you on hold while I get an ambulance for you.',
      POLICE: 'Please stay where you are. I’ll put you on hold while I get the Police for you.',
      ANYTHING_ELSE: 'Can I help you with anything else, or can we end the chat now?',
      CHAT_END: 'Chat session has ended'
    },
    'TYPE_CLASS': {
      HI: 'active-hand-waving',
      HOLD: 'active-pause',
      LOCATION: 'active-location',
      AMBO: 'active-first-aid',
      POLICE: 'active-siren',
      ANYTHING_ELSE: 'active-anything-else',
      CHAT_END: 'active-chat-end'
    }
  },
  'SERVICE_TYPES':{
    SUBSCRIBERS: 'subscribers',
    INTERACTIONS: 'interactions',
    LOCATIONS: 'locations',
    NOTIFICATIONS: 'notifications'
  },
  'INTERACTION_STATUS':{
    Open: 'OPEN',
    Closed: 'CLOSED'
  },
  'INTERACTION_SUBSTATUS':{
    Landed: 'LANDED',
    InProgress: 'IN_PROGRESS',
    OnHold: 'ON-HOLD',
    Ended: 'ENDED',
    Missed: 'MISSED',
    Unanswered: 'UNANSWERED'
  },
  'RESOLUTION':{
    Ambulance: 'AMBULANCE',
    CallEmergencyNumber: 'EMERGENCY_NO',
    MedicalAdvice: 'MEDICAL_ADVICE',
    CarerReferral: 'CARER_REF',
    MedicalFollowUp: 'MED_FOLLOWUP',
    GeneralAdvice: 'GENERAL_ADVICE',
    FollowUp: 'FOLLOW_UP',
    AccidentalCall: 'ACCIDENTAL_CALL',
    EmergencyContactCall: 'EMERGENCYCONTACTCALL',
    WatchMe: 'WATCH_ME',
    NuisanceCall: 'NUISANCE_CALL',
    TestCall: 'TEST_CALL',
    Racism: 'RACISM',
  },
  'RC_OPERATOR_Status':{
    Available: 'Available',
    Offline: 'Offline',
    ACW:'After Call Work',
    OnInteraction: 'On-Interaction'
  },
  'MAP_DEFAULT_LOCATION': {
    Latitude: -26.336095559578308,
    Longitude: 134.26728788594764
  },
  'MAP_ZOOMS': {
    Default: 4,
    Locate: 16,
    QuickZoom: 8,
  },
  'CALL_OUT_CHANNELS':{
    ChatOut: 'CHAT_OUT',
    SOSVideoCallOut: 'SOS_VIDEO_CALL_OUT',
    PSTNOut: 'PSTN_OUT'
  },
  'MAP_STYLES_CONFIG': {
    'POLYLINE': {
      'STROKE_COLOR': '#00b5ff',
      'STROKE_LIGHT_COLOR': '#a8daff',
      'STROKE_OPACITY': 0.5,
      'STROKE_WEIGHT': 4,
      'Z_INDEX': 2,
      'STROKE_PAUSED_STATE': 'paused',
      'STROKE_BUFFERING_STATE': 'buffering'
    },
    'POLYLINE_REPLAY': {
      'STROKE_COLOR': '#2670c0',
      'STROKE_OPACITY': 1,
      'STROKE_WEIGHT': 3,
      'Z_INDEX': 5,
    },
    'CIRCLE': {
      PATH: 'M 0, 0 m -1, 0 a 1,1 0 1,0 2,0 a 1,1 0 1,0 -2,0',
      STROKE_COLOR: '#2670c0',
      STROKE_WEIGHT: 4,
    }
  }
};
