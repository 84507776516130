export interface SearchBarProps {
  onSearchPress: (searchString: string, type: SearchType) => void;
  isFetching?: boolean;
  isOpenSearchResult?: boolean;
  onKeyPress?: (event: any) => any;
}

export enum SearchType {
  user = 'user',
  phone = 'phone'
}
