import React, {useEffect, useState} from 'react';
import './AcwPopUp.styles.css';
import {Colors} from '../../configs/colors';
import {Images} from '../../configs/images';
import {Text} from '../../components/atoms/Text';
import {AcwPopUpProps} from '.';
import {DropdownSelectIncident} from '../../components/molecules/DropdownSelect';
import {Button, ButtonVariant} from '../../components/atoms/Button';
import {CONSTANTS} from '../../constants/constants';
import {incidentOptionListForACW} from '../../constants/appConstantData';
import {Image} from '../../components/atoms/Image';

export const AcwPopUp: React.FC<AcwPopUpProps> = ({onSubmit}) => {
  const [selectedIncident, setSelectedIncident] = useState<string>('');
  const [disableSubmitBtn, setDisableSubmitBtn] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [isResetOptionChecked, setIsResetOptionChecked] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');

  useEffect(()=>{
    if ((isOpen || isClose) && selectedIncident) {
      setDisableSubmitBtn(false);
    } else {
      setDisableSubmitBtn(true);
    }
  }, [isOpen, isClose, selectedIncident]);

  const handleIncidentOnSelect = (value: string) => {
    setSelectedIncident(value);
  };

  const onClickOpen = () => {
    setStatus(CONSTANTS.INTERACTION_STATUS.Open);
    setIsOpen(true);
    setDisableSubmitBtn(false);
    setIsClose(false);
  };

  const onClickClose = () => {
    setStatus(CONSTANTS.INTERACTION_STATUS.Closed);
    setIsClose(true);
    setIsOpen(false);
    setDisableSubmitBtn(false);
  };

  const handleSubmit = () => {
    onSubmit(status, selectedIncident);
    setDisableSubmitBtn(true);
    setIsResetOptionChecked(!isResetOptionChecked);
  };

  return (
    <div className="acw-popup">
      <div className="acw-content">
        <div className="acw-content-left">
          <Image height="48px" width="48px" url={Images.EndVideo} />
        </div>
        <div className="acw-content-right">
          <div className="acw-content-title">
            <Text fontWeight="500" fontSize={18} color="#101828" margin="0px 0px 8px 0px">
              Update incident type and call status
            </Text>
            <Text fontWeight="400" fontSize={14} color="#667085">
              These updates will be published to the caller log.
            </Text>
          </div>
          <div className="acw-content-incident-type">
            <Text
              fontWeight="500"
              fontSize={15}
              color={Colors.NeutralGrey1}
              margin="0px 0px 8px 0px"
            >
              Incident (required):
            </Text>
            <DropdownSelectIncident
              onSelected={handleIncidentOnSelect}
              padding="5px 10px"
              backgroundColor="white"
              height="48px"
              optionsList={incidentOptionListForACW}
              selectImage
              borderColor={Colors.LSTealAccent}
              textColor={Colors.NeutralBlack}
              isResetOptionChecked={isResetOptionChecked}
            />
          </div>
          <div className="acw-content-call-status">
            <Text
              fontWeight="500"
              fontSize={15}
              color={Colors.NeutralGrey1}
              margin="0px 0px 8px 0px"
            >
              Call status (required):
            </Text>
            <div className="group-btn-status">
              <div className={`${isOpen ? 'active-border' : ''}`}>
                <Button
                  variant={ButtonVariant.ButtonImage}
                  text={'Open'}
                  textColor={Colors.RCSuccess}
                  color={Colors.LSWhite}
                  height="48px"
                  width="145px"
                  fontSize={16}
                  fontWeight={500}
                  border={'1px solid #D6D8D9'}
                  borderRadius="3px"
                  boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                  onClick={onClickOpen}
                >
                  <div className={`empty-circle ${!isOpen ? 'open' : 'open-active'}`}>
                    <div></div>
                  </div>
                </Button>
              </div>
              <div className={`${isClose ? 'active-border' : ''}`}>
                <Button
                  variant={ButtonVariant.ButtonImage}
                  text={'Close'}
                  textColor={Colors.RCCritical}
                  color={Colors.LSWhite}
                  height="48px"
                  width="145px"
                  fontSize={16}
                  fontWeight={500}
                  border={'1px solid #D6D8D9'}
                  borderRadius="3px"
                  boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                  onClick={onClickClose}
                >
                  <div className={`empty-circle ${!isClose ? 'close' : 'close-active'}`}>
                    <div></div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <div className="acw-content-submit-btn">
            <Button
              variant={ButtonVariant.Round}
              text={'Update and save'}
              textColor={Colors.LSWhite}
              color={Colors.LSDarkTeal}
              disabled={disableSubmitBtn}
              height="48px"
              fontSize={16}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
