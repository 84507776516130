import {MapLocation} from './../components/atoms/Map/Map.props';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {LastKnowLocationData} from '../pages/Caller';
import {getLastKnownLocation, getUserLocations} from '../api/locations';

export interface LastKnowLocationObjectState {
  data?: LastKnowLocationData[];
  isLoading: boolean;
  error?: string;
}

const initialState: LastKnowLocationObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const lastKnownLocationBySubscriberIds = createAsyncThunk('lastKnownLocation/subscriberIds', async (parma: { subscriberIds: string, position1: MapLocation | undefined }) => {
  const service = new google.maps.DistanceMatrixService();
  const response = await getLastKnownLocation(parma.subscriberIds);
  const users = await getUserLocations(parma.subscriberIds);
  const usersLocation = users.lifestreamResponse.data.locationsIds;

  const data = response.lifestreamResponse.data;
  if (data) {
    const newRes = await Promise.all(data.map(async (locationData: any) => {
      let position2 = undefined;
      if (locationData.coordinates) {
        position2 = {
          lat: locationData.coordinates.coordinates[1],
          lng: locationData.coordinates.coordinates[0]
        };
      }
      try {
        if (position2 && parma.position1) {
          const request = {
            origins: [parma.position1],
            destinations: [position2],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          };
          const res = await service.getDistanceMatrix(request);
          if (res && res.rows) {
            locationData.distance = res.rows[0].elements[0].distance.text;
            locationData.distanceValue = res.rows[0].elements[0].distance.value;
            locationData.currentLocation = res.destinationAddresses[0];
          }
          if (usersLocation) {
            usersLocation.map((user: any) => {
              if (user.subscriberId === locationData.subscriberId) {
                locationData.roamAIUserId = user.locationUserId;
              }
            });
          }
        }
      } catch (error) {
        locationData.distance = 'N/A';
        locationData.distanceValue = 0;
        locationData.currentLocation = 'N/A';
        console.log(error);
      }
      return locationData;
    }));
    return newRes ? newRes.map((locationData: any) => {
      return {
        subscriberId: locationData.subscriberId,
        roamAIUserId: locationData.roamAIUserId,
        latitude: locationData.coordinates ? locationData.coordinates.coordinates[1] : undefined,
        longitude: locationData.coordinates ? locationData.coordinates.coordinates[0] : undefined,
        speed: locationData.speed,
        altitude: locationData.altitude,
        activity: locationData.activity,
        recordedAt: locationData.recorded_at,
        gpsStatus: locationData.gps_status,
        horizontalAccuracy: locationData.horizontal_accuracy,
        verticalAccuracy: locationData.vertical_accuracy,
        createdAt: locationData.created_at,
        trackingMode: locationData.tracking_mode,
        distance: locationData.distance,
        distanceValue: locationData.distanceValue,
        currentLocation: locationData.currentLocation
      };
    }) : [];
  }
});

const lastKnownLocationSlice = createSlice({
  name: 'lastKnownLocation',
  initialState,
  reducers: {
    setLastKnownLocationData: (state, action: PayloadAction<LastKnowLocationData[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    setLastKnownLocationDataClear: (state) => {
      state.data = undefined;
      state.isLoading = false;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(lastKnownLocationBySubscriberIds.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(lastKnownLocationBySubscriberIds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(lastKnownLocationBySubscriberIds.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setLastKnownLocationData,
  setLastKnownLocationDataClear
} = lastKnownLocationSlice.actions;

export const lastKnownLocationDataSelector = (state: RootState) => state.lastKnownLocation;

export default lastKnownLocationSlice.reducer;
