import {UserLocationState} from './../../redux/userLocationSlice';
import {NoteItem} from '../../components/layouts/NotesPanel';
import {UserDetails} from '../../components/organisms/CallsTable/CallsTable.props';
import {DataMedicalInformation} from '../../components/organisms/MedicalInformation';
import {UserBasicdata} from '../../components/organisms/UserInfoPanel';
import {CallerLocationItem} from '../../components/molecules/CallerLocation';
import {MapLocation} from '../../components/atoms/Map';

export interface CallerProps {
  contacts?: Array<DataContacts>;
  userDetails?: UserDetails;
}

export interface UserDetailsProps {
  userDetails: UserDetails;
  dynamicDetails:any;
  mapFullScreenState: boolean;
  setMapFullScreen:(state: boolean) => void;
  userInfo: any;
  userLocation?: UserLocationState;
  callerLocation?: CallerLocationItem;
  isConnected?: boolean;
  subscriberId?: string;
  timeOfInteraction?: TimeOfIncident;
}

export interface TelephoneNumber {
  number: string;
  countryCode: string;
}

export interface DataContacts {
  id?: string;
  subScriberID: string;
  contactName: string;
  group: string;
  relationship: string;
  contact: string;
  currentLocation: string;
  lastFix: string;
  distance: string;
  distanceValue: number;
  url?: string;
  type: string;
  home?: string;
  work?: string;
  mobile?: TelephoneNumber;
  contactSubscriberId?: string;
  firstName?: string;
  location?: MapLocation;
  subscriberLocation?: MapLocation;
  times?: string;
  showContactMarker?: boolean;
  roamAIUserId?:string;
  activity?: string;
  homeAddress?: string;
}

export interface ContactProps {
  mapFullScreenState: boolean;
  setMapFullScreen:(state: boolean) => void;
  userLocation?: UserLocationState;
  callerLocation?: CallerLocationItem;
  isConnected?: boolean;
  userInfo: UserBasicdata;
  subscriberId?: string;
  timeOfInteraction?: TimeOfIncident;
  onClickMobile: (mobileNumber:string, isWatchme?:boolean, isNewIncident?:boolean, calleeSubscriberId?: string) => void;
  onClickChat?: (calleeSubscriberId?: string) => void;
  onVideoClick?:(isNewIncident?:boolean, calleeSubscriberId?: string) => void;
  isInteractionButtonDisabled?: boolean;
}

export enum SortColumn {
  ContactName = 'contactname',
  Group = 'group',
  Relationship = 'relationship',
  LastFix = 'lastfix',
  Distance = 'distance'
}

export interface HistoryAndNotesProps {
  setTabName: (value: string) => void;
  onClickRowShowNotes: (data: any, showNotes: boolean) => void;
  showNotes?: boolean;
  subscriberId?:string;
  interactionHistory: IncidentInteractionHistory[];
  selectedInteractionId?:string;
  onDoubleClickRow?: (value: InteractionHistory) => void;
}

export interface IncidentInteractionHistory {
  incidentId: string;
  incidentStatus:string;
  companyId:string;
  createdDate:Date;
  interactions: InteractionHistory[];
}

export interface InteractionHistory {
  interactionId: string;
  incidentId: string;
  subscriberId: string;
  service?: string;
  type:string;
  contact:string;
  location:string;
  received:string;
  status:string;
  subStatus:string;
  callRef:string;
  duration?:string;
  interactionStartDateTime:Date;
  interactionEndDateTime?:Date;
  notes: NoteItem[];
  externalSessionData?:string;
  resolution?:string;
  callOp?: string;
  interactionsForIncident?: boolean;
  interactions: InteractionHistory[];
}

export interface AcwPopUpProps {
  onClickOpen: (value:string) => void;
  onClickClose: (value:string) => void;
}

export interface LastKnowLocationData {
  subscriberId: string;
  roamAIUserId?: string;
  latitude: number;
  longitude: number;
  speed?:number;
  altitude?:number;
  activity?:string;
  recordedAt?:string;
  gpsStatus?:string;
  horizontalAccuracy?:number;
  verticalAccuracy?:number;
  createdAt?:string;
  trackingMode?:string;
  distance?: string;
  distanceValue?: number;
  currentLocation?: string;
}

export interface TimeOfIncident { startTime: Date; endTime: Date | undefined }