import React, {useRef, useState, useEffect, useCallback} from 'react';
import 'amazon-connect-streams';
import 'amazon-connect-taskjs';
import appConfig from '../../configs/appConfig.json';
import {CCPProps} from './CCP.props';
import './CCP.styles.css';
import {useAppDispatch} from '../../redux/hooks';
import {
  setUserTaskAgent,
  setUserTaskIncomming,
  setUserTaskIsConnected,
  setUserCloseContact,
  setUserTaskIsEnded,
  setUserTaskClear,
  setUserTaskEnd,
} from '../../redux/userTaskSlice';
import {setVideoCallEnd, setVideoCallSession} from '../../redux/videoCallSlice';
import {interactionAnswerByInteractionId} from '../../redux/interactionDetailsSlice';
import {
  setUserLocationDataClear,
  userLocationFetch,
} from '../../redux/userLocationSlice';
import {
  parseSubscriberIdAttribute,
  parseInteractionIdAttribute,
  parseTokenAttribute,
  parseTokenData,
  parseInteractionTypeAttribute,
} from '../../utils/parsers';
import {CONSTANTS} from '../../constants/constants';

export const CCP: React.FC<CCPProps> = ({
  loginPopup = true,
  loginPopupAutoClose = true,
  allowFramedSoftphone = true,
  debug = false,
  children,
}) => {
  const dispatch = useAppDispatch();
  const containerDiv = useRef(null);
  const [completed, setCompleted] = useState(false);

  // Handling all of our errors here by alerting them
  const handleError = (error?: OT.OTError | undefined) => {
    if (error) {
      console.error('Error connecting: ', error.name, error.message);
      dispatch(setUserTaskEnd());
      dispatch(setVideoCallEnd());
    } else {
      console.log('Connected to the session.');
    }
  };

  const initCommonTokBoxSession = useCallback((token: string) => {
    const {apiKey, sessionId} = parseTokenData(token);
    const session = OT.initSession(apiKey, sessionId);
    session.connect(token, handleError);
    dispatch(setVideoCallSession({session, token}));
  }, []);

  useEffect(() => {
    if (containerDiv.current) {
      connect.core.initCCP(containerDiv.current, {
        ccpUrl: appConfig.AwsConnect.ccpUrl,
        loginUrl: appConfig.AwsConnect.loginUrl,
        loginPopup,
        loginPopupAutoClose,
        loginOptions: {
          autoClose: loginPopupAutoClose,
          height: 600,
          width: 400,
          top: 0,
          left: 0,
        },
        region: appConfig.AwsConnect.region,
        softphone: {
          allowFramedSoftphone,
          disableRingtone: false,
        },
      });

      connect.core.onInitialized(() => {
        setCompleted(true);
      });

      connect.agent((agent) => {
        dispatch(
          setUserTaskAgent({
            agent,
          })
        );
      });

      connect.contact((contact) => {
        console.log('CCP >> Connect Init...');

        if (contact.getType() === 'task') {
          contact.onConnecting((obj) => {
            console.log('CCP >> Task Connecting...');
            dispatch(
              setUserTaskIncomming({
                contact,
                attributes: obj.getAttributes(),
              })
            );
            const subscriberId = parseSubscriberIdAttribute(
              obj.getAttributes()
            );
            dispatch(setUserLocationDataClear());
            dispatch(userLocationFetch(subscriberId));
          });

          contact.onConnected((obj) => {
            console.log('CCP >> Task Connected...');
            const interactionType =  parseInteractionTypeAttribute(obj.getAttributes());
            const interactionId = parseInteractionIdAttribute(
              obj.getAttributes()
            );

            if(interactionType === CONSTANTS.CALL_TYPES.MESSAGE){
              dispatch(interactionAnswerByInteractionId(interactionId)).then(() => {
                dispatch(
                  setUserTaskIsConnected({
                    contact,
                    attributes: obj.getAttributes(),
                  })
                );
              });
            }else{
              dispatch(
                setUserTaskIsConnected({
                  contact,
                  attributes: obj.getAttributes(),
                })
              );
              dispatch(interactionAnswerByInteractionId(interactionId));
            }

            const subscriberId = parseSubscriberIdAttribute(
              obj.getAttributes()
            );
            console.log('subscriberId', subscriberId);
            dispatch(setUserLocationDataClear());
            dispatch(userLocationFetch(subscriberId));
            const token = parseTokenAttribute(obj.getAttributes());
            if (token) {
              initCommonTokBoxSession(token);
            }
          });

          contact.onEnded(() => {
            console.log('CCP >> Task Ended...');
            dispatch(setUserTaskIsEnded());
          });

          contact.onDestroy(() => {
            console.log('CCP >> Task Destroy...');
            dispatch(setUserTaskClear());
            dispatch(setUserLocationDataClear());
          });

          contact.onACW(() => {
            console.log('CCP >> Task ACW...');
          });

          contact.onMissed(() => {
            console.log('CCP >> Task Missed...');
            dispatch(setUserCloseContact());
          });

          contact.onError((obj) => {
            console.log('CCP >> Task Error' + JSON.stringify(obj));
          });
        }
      });
    }
  }, []);

  return (
    <>
      <div
        className="ccp-container-style"
        style={{display: debug ? 'unset' : 'none'}}
        ref={containerDiv}
      />
      {completed && children}
    </>
  );
};
