import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {getSearchByKey} from '../api/subscribers';
import {getDateTimeDifference} from '../utils/dateTimeHelper';

export interface SubscriberSearchObject {
  name: string;
  age: number;
  sex: string;
  contact: string;
  service: string;
  homeAddress: string;
  subscriberId: string;
  isDeleted: boolean;
}

export interface SubscriberSearchObjectState {
  data?: Array<SubscriberSearchObject>;
  selectedObjectId?: string;
  isLoading: boolean;
  error?: string;
}

const initialState: SubscriberSearchObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const subscriberSearchByKey = createAsyncThunk('subscriberSearch/byKey', async (searchText: string) => {
  const response = await getSearchByKey(searchText);
  const data = response.lifestreamResponse.data.data;
  return data ? data.map((item: any) => {
    return {
      name: `${item.account.firstName} ${item.account.lastName}`,
      age: getDateTimeDifference(item.account.dateOfBirth),
      contact: `${item.account.mobileNumber.countryCode}${item.account.mobileNumber.number}`,
      homeAddress: item.account.homeAddress,
      sex: item.additionalDetails?.gender,
      subscriberId: item.account.subscriberId,
      isDeleted: item.account.isDeleted,
    };
  }) : [];
});

const subscriberSearchSlice = createSlice({
  name: 'subscriberSearch',
  initialState,
  reducers: {
    setSubscriberSearchData: (state, action: PayloadAction<Array<SubscriberSearchObject> | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as Array<SubscriberSearchObject>;
      state.selectedObjectId = undefined;
      state.error = undefined;
    },
    setSubscriberSelectedObjectId: (state, action: PayloadAction<string>) => {
      state.selectedObjectId = action.payload;
    },
    setSubscriberSearchClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.selectedObjectId = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscriberSearchByKey.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(subscriberSearchByKey.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as Array<SubscriberSearchObject>;
      state.error = undefined;
    });

    builder.addCase(subscriberSearchByKey.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {setSubscriberSearchData, setSubscriberSelectedObjectId, setSubscriberSearchClear} = subscriberSearchSlice.actions;

export const subscriberSearchSelector = (state: RootState) => state.subscriberSearch;

export default subscriberSearchSlice.reducer;
