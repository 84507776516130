import {Images} from '../configs/images';
import {CONSTANTS} from './constants';

export const statusOptionList = [
  {
    name: 'Open',
    urlImgOption: Images.ImgGreenDot,
    urlImgActiveOption: Images.ImgGreenDot,
    widthImgOption: '20px',
    value: 'OPEN',
  },
  {
    name: 'Close',
    urlImgOption: Images.ImgRedDot,
    urlImgActiveOption: Images.ImgRedDot,
    widthImgOption: '20px',
    value: 'CLOSED',
  }
];

export const incidentOptionList = [ 
  {
    name: 'Ambulance',
    urlImgOption: Images.ImgSiren,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.Ambulance,
    check: false,
  },
  {
    name: '000 Call',
    urlImgOption: Images.Warning,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.CallEmergencyNumber,
    check: false,
  },
  {
    name: 'Medical advice',
    urlImgOption: Images.ImgFirstAid,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.MedicalAdvice,
    check: false,
  },
  {
    name: 'Carer referral',
    urlImgOption: Images.HandShake,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.CarerReferral,
    check: false,
  },
  {
    name: 'Medical follow up',
    urlImgOption: Images.PhoneOutgoing,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.MedicalFollowUp,
    check: false,
  },
  {
    name: 'General advice',
    urlImgOption: Images.ChatCentered,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.GeneralAdvice,
    check: false,
  },
  {
    name: 'Follow-up',
    urlImgOption: Images.SmileyWink,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.FollowUp,
    check: false,
  },
  {
    name: 'Accidental Call',
    urlImgOption: Images.SmileyNervous,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.AccidentalCall,
    check: false,
  },
  {
    name: 'Emergency Contact Call',
    urlImgOption: Images.PhoneHistory,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.EmergencyContactCall,
    check: false,
  },
  {
    name: 'WatchMe',
    urlImgOption: Images.ImgAlarm,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.WatchMe,
    check: false,
  },
  {
    name: 'Nuisance call',
    urlImgOption: Images.SmileySad,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.NuisanceCall,
    check: false,
  },
  {
    name: 'Test call',
    urlImgOption: Images.ImgUserTalk,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.TestCall,
    check: false,
  },
  {
    name: 'Racism',
    urlImgOption: Images.ImgProhibit,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.Racism,
    check: false,
  }
];

export const incidentOptionListForACW = [
  {
    name: 'Select an incident',
    urlImgOption: Images.Question,
    widthImgOption: '20px',
    value: '',
    check: false,
  },
  {
    name: 'Ambulance',
    urlImgOption: Images.ImgSiren,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.Ambulance,
    check: false,
  },
  {
    name: '000 Call',
    urlImgOption: Images.Warning,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.CallEmergencyNumber,
    check: false,
  },
  {
    name: 'Medical advice',
    urlImgOption: Images.ImgFirstAid,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.MedicalAdvice,
    check: false,
  },
  {
    name: 'Carer referral',
    urlImgOption: Images.HandShake,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.CarerReferral,
    check: false,
  },
  {
    name: 'Medical follow up',
    urlImgOption: Images.PhoneOutgoing,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.MedicalFollowUp,
    check: false,
  },
  {
    name: 'General advice',
    urlImgOption: Images.ChatCentered,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.GeneralAdvice,
    check: false,
  },
  {
    name: 'Follow-up',
    urlImgOption: Images.SmileyWink,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.FollowUp,
    check: false,
  },
  {
    name: 'Accidental Call',
    urlImgOption: Images.SmileyNervous,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.AccidentalCall,
    check: false,
  },
  {
    name: 'Emergency Contact Call',
    urlImgOption: Images.PhoneHistory,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.EmergencyContactCall,
    check: false,
  },
  {
    name: 'WatchMe',
    urlImgOption: Images.ImgAlarm,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.WatchMe,
    check: false,
  },
  {
    name: 'Nuisance call',
    urlImgOption: Images.SmileySad,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.NuisanceCall,
    check: false,
  },
  {
    name: 'Test call',
    urlImgOption: Images.ImgUserTalk,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.TestCall,
    check: false,
  },
  {
    name: 'Racism',
    urlImgOption: Images.ImgProhibit,
    widthImgOption: '20px',
    value: CONSTANTS.RESOLUTION.Racism,
    check: false,
  },
];