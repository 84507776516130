import 'amazon-connect-streams';
import 'amazon-connect-taskjs';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {CONSTANTS} from '../constants/constants';

export interface UserTaskObject {
  agent?: connect.Agent;
  contact?: connect.Contact;
  attributes?: connect.AttributeDictionary;
}

export interface OutboundTaskAttributes {
  subscriberId: string;
  interactionId: string;
  channel?: string;
  incidentId?: string;
  firstName?: string;
  lastName?: string;
  homeAddress?: string;
  conversationId?: string;
  nexmoToken?: string;
  interactionType: string;
  mobileNumber?: string;
  age?: string;
}

export interface UserTaskState {
  time?: Date;
  agent?: connect.Agent;
  contact?: connect.Contact;
  attributes?: connect.AttributeDictionary;
  isIncomming: boolean;
  isConnected: boolean;
  isMissed: boolean;
  isEnded: boolean;
  initializeCallOnEnd : boolean;
  inbound: boolean;
}

const initialState: UserTaskState = {
  time: undefined,
  agent: undefined,
  contact: undefined,
  attributes: undefined,
  isIncomming: false,
  isConnected: false,
  isMissed: false,
  isEnded: false,
  initializeCallOnEnd: false,
  inbound: false
};

const userTaskSlice = createSlice({
  name: 'userTask',
  initialState,
  reducers: {
    setUserTaskAgent: (state, action: PayloadAction<UserTaskObject>) => {
      const currentAgent = action.payload.agent;
      state.agent = currentAgent;
      const agentStates = currentAgent?.getAgentStates();
      const availableState = agentStates?.find((agentState) => agentState.name === CONSTANTS.RC_OPERATOR_Status.Available);
      availableState &&
      state.agent?.setState(availableState, {
        success: () => {
          console.log(`Agent set to ${action.payload}`);
        },
        failure: (error) => {
          console.log(`Agent set to ${action.payload} via streams failed`, error);
        },
      });
    },
    setUserTaskIsMissed: (state) => {
      state.time = undefined;
      state.isIncomming = false;
      state.isConnected = false;
      state.isMissed = true;
      state.isEnded = false;
    },
    setUserTaskIncomming: (state, action: PayloadAction<UserTaskObject>) => {
      state.time = undefined;
      state.contact = action.payload.contact;
      state.attributes = action.payload.attributes;
      state.isIncomming = true;
      state.isConnected = false;
      state.isMissed = false;
      state.isEnded = false;
      state.inbound = true;
    },
    setUserTaskIsConnected: (state, action: PayloadAction<UserTaskObject>) => {
      state.time = new Date();
      state.contact = action.payload.contact;
      state.attributes = action.payload.attributes;
      state.isIncomming = false;
      state.isConnected = true;
      state.isMissed = false;
      state.isEnded = false;
    },
    setOutboundTaskIsConnected: (state,action: PayloadAction<OutboundTaskAttributes>) => {
      state.time = new Date();
      state.isIncomming = false;
      state.isConnected = true;
      state.isMissed = false;
      state.isEnded = false;
      state.attributes = {
        incidentId: {name: '', value: action.payload.incidentId || ''},
        interactionId: {name: '', value: action.payload.interactionId},
        subscriberId: {name: '', value: action.payload.subscriberId},
        firstName: {name: '', value: action.payload.firstName || ''},
        lastName: {name: '', value: action.payload.lastName || ''},
        homeAddress: {name: '', value: action.payload.homeAddress || ''},
        InteractionType: {name: '', value: action.payload.interactionType},
        conversationId: {name: '', value: action.payload.conversationId || ''},
        nexmoToken: {name: '', value: action.payload.nexmoToken || ''},
        mobileNumber: {name: '', value: action.payload.mobileNumber || ''},
        channel: {name: '', value: action.payload.channel || ''},
        age: {name: '', value: action.payload.age || ''}
      };
      state.initializeCallOnEnd = false;
    },
    setUserTaskIsEnded: (state) => {
      if(state.inbound)
      {
        state.isIncomming = false;
        state.isConnected = false;
        state.isMissed = false;
        state.isEnded = true;
      }
    },
    setUserTaskClear: (state) => {
      if(state.isEnded){
        state.time = undefined;
        state.contact = undefined;
        state.attributes = undefined;
        state.isIncomming = false;
        state.isConnected = false;
        state.isMissed = false;
        state.isEnded = false;
      }
    },
    setAgentStatus: (state, action: PayloadAction<string>) => {
      const agentStates = state.agent?.getAgentStates();
      const selectedState = agentStates?.find((agentState) => agentState.name === action.payload);
      selectedState &&
      state.agent?.setState(selectedState, {
        success: () => {
          console.log(`Agent set to ${action.payload}`);
        },
        failure: (error) => {
          console.log(`Agent set to ${action.payload} via streams failed`, error);
        },
      });
    },
    setUserTaskAccept: (state) => {
      state.contact?.accept({
        success: () => {
          console.log('Accept success');
        },
        failure: (error) => {
          console.log('Contact accept via streams failed', error);
        },
      });
    },
    setUserTaskReject: (state) => {
      // state.contact become null after reject. That's why we need to make a constant before use it in here.
      // It's a strange error from amazon-connect side. `\_("_")_/`
      const connect = state.contact;
      connect?.reject({
        success: () => {
          connect?.clear({
            success: () => {
              console.log('Close contact success');
            },
            failure: (error) => {
              console.log('Contact close via streams failed', error);
            },
          });
        },
        failure: (error) => {
          console.log('Contact reject via streams failed', error);
        },
      });
    },
    setUserCloseContact: (state) => {
      // state.contact become null after reject. That's why we need to make a constant before use it in here.
      // It's a strange error from amazon-connect side. `\_("_")_/`
      const connect = state.contact;
      connect?.clear({
        success: () => {
          console.log('Close contact success');
        },
        failure: (error) => {
          console.log('Contact close via streams failed', error);
        },
      });
    },
    setUserTaskEnd: (state, action: PayloadAction<boolean|undefined>) => {
      if(state.contact) {
        state.contact.getInitialConnection().destroy({
          success: () => {
            state.contact?.clear({
              success: () => {
                console.log('Close contact success');
              },
              failure: (error) => {
                console.log('Contact close via streams failed', error);
              },
            });
          },
          failure: (error) => {
            console.log('End task via streams failed', error);
          },
        });

      } else {
        state.isIncomming = false;
        state.isConnected = false;
        state.isMissed = false;
        state.isEnded = true;
        state.attributes = undefined;
      }

      if(action.payload){
        state.initializeCallOnEnd = action.payload;
        state.inbound= false;
        state.time = undefined;
        state.contact = undefined;
        state.attributes = undefined;
        state.isIncomming = false;
        state.isConnected = false;
        state.isMissed = false;
        state.isEnded = true;
      }
    },
  },
});

export const {
  setUserTaskAgent,
  setUserTaskIncomming,
  setUserTaskIsConnected,
  setUserTaskIsMissed,
  setUserTaskIsEnded,
  setUserTaskClear,
  setAgentStatus,
  setUserTaskAccept,
  setUserTaskReject,
  setUserCloseContact,
  setUserTaskEnd,
  setOutboundTaskIsConnected,
} = userTaskSlice.actions;

export const userTaskSelector = (state: RootState) => state.userTask;

export default userTaskSlice.reducer;
