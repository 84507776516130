import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {updateInteractionsResolution} from '../api/interactions';

export interface InteractionResolutoinUpdateObjectState {
  data?: any;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionResolutoinUpdateObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const interactionResolutionUpdate = createAsyncThunk('interactionResolutionUpdate/ById', async (interactionResolutionParams: any) => {
  const response = await updateInteractionsResolution(interactionResolutionParams.InteractionId, interactionResolutionParams.Incident);
  return response.lifestreamResponse.data;
});

const interactionResolutionUpdateSlice = createSlice({
  name: 'interactionResolutionUpdate',
  initialState,
  reducers: {
    setInteractionResolutionUpdateData: (state, action: PayloadAction<any | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(interactionResolutionUpdate.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionResolutionUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(interactionResolutionUpdate.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInteractionResolutionUpdateData
} = interactionResolutionUpdateSlice.actions;

export const interactionResolutionUpdateSelector = (state: RootState) => state.interactionResolutionUpdate;

export default interactionResolutionUpdateSlice.reducer;
