import {DataContacts} from '../../pages/Caller';
import {UserLocationState} from '../../redux/userLocationSlice';

export enum CallerVideoVariant {
  ActiveWith1Video,
  ActiveWith2Videos,
  NoVideo,
  HungUp
}

export interface CallerVideoProps {
  variant: CallerVideoVariant,
  address?: string,
  date?: string,
  time?: string,
  isDeviceControlTextVisible?: boolean;
  emergencyContacts?: DataContacts[];
  mobileNumber?: string;
  handleOutgoingCall?: () => void;
  handlePstnCall?: (mobileNumber:string) => void;
  onOutgoingVideoCallAutomaticEnd?: () => void;
  isVideoButtonDisabled?: boolean;
  userLocation?: UserLocationState;
  profileImage?: string;
}
