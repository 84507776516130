import React from 'react';
import {InfoPanelProps} from './InfoPanel.props';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image/Image';
import EditImg from '../../../assets/images/note-edit-button.svg';
import './InfoPanel.styles.css';

export const InfoPanel: React.FC<InfoPanelProps> = ({
  title,
  onEdit,
  width,
  height
}) => {
  return (
    <div className="title-style" style={{width,height}}>
      <Text fontWeight="600">{title}</Text>
      {onEdit && <Image onClick={onEdit} url={EditImg} width="14" backgroundColor="null" />}
    </div>
  );
};
