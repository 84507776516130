import React from 'react';
import {Marker, Polyline} from '@react-google-maps/api';
import {PolylineComponentProps} from '.';
import './Polylines.style.css';
import {CONSTANTS} from '../../../constants/constants';

export const PolylineComponent: React.FC<PolylineComponentProps> = ({path}) => {
  const CircleMarker = {
    path: CONSTANTS.MAP_STYLES_CONFIG.CIRCLE.PATH,
    strokeColor: CONSTANTS.MAP_STYLES_CONFIG.CIRCLE.STROKE_COLOR,
    strokeWeight:  CONSTANTS.MAP_STYLES_CONFIG.CIRCLE.STROKE_WEIGHT,
  };

  const options = {
    strokeColor: CONSTANTS.MAP_STYLES_CONFIG.POLYLINE.STROKE_COLOR,
    strokeOpacity:CONSTANTS.MAP_STYLES_CONFIG.POLYLINE.STROKE_OPACITY,
    strokeWeight: CONSTANTS.MAP_STYLES_CONFIG.POLYLINE.STROKE_WEIGHT,
    zIndex: CONSTANTS.MAP_STYLES_CONFIG.POLYLINE.Z_INDEX,
  };

  return (
    <>
      <Polyline path={path} options={options} />;
      {path.map((item, key) => (
        <Marker position={item} icon={CircleMarker} visible={true} key={key} />
      ))}
    </>
  );
};
