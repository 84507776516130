import {MapLocation} from './../components/atoms/Map/Map.props';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {getSubscriberById} from '../api/subscribers';
import {Images} from '../configs/images';
import {LastKnowLocationData, SortColumn} from '../pages/Caller';
import {UserDetails} from '../components/organisms/CallsTable';
import {DataMedicalInformation} from '../components/organisms/MedicalInformation';
import {formatDate, getDateTimeDifference} from '../utils/dateTimeHelper';

export interface SubscriberContactInfoObject {
  mobile: string;
  home: string;
  work: string;
  video: string;
  chat: string;
}

export interface TelephoneNumber {
  number: string;
  countryCode: string;
}

export interface EmergencyContactObject {
  id: string;
  subScriberID: string;
  contactName: string;
  group: string;
  relationship: string;
  contact: string;
  currentLocation: string;
  lastFix: string;
  distance: string;
  distanceValue: number;
  url: string;
  type: string;
  home: string;
  work: string;
  mobile: TelephoneNumber;
  contactSubscriberId?:string;
  roamAIUserId?:string;
  location?: MapLocation;
  subscriberLocation?: MapLocation;
  times?: string;
  showContactMarker?: boolean;
  activity?: string;
  homeAddress?: string;
}

export interface SubscriberDetailsObject {
  subscriberData: any;
  subscriberContactInfo: any;
  emergencyContactList: Array<EmergencyContactObject>;
  additionalDetails: Array<any>;
  watchmeConfig?: WatchMeConfig;
  isDeleted?: boolean;
}

export interface SubscriberDetailsObjectState {
  data?: SubscriberDetailsObject;
  isLoading: boolean;
  error?: string;
}

export interface WatchMeConfig {
  primaryContactId: string;
  secondaryContactId: string;
  useEmergencyService: boolean;
}

const initialState: SubscriberDetailsObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const subscriberDetailsById = createAsyncThunk('subscriberDetails/ById', async (subscriberId: string) => {
  const response = await getSubscriberById(subscriberId);
  const data = response.lifestreamResponse.data;
  const subscriber = data.subscriber;
  const searchFieldFromAdditionalDetails = (fieldName: string) => {
    const field = subscriber?.additionalDetails?.flatMap((section: { fields: any; }) => section.fields)
      .find((field: { name: string; dataType: string; }) => field.name === fieldName);

    if (field?.dataType === 'P') {
      return field?.value?.countryCode && field?.value?.number ? `${field.value.countryCode}${field.value.number}` : '';
    } else {
      return field?.value || '';
    }
  };
  

  function getImageUrl(obj :any) {
    if (obj && obj.bucketName && obj.version && obj.key) {
      return `https://s3.amazonaws.com/${obj.bucketName}/${obj.key}?versionId=${obj.version}`;
    } else {
      return  Images.NoProfilePictureIcon;
    }
  }
  return {
    subscriberData:subscriber,
    subscriberContactInfo: {
      mobile: data.subscriber?.account?.mobileNumber?.countryCode && data.subscriber.account.mobileNumber.number
        ? `${data.subscriber.account.mobileNumber.countryCode}${data.subscriber.account.mobileNumber.number}`
        : '',
      home: searchFieldFromAdditionalDetails('homeNumber'),
      work: searchFieldFromAdditionalDetails('workNumber'),

      video: data.subscriber?.account?.mobileNumber?.countryCode && data.subscriber.account.mobileNumber.number
        ? `${data.subscriber.account.mobileNumber.countryCode}${data.subscriber.account.mobileNumber.number}`
        : '',
      chat: data.subscriber?.account?.mobileNumber?.countryCode && data.subscriber.account.mobileNumber.number
        ? `${data.subscriber.account.mobileNumber.countryCode}${data.subscriber.account.mobileNumber.number}`
        : '',
      email: data.subscriber?.account?.emailAddress || '',
      address: data.subscriber?.account?.Address || searchFieldFromAdditionalDetails('homeAddress'),
      fullName: `${data.subscriber?.account?.firstName || 'N/A'} ${data.subscriber?.account?.lastName || ''}`,
      profileImageUrl: data.subscriber?.account?.profilePhoto?.link || '',
      age: data.subscriber?.account?.Age || (
        searchFieldFromAdditionalDetails('dateOfBirth')
          ? new Date().getFullYear() - new Date(searchFieldFromAdditionalDetails('dateOfBirth')).getFullYear()
          : null
      ),
      isUserActive: data.subscriber?.account?.registrationStatus === 'ACTIVE',
    },
    additionalDetails: subscriber?.additionalDetails,
    watchmeConfig: data.subscriber?.watchMeConfig,
    isDeleted: data.subscriber?.account?.isDeleted || ''
  };
});


// export const subscriberDetailsById = createAsyncThunk('subscriberDetails/ById', async (subscriberId: string) => {
//   const response = await getSubscriberById('d93833cc-b21e-46f7-b5cf-3cf2bcdeb461');
//   const subscriber = response.lifestreamResponse.data.subscriber;
//   const account = subscriber.account;

//   const getFieldValue = (fieldName) => {
//     const field = subscriber.additionalDetails.flatMap(section => section.fields).find(field => field.name === fieldName);
//     return field ? field.value || field.multipleChoiceValues?.value || '' : '';
//   };

//   const additionalDetailsFormatted = subscriber.additionalDetails.map(section => ({
//     sectionHeading: section.sectionHeading,
//     sectionId: section.sectionId,
//     fields: section.fields.map(field => ({
//       name: field.name,
//       value: field.value || field.multipleChoiceValues?.value || '',
//       dataType: field.dataType
//     }))
//   }));

//   const d  = {
//     subscriberContactInfo: {
//       mobile: `${account.mobileNumber.countryCode}${account.mobileNumber.number}`,
//       home: `${getFieldValue('homeNumber')?.countryCode || ''}${getFieldValue('homeNumber')?.number || ''}`,
//       work: `${getFieldValue('workNumber')?.countryCode || ''}${getFieldValue('workNumber')?.number || ''}`,
//       video: `${account.mobileNumber.countryCode}${account.mobileNumber.number}`,
//       chat: `${account.mobileNumber.countryCode}${account.mobileNumber.number}`,
//     },
//     emergencyContactList: subscriber.emergencyContacts?.map((contact: any) => ({
//       id: contact.id,
//       subScriberID: subscriberId,
//       contactName: `${contact.firstName} ${contact.lastName}`,
//       group: 'EC',
//       relationship: contact.relationship,
//       contact: `${contact.mobileNumber.countryCode}${contact.mobileNumber.number}`,
//       lastFix: '',
//       distance: '',
//       distanceValue: 0,
//       url: contact.photo ?? Images.NoProfilePictureIcon,
//       type: contact.contactType,
//       home: `${contact.homeNumber?.countryCode || ''}${contact.homeNumber?.number || ''}`,
//       work: `${contact.workNumber?.countryCode || ''}${contact.workNumber?.number || ''}`,
//       mobile: contact.mobileNumber,
//       contactSubscriberId: contact.contactSubscriberId,
//       firstName: contact.firstName ?? '',
//       location: undefined,
//       subscriberLocation: undefined,
//       showContactMarker: true,
//       homeAddress: contact.homeAddress ? contact.homeAddress : 'N/A',
//       currentLocation: '',
//     })) || [],
//     additionalDetails: additionalDetailsFormatted
//   };
//   return d
// });
const subscriberDetailsSlice = createSlice({
  name: 'subscriberDetails',
  initialState,
  reducers: {
    setSubscriberDetailsData: (state, action: PayloadAction<SubscriberDetailsObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as SubscriberDetailsObject;
      state.error = undefined;
    },
    setSubscriberDetailsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    },
    sortSubscriberContactData: (state, action:PayloadAction<any>) => {
      if(state.data){
        switch (action.payload.SortBy) {
        case SortColumn.ContactName:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.contactName.localeCompare(b.contactName) : b.contactName.localeCompare(a.contactName));
          break;
        
        case SortColumn.Group:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.group.localeCompare(b.group) : b.group.localeCompare(a.group));
          break;
        
        case SortColumn.Relationship:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.relationship.localeCompare(b.relationship) : b.relationship.localeCompare(a.relationship));
          break;
        
        case SortColumn.Distance:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? (a.distanceValue - b.distanceValue) : (b.distanceValue - a.distanceValue));
          break;
        
        // TODO : We'll have to change the logic based on the type we get here for dates
        case SortColumn.LastFix:
          state.data.emergencyContactList = state.data.emergencyContactList.sort((a, b) => action.payload.IsAscending ? a.lastFix.localeCompare(b.lastFix) : b.lastFix.localeCompare(a.lastFix));
          break;
        }
      }
    },
    updateSubscriberContactLocation: (state: SubscriberDetailsObjectState, action:PayloadAction<LastKnowLocationData[]>) => {
      if(state.data){
        action.payload.forEach(function(contactLocation){
          const index = state.data?.emergencyContactList.findIndex(x => x.contactSubscriberId === contactLocation.subscriberId);
          if(state.data && index != undefined && index > -1){
            state.data.emergencyContactList[index].roamAIUserId = contactLocation.roamAIUserId;
            state.data.emergencyContactList[index].currentLocation = contactLocation.currentLocation || 'N/A';
            state.data.emergencyContactList[index].lastFix = contactLocation.recordedAt? formatDate(contactLocation.recordedAt, 'DD/MM/yy HH:mm:ss'): 'N/A';
            state.data.emergencyContactList[index].times = contactLocation.recordedAt? formatDate(contactLocation.recordedAt, 'HH:mm'): 'N/A';
            state.data.emergencyContactList[index].location = contactLocation.latitude && contactLocation.longitude ? {lat: contactLocation.latitude, lng: contactLocation.longitude} : undefined;
            state.data.emergencyContactList[index].distance = contactLocation.distance || 'N/A';
            state.data.emergencyContactList[index].distanceValue = contactLocation.distanceValue || 0;
            state.data.emergencyContactList[index].activity = contactLocation.activity;
          }
        });

        //Sort contact data after updating locations
        let hasDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue > 0);
        hasDistanceValue = hasDistanceValue.sort((a, b) => a.distanceValue - b.distanceValue);
        let nonDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue === 0);
        nonDistanceValue = nonDistanceValue.sort((a, b) => a.contactName.localeCompare(b.contactName));
        state.data.emergencyContactList = hasDistanceValue.concat(nonDistanceValue);
      }
    },
    setEmergencyContactListDefaultSort: (state: SubscriberDetailsObjectState) => {
      if(state.data){
        let hasDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue > 0);
        hasDistanceValue = hasDistanceValue.sort((a, b) => a.distanceValue - b.distanceValue);
        let nonDistanceValue = state.data.emergencyContactList.filter(c => c.distanceValue === 0);
        nonDistanceValue = nonDistanceValue.sort((a, b) => a.contactName.localeCompare(b.contactName));
        state.data.emergencyContactList = hasDistanceValue.concat(nonDistanceValue);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscriberDetailsById.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(subscriberDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(subscriberDetailsById.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setSubscriberDetailsData,
  setSubscriberDetailsClear,
  sortSubscriberContactData,
  updateSubscriberContactLocation,
  setEmergencyContactListDefaultSort
} = subscriberDetailsSlice.actions;

export const subscriberDetailsSelector = (state: RootState) => state.subscriberDetails;

export default subscriberDetailsSlice.reducer;
