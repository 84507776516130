import React from 'react';
import './MapMarker.styles.css';
import {MapMarkerProps} from './MapMarker.props';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';
import {Images} from '../../../configs/images';

export const MapMarker: React.FC<MapMarkerProps> = ({
  isShowBroadcast,
  stroke = Colors.RCCritical,
  pointMarkerSize = {width: '24px', height: '24px'},
  broadcastSize = {width: '76px', height: '76px'},
}) => {
  const getMarkerPoint = (): string => {
    switch (stroke) {
    case Colors.RCCritical:
      return Images.ReadMarker;

    case Colors.RCSuccess:
      return Images.GreenMarker;
    default:
      return Images.ReadMarker;
    }
  };

  const getBroadcast = (): string => {
    switch (stroke) {
    case Colors.RCCritical:
      return Images.RedBroadcast;
    case Colors.RCSuccess:
      return Images.GreenBroadcast;

    default:
      return Images.RedBroadcast;
    }
  };

  return (
    <div className="marker-wrap">
      <div className="marker-point-wrap">
        <Image url={getMarkerPoint()} width={pointMarkerSize.width} height={pointMarkerSize.height} />
      </div>
      {isShowBroadcast && (
        <div className="broadcast-wrap">
          <Image url={getBroadcast()} width={broadcastSize.width} height={broadcastSize.height} />
        </div>
      )}
    </div>
  );
};
