import {Buffer} from 'buffer';
import {parse} from 'query-string';

export interface TokenData {
  apiKey: string;
  sessionId: string;
}

export const parseTokenData = (token: string): TokenData => {
  const base64Token = token.replace('T1==', '');
  const tokenData = Buffer.from(base64Token, 'base64').toString();
  const keyValues = parse(tokenData);
  const partnerId = keyValues.partner_id?.toString() || '';
  const sig = keyValues.sig?.toString() || '';
  const sigList = sig.split('=');
  const sessionId = sigList.length > 1 ? sigList[1] : '';
  return {
    apiKey: partnerId,
    sessionId
  };
};

export const parseNameAttribute = (attributes: any): string => {
  if (attributes) {
    return `${attributes['firstName']['value'] || ''} ${attributes['lastName']['value'] || ''}`;
  }
  return 'N/A';
};

export const parseAgeAttribute = (attributes: any): number => {
  if (attributes['age'] && !isNaN(attributes['age']['value'])) {
    return parseInt(attributes['age']['value']);
  } else {
    return 0;
  }
};

export const parseHomeAddressAttribute = (attributes: any): string => {
  if (attributes['homeAddress']) {
    return attributes['homeAddress']['value'];
  } else {
    return '';
  }
};

export const parseSubscriberIdAttribute = (attributes: any): string => {
  if (attributes['subscriberId']) {
    return attributes['subscriberId']['value'];
  } else {
    return '';
  }
};

export const parseInteractionIdAttribute = (attributes: any): string => {
  if (attributes['interactionId']) {
    return attributes['interactionId']['value'];
  } else {
    return '';
  }
};

export const parseTokenAttribute = (attributes: any): string | undefined => {
  return attributes['token']['value'] || undefined;
};

export const parseInteractionTypeAttribute = (attributes: any): string | undefined => {
  if (attributes['InteractionType']) {
    return attributes['InteractionType']['value'];
  } else {
    return '';
  }
};
