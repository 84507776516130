import React from 'react';
import {Text} from '../../atoms/Text';
import './TableHeader.styles.css';
import {Colors} from '../../../configs/colors';
import {TableHeaderProps} from '.';

export const TableHeader: React.FC<TableHeaderProps> = ({
  dataHeader,
  fontSizeType = 14,
  fontWeightType = '600',
  colorType = Colors.NeutralGrey1,
}) => {
  return (
    <div className="table-header-style">
      {
        dataHeader.map((item, index) =>
          <div className="header-title" style={{width: item.widthType}} key={index} onClick={item.onClick}>
            <Text margin="0px 10px 0px 0px" fontSize={fontSizeType} fontWeight={fontWeightType} color={colorType}>{item.type}</Text>
            {
              item.showIcon && item.Icon
            }
          </div>
        )
      }
    </div>
  );
};
