import React from 'react';
import {IconProps} from './Icon.props';
import './Icon.styles.css';

export const Icon: React.FC<IconProps> = ({
  backgroundColor,
  IconImage,
  iconColor = 'white'
}) => {
  return <div className="icon-wrap" style={{backgroundColor}}>
    <IconImage stroke={iconColor} height={16} width={16} />
  </div>;
};