import {configureStore} from '@reduxjs/toolkit';
import userTaskReducer from './userTaskSlice';
import videoCallReducer from './videoCallSlice';
import audioCallReducer from './audioCallSlice';
import userLocationReducer, {locationApi} from './userLocationSlice';
import subscriberSearchReducer from './subscriberSearchSlice';
import subscriberDetailsReducer from './subscriberDetailsSlice';
import interactionHistoryReducer from './interactionHistorySlice';
import interactionChatTokenReducer from './interactionChatTokenSlice';
import interactionDetailsReducer from './interactionDetailsSlice';
import interactionNotesReducer from './interactionNotesSlice';
import interactionStatusUpdateReducer from './interactionStatusUpdateSlice';
import interactionResolutionUpdateReducer from './interactionResolutionUpdateSlice';
import dialOutCallReducer from './dialOutCallSlice';
import referenceDataMedicalConditionsReducer from './referenceDataMedicalConditionsSlice';
import interactioStatisticsReducer from './interactionStatisticsSlice';
import interactionPstnCalloutReducer from './interactionPstnCallOutSlice';
import interactionChatCalloutReducer from './interactionChatCallOutSlice';
import notificationUserStatusReducer from './notificationUserStatusSlice';
import lastKnownLocationReducer from './userLastKnowLocationSlice';
import subscriberLocationReducer from './subscriberLocationSlice';
import trackingLocationReducer from './trackingSlice';

export const store = configureStore({
  reducer: {
    userTask: userTaskReducer,
    videoCall: videoCallReducer,
    userLocation: userLocationReducer,
    subscriberSearch: subscriberSearchReducer,
    subscriberDetails: subscriberDetailsReducer,
    interactionHistory: interactionHistoryReducer,
    interactionChatToken: interactionChatTokenReducer,
    interactionDetails: interactionDetailsReducer,
    interactionNotes: interactionNotesReducer,
    interactionStatusUpdate: interactionStatusUpdateReducer,
    interactionResolutionUpdate: interactionResolutionUpdateReducer,
    dialOutCall: dialOutCallReducer,
    referenceDataMedicalConditions: referenceDataMedicalConditionsReducer,
    interactioStatistics: interactioStatisticsReducer,
    interactionPstnCallOut: interactionPstnCalloutReducer,
    interactionChatCallOut: interactionChatCalloutReducer,
    notificationUserStatus: notificationUserStatusReducer,
    subscriberLocation: subscriberLocationReducer,
    audioCall: audioCallReducer,
    trackingLocation: trackingLocationReducer,
    lastKnownLocation: lastKnownLocationReducer,
    [locationApi.reducerPath]: locationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(locationApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
