import React, {useState} from 'react';
import './Tooltip.styles.css';
import {TooltipProps} from './Tooltip.props';

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  position = 'top',
  width = 'auto',
  className = '',
  padding,
}) => {
  const [show, setShow] = useState(false);

  return (
    <div
      style={{width, padding}}
      className={`tooltip ${className}`}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {show && (
        <div className={`tooltip-content ${position}`}>
          {content}
        </div>
      )}
      {children}
    </div>
  );
};
