import React from 'react';
import {StartProfileProps} from './StartProfile.props';
import {Text} from '../../atoms/Text';
import {Button, ButtonVariant} from '../../atoms/Button';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';
import SettingsIcon from '../../../assets/images/settings-icon.svg';
import {ProfileImage, ProfileStatus} from '../../organisms/ProfileImage';
import './StartProfile.styles.css';
import {ReactComponent as DotIcon} from '../../../assets/images/dot-xl-icon.svg';

export const StartProfile: React.FC<StartProfileProps> = ({
  url,
  name,
  email,
  status,
  onManageYourAccount,
  onSetAvailable,
  onSetAway,
  onSignOut
}) => {
  return (
    <div className="start-profile-style">
      <ProfileImage url={url} status={status} size="80px" borderWidth="1px" borderColor={Colors.LSTeal} />
      <Text margin="16px 0 0 0" fontSize={17} fontWeight="600" color={Colors.NeutralGrey1}>{name}</Text>
      <Text margin="4px 0 0 0" fontSize={14} color={Colors.NeutralGrey1}>{email}</Text>
      <Button
        variant={ButtonVariant.Profile}
        margin="20px 0 0 0"
        width="100%"
        onClick={onManageYourAccount}
      >
        <Image width="16px" height="16px" url={SettingsIcon} />
        <Text margin="0 0 0 8px" fontSize={15} fontWeight="600" color={Colors.NeutralBlack}>Manage your account</Text>
      </Button>
      <div className="start-profile-separator-style" />
      {status !== ProfileStatus.Active &&
        <div className="start-profile-status-style" onClick={onSetAvailable}>
          <DotIcon fill={Colors.RCSuccess} stroke={Colors.RCSuccess} width={15} height={15} />
          <Text fontSize={15} margin="0px 0px 0px 5px">Set yourself as</Text>
          &nbsp;
          <Text fontSize={15} fontWeight="700">‘Available’</Text>
        </div>
      }
      {status === ProfileStatus.Active &&
        <div className="start-profile-status-style" onClick={onSetAway}>
          <DotIcon fill={Colors.LavaRed} stroke={Colors.LavaRed} width={15} height={15} />
          <Text fontSize={15} margin="0px 0px 0px 5px">Set yourself as</Text>
          &nbsp;
          <Text fontSize={15} fontWeight="700">‘Away’</Text>
        </div>
      }
      <Button
        variant={ButtonVariant.Profile}
        margin="20px 0 0 0"
        width="120px"
        onClick={onSignOut}
      >
        Sign out
      </Button>
    </div>
  );
};
