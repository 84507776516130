import React from 'react';
import {Images} from '../../../configs/images';
import {Image} from '../../atoms/Image';
import {Text} from '../../atoms/Text';
import {StreetViewDialogProps} from './StreetViewDialog.props';
import './StreetViewDialog.styles.css';

export const StreetViewDialog: React.FC<StreetViewDialogProps> = ({closeCallback}) => {
  return <div className="street-view-dialog-style">
    <div className="close">
      <Image width="24px" url={Images.ActiveClose} onClick={closeCallback} />
    </div>
    <Image width="24px" url={Images.PegMan} />
    <Text margin="20px 0px" fontSize={16} fontWeight="600">Streetview is not available for this location</Text>
  </div>;
};