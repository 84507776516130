import React, {useEffect, useState} from 'react';
import {Colors} from '../../../configs/colors';
import {VideoPlayerProps} from './VideoPlayer.props';
import {Text} from '../../atoms/Text';
import './VideoPlayer.styles.css';
import {drawPolyline} from '../../../utils/commonFunction';
import {useAppSelector} from '../../../redux/hooks';
import {TrackingLocationSelector} from '../../../redux/trackingSlice';
import {userTaskSelector} from '../../../redux/userTaskSlice';

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  width = '120px',
  height = '160px',
  borderRadius = '0px',
  userInfoPanel,
  userLocation,
}) => {
  const userTask = useAppSelector(userTaskSelector);
  const trackingLocation = useAppSelector(TrackingLocationSelector);
  const [src, setSrc] = useState<string>('');
  const video = document.querySelector('video');

  const getPositionList = () => {
    if (userLocation && userLocation.pathData && userLocation.pathData.length > 0) {
      const list = userLocation.pathData.map((item) => {
        return {lng: item.longitude, lat: item.latitude};
      });
      return list;
    }
    return [];
  };

  const positionList = getPositionList();

  useEffect(() => {
    const getDomain = url.split('?')[0];
    const stateDomain = src.split('?')[0];
    if (getDomain && getDomain.localeCompare(stateDomain) !== 0) {
      setSrc(url);
    }
  }, [url]);

  useEffect(() => {
    if (trackingLocation && !userTask.isConnected && !userTask.isMissed) {
      drawPolyline(positionList, trackingLocation.map, video, trackingLocation.isTracking);
    }
  }, [trackingLocation.isTracking]);

  const getDrawPolyline = () => {
    if (trackingLocation && !userTask.isConnected && !userTask.isMissed) {
      drawPolyline(positionList, trackingLocation.map, video, trackingLocation.isTracking);
    }
  };

  return (
    <>
      <video
        id="video-element"
        className="VideoPlayer-style"
        controlsList="nodownload"
        style={{width, height, borderRadius, backgroundColor: Colors.NeutralBlack}}
        src={src}
        controls
        onPlay={getDrawPolyline}
        onSeeked={getDrawPolyline}
        onLoadedData={getDrawPolyline}
        onContextMenu={(event) => event.preventDefault()}
      />
      <div className="info-sub-call">
        <Text color={Colors.LSWhite}>{userInfoPanel?.address}</Text>
        <div className="info-sub-call-time">
          <Text color={Colors.LSWhite}>{userInfoPanel?.date}</Text>
          <Text color={Colors.LSWhite}>{userInfoPanel?.time}</Text>
          <Text color={Colors.LSWhite}>{userInfoPanel?.duration}</Text>
        </div>
      </div>
    </>
  );
};
