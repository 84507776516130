import React, {useState} from 'react';
import {Colors} from '../../../configs/colors';
import {Text} from '../../atoms/Text';
import {WatchMeBulletListItem} from '../WatchMeBulletListItem';
import './NoResponseProcedure.styles.css';
import {NoResponseProcedureProps} from './NoReponseProcedure.props';
import {ReactComponent as CaretDownImg} from '../../../assets/images/caret-down.svg';
import {ReactComponent as CaretUpImg} from '../../../assets/images/caret-up.svg';

export const NoResponseProcedure: React.FC<NoResponseProcedureProps> = ({items}) => {

  const [showItems, setShowItems] = useState(false);

  return <div>
    <div className={`no-response-procedure-header ${showItems && 'active'}`} onClick={() => setShowItems(!showItems)}>
      <Text color={Colors.RCCriticalBG} margin="0px 11px 0px 0px" fontSize={12}>NO RESPONSE PROCEDURE</Text>
      {showItems && <CaretUpImg stroke={Colors.RCCriticalBG} width={16} height={16} />}
      {!showItems && <CaretDownImg stroke={Colors.RCCriticalBG} width={16} height={16} />}
    </div>
    <div className={`no-response-procedure-items scroll-custom  ${!showItems ? 'collapsed' : ''}`}>
      {
        items.map((item, index) => <WatchMeBulletListItem {...item} key={index} />)
      }
    </div>
  </div>;
};
