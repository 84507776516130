import React, {useEffect, useState} from 'react';
import {Label, TabsProps} from '.';
import {Tab} from '../../atoms/Tab';
import './Tabs.styles.css';

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  children,
  onTabs,
  tabName
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].label);

  useEffect(() => {
    setActiveTab(tabName || tabs[0].label);
  }, [tabName]);

  const handleActiveTabs = (tab: Label) => {
    setActiveTab(tab.label);
    onTabs(tab.label);
  };

  return (
    <div className="tabs">
      {tabs.map((tab, index) => {
        return (
          <Tab
            activeTab={activeTab}
            key={index}
            label={tab.label}
            onClick={() => handleActiveTabs(tab)}
          />
        );
      })}
      <div className="tab-content">
        {children}
      </div>
    </div>
  );
};
