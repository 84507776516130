import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';

export interface SubscriberLocationState {
  address?: string;
}

const initialState: SubscriberLocationState = {
  address: undefined
};

const subscriberLocationSlice = createSlice({
  name: 'subscriberLocation',
  initialState,
  reducers: {
    setSubscriberLocation: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setSubscriberLocationClear: (state) => {
      state.address = undefined;
    }
  },
});

export const {
  setSubscriberLocation,
  setSubscriberLocationClear
} = subscriberLocationSlice.actions;

export const subscriberLocationSelector = (state: RootState) => state.subscriberLocation;

export default subscriberLocationSlice.reducer;
