import React, {useState, useEffect} from 'react';
import {useMsal} from '@azure/msal-react';
import {useNavigate} from 'react-router-dom';
import {HeaderControlProps} from './HeaderControl.props';
import './HeaderControl.styles.css';
import {Image} from '../../components/atoms/Image/Image';
import {Button, ButtonVariant} from '../../components/atoms/Button';
import {Text} from '../../components/atoms/Text';
import Logo from '../../assets/images/logo.svg';
import {TabMenuItem} from '../../components/molecules/TabMenuItem';
import {Images} from '../../configs/images';
import {ProfileImage, ProfileStatus} from '../../components/organisms/ProfileImage';
import {StartProfile} from '../../components/layouts/StartProfile';
import {MenuDock} from '../../components/layouts/MenuDock';
import appConfig from '../../configs/appConfig.json';
import {ReactComponent as SearchIcon} from '../../assets/images/search-icon.svg';
import {ReactComponent as ActiveClose} from '../../assets/images/x-active.svg';
import {Colors} from '../../configs/colors';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {setAgentStatus, setUserTaskEnd, userTaskSelector} from '../../redux/userTaskSlice';
import {
  subscriberSearchByKey,
  setSubscriberSelectedObjectId,
  setSubscriberSearchClear,
  subscriberSearchSelector
} from '../../redux/subscriberSearchSlice';
import {SearchBar} from '../../components/organisms/SearchBar';
import {SearchResults} from '../../components/organisms/SearchResults';
import {CONSTANTS} from '../../constants/constants';
import {EventMessage, EventType} from '@azure/msal-browser';
import {setSelectedInteractionDetailsObjectClear} from '../../redux/interactionDetailsSlice';
import {setVideoCallEnd} from '../../redux/videoCallSlice';
import {interactionStatusUpdate} from '../../redux/interactionStatusUpdateSlice';
import {parseInteractionIdAttribute} from '../../utils/parsers';

export const HeaderControl: React.FC<HeaderControlProps> = ({onToggleDebugMode}) => {
  const {instance, accounts} = useMsal();
  const dispatch = useAppDispatch();
  const userTask = useAppSelector(userTaskSelector);
  const subscriberSearch = useAppSelector(subscriberSearchSelector);
  const [userStatus, setUserStatus] = useState<ProfileStatus>(ProfileStatus.Inactive);
  const [isOpenProfile, setOpenProfile] = useState<boolean>(false);
  const [isOpenDock, setOpenDock] = useState<boolean>(false);
  const [isOpenSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [isHomeTabDisabled, setHomeTabDisabledStatus] = useState<boolean>(false);
  const accountName = accounts[0] && accounts[0].name;
  const accountEmail = accounts[0] && accounts[0].username;
  const navigate = useNavigate();

  const itemsNav = [
    {
      id: 1,
      text: 'Home',
      url: '/home',
      icon: Images.HomeIcon,
      disabled: isHomeTabDisabled
    },
    {
      id: 2,
      text: 'Callers',
      url: '/callers',
      icon: Images.CallerIcon,
    },
    // {
    //   id: 3,
    //   text: 'Incident',
    //   url: '/incident',
    //   icon: Images.IncidentIcon,
    // },
  ];

  instance.addEventCallback((message: EventMessage) => {
    if (message.eventType === EventType.LOGOUT_SUCCESS) {
      dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.Offline));
    }
  });

  const handleBrowserCloseEvent = () => {
    localStorage.clear();
    dispatch(setAgentStatus(CONSTANTS.RC_OPERATOR_Status.Offline));
  };

  useEffect(() => {
    if (userTask.agent) {
      resolveUserState(userTask.agent.getState().name);
      userTask.agent.onStateChange((states: any) => {
        resolveUserState(states['newState']);
      });
      window.addEventListener('beforeunload', handleBrowserCloseEvent);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBrowserCloseEvent);
    };
  }, [userTask.agent]);

  useEffect(() => {
    if (userTask.isConnected) {
      const homeTabDisabled = userTask.attributes && userTask.attributes.InteractionType && userTask.attributes.InteractionType.value === CONSTANTS.CALL_TYPES.PSTN_CALL || false;
      setHomeTabDisabledStatus(homeTabDisabled);
    }else{
      setHomeTabDisabledStatus(false);
    }
  }, [userTask.isConnected]);

  const resolveUserState = (state: string) => {
    switch (state) {
    case 'Available':
      setUserStatus(ProfileStatus.Active);
      break;
    case 'On-Interaction':
      setUserStatus(ProfileStatus.OnInteraction);
      break;
    case 'Lunch Break':
      setUserStatus(ProfileStatus.Inactive);
      break;
    case 'Offline':
      setUserStatus(ProfileStatus.Busy);
      break;
    default:
      setUserStatus(ProfileStatus.Busy);
      break;
    }
  };

  const handleYourAccount = () => {
    // doo something
  };

  const handleSetAvailable = () => {
    const states = userTask.agent?.getAgentStates();
    const availableState = states?.find((state) => state.name === 'Available');
    availableState &&
      userTask.agent?.setState(availableState, {
        success: () => {
          console.log('Agent set to Available');
        },
        failure: (error: any) => {
          console.log('Agent set to available via streams failed', error);
        },
      });
  };

  const handleSetAway = () => {
    const states = userTask.agent?.getAgentStates();
    const offlineState = states?.find((state) => state.name === 'Offline');
    offlineState &&
      userTask.agent?.setState(offlineState, {
        success: () => {
          console.log('Agent set to Offline');
        },
        failure: (error: any) => {
          console.log('Agent set to unavailable via streams failed', error);
        },
      });
  };

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      const logingIdClaims = accounts[0].idTokenClaims as any;
      await instance.logoutRedirect({
        authority: appConfig.logoutRequest.authority,
        account: accounts[0],
        postLogoutRedirectUri: appConfig.logoutRequest.postLogoutRedirectUri,
        logoutHint: logingIdClaims.login_hint
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onItemClick = (subscriberId: string) => {
    setOpenSearchResult(false);
    dispatch(setSelectedInteractionDetailsObjectClear());
    dispatch(setSubscriberSelectedObjectId(subscriberId));
    navigate('/callers');
  };

  const onClickActiveAgents = () => {
    // Post topgun
  };
  const onClickCCTVHistory = () => {
    // Post topgun
  };
  const onClickOperatorHistory = () => {
    // Post topgun
  };
  const onClickStatistic = () => {
    // Post topgun
  };

  const onClickSearch = (searchText: string) => {
    dispatch(subscriberSearchByKey(searchText));
  };

  const handleOnSearchButton = () => {
    setOpenSearchResult(!isOpenSearchResult);
    dispatch(setSubscriberSearchClear());
  };

  const onKeyPress = (evt:any) => {
    if((evt.charCode == 10 || evt.charCode == 13)){
      evt.preventDefault();
      dispatch(subscriberSearchByKey(evt.target.value));
    }
  };

  const handleBrowserEndCall = () => {
    dispatch(setUserTaskEnd());
    dispatch(setVideoCallEnd());
    dispatch(interactionStatusUpdate({
      InteractionId: parseInteractionIdAttribute(userTask.attributes),
      MainStatus: CONSTANTS.INTERACTION_STATUS.Open,
      SubStatus: userTask.isMissed ? CONSTANTS.INTERACTION_SUBSTATUS.Missed : CONSTANTS.INTERACTION_SUBSTATUS.Ended}));
  };

  useEffect(() => {
    if (userTask.isConnected || userTask.isMissed) {
      window.addEventListener('beforeunload', handleBrowserEndCall);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBrowserEndCall);
    };
  }, [userTask.isConnected]);


  return (
    <header>
      <span className="header-logo">
        <Image url={Logo} width="135px" />
        <span className="version">
          <Text fontSize={10}>
            {'v'}{appConfig.Build.version}
          </Text>
        </span>
      </span>
      <TabMenuItem itemsNav={itemsNav} />
      {appConfig.Debug.awsConnect === 'true' && <Button
        variant={ButtonVariant.Round}
        color={Colors.LavaRed}
        text="Debug"
        onClick={onToggleDebugMode}
      />}
      <div className="header-right">
        <div className="header-right-icon-wrap">
          {!isOpenSearchResult ? (
            <div className="search-icon">
              <SearchIcon
                onClick={handleOnSearchButton}
                width={24}
                height={24}
              />
            </div>
          ) : (
            <div className="close-search-icon" onClick={handleOnSearchButton}>
              <ActiveClose
                stroke={Colors.LSDarkTeal}
                width={24}
                height={24}
              />
            </div>
          )}
          {isOpenSearchResult ? (
            <div className={`search-result-container ${isOpenSearchResult ? 'opened': ''}`}>
              <SearchBar onSearchPress={onClickSearch} isFetching={subscriberSearch.isLoading} onKeyPress={onKeyPress}/>
              {subscriberSearch.isLoading && (
                <div className="search-spinner-wrap">
                  <Image width="150px" url={Images.Spinner} />
                </div>
              )}
              {subscriberSearch.data && <SearchResults onOpenItemClick={onItemClick} isFetching={subscriberSearch.isLoading} results={subscriberSearch.data} />}
            </div>
          ) : <div className="hide-search-result"></div>}
        </div>
        <div className="header-right-icon-wrap">
          {/* <div
            className={`grid-icon ${isOpenDock && 'active'}`}
            onClick={() => setOpenDock(!isOpenDock)}
          >
            <Image url={Images.Grid} width="20" />
          </div> */}
          {isOpenDock && (
            <div className="header-menu-dock">
              <MenuDock
                onClickActiveAgents={onClickActiveAgents}
                onClickCCTVHistory={onClickCCTVHistory}
                onClickOperatorHistory={onClickOperatorHistory}
                onClickStatistic={onClickStatistic}
              />
            </div>
          )}
        </div>
        <div>
          <div
            className={`header-profile ${isOpenProfile && 'active'}`}
            onClick={() => setOpenProfile(!isOpenProfile)}
          >
            <span>
              <Text color="#2C2E2E" fontSize={15} fontWeight="600">{accountName}</Text>
            </span>
            <span>
              <ProfileImage url={Images.NoProfilePictureIcon} status={userStatus} border="none" />
            </span>
          </div>
          {isOpenProfile && (
            <div className="start-profile">
              <StartProfile
                url={Images.NoProfilePictureIcon}
                name={accountName || ''}
                email={accountEmail}
                status={userStatus}
                onManageYourAccount={handleYourAccount}
                onSetAvailable={handleSetAvailable}
                onSetAway={handleSetAway}
                onSignOut={handleSignOut}
              />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
