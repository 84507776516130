import React, {useEffect, useRef, useState} from 'react';
import {useMsal} from '@azure/msal-react';
import NexmoClient, {
  Application,
  Conversation,
  EventsPage,
  ImageEvent,
  TextEvent,
} from 'nexmo-client';
import {ChatConversationProps} from '.';
import {Image as CustomImage} from '../../components/atoms/Image';
import {Text} from '../../components/atoms/Text';
import './ChatConversation.styles.css';
import {CircleImage} from '../../components/molecules/CircleImage';
import {Images} from '../../configs/images';
import {CONSTANTS} from '../../constants/constants';
import {ChatContent, DataConversation} from './ChatConversation.props';
import {formatDate} from '../../utils/dateTimeHelper';
import {Colors} from '../../configs/colors';
import {ChatOptions} from '../../components/molecules/ChatOptions';
import appConfig from '../../configs/appConfig.json';
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {setUserTaskEnd, userTaskSelector} from '../../redux/userTaskSlice';
import {nexmoJwtToken} from '../../redux/interactionChatTokenSlice';
import {getFirstNameFromFullName} from '../../utils/commonFunction';
import {ReactComponent as SeenIcon}  from '../../assets/images/seen-icon.svg';
import {ReactComponent as DeliveredIcon}  from '../../assets/images/delivered-icon.svg';
import SendbirdChat from '@sendbird/chat';
import {GroupChannel, GroupChannelHandler, GroupChannelModule, Member} from '@sendbird/chat/groupChannel';
import {User, MetaData, BaseChannel, ChannelType, UserUpdateParams} from '@sendbird/chat';
import {BaseMessage, PreviousMessageListQuery, PreviousMessageListQueryParams, UserMessage, FileMessage, FileMessageCreateParams, MentionType} from '@sendbird/chat/message';
const borderStyle = '2px solid #0DA9B9';

export const ChatConversation: React.FC<ChatConversationProps> = ({
  conversationId = '',
  subscriberId,
  subscriberImage,
}) => {
  console.log('conversationId',conversationId,'subscriberId',subscriberId,'subscriberImage',subscriberImage);
  const dispatch = useAppDispatch();
  const {accounts} = useMsal();
  const userTask = useAppSelector(userTaskSelector);
  const [inputMessage, setInputMessage] = useState<string>('');
  const [nexmoApp, setNexmoApp] = useState<Application>();
  const [conversationSession, setConversationSession] = useState<Conversation>();
  const conversation = {
    callRef: '',
    chatContent: [],
    date: '',
  };
  const [conversationContent, setChatContent] = useState<DataConversation>(conversation);
  const [currentEventsPage, setInitialEventsPage] = useState<EventsPage>();
  const [messageType, setMessageType] = useState<string>(CONSTANTS.CHAT_MESSAGE_TYPES.TEXT);
  const [fileMessage, setFileMessage] = useState<File>();
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [inputMessageHover, setInputMessageHover] = useState<string>('');
  const [resetInput, setResetInput] = useState<boolean>(false);
  const [showChatLoader, setChatLoaderStatus] = useState<boolean>(false);
  const chatEndDivRef = useRef<HTMLDivElement>(null);
  const operatorName = accounts[0] && getFirstNameFromFullName(accounts[0].name);
  const chatOperatorId = accounts[0] && accounts[0].localAccountId;
  const callerName = userTask.attributes?.firstName.value;
  const [keyUpTimeout, setKeyUpTimeOut] = useState<NodeJS.Timeout>();
  const [scrollToBottomStatus, setScrollToBottomStatus] = useState<boolean>(false);
  const unseenTextEvents = useRef<TextEvent[]>([]);
  const unseenImageEvents = useRef<ImageEvent[]>([]);
  const [chatTimeout, setChatTimeout] = useState<NodeJS.Timeout>();
  const [curretChannel, setCurretChannel] = useState<BaseChannel>();
  const [previousMessageListQuery, setPreviousMessageListQuery] = useState<PreviousMessageListQuery>();

  const handleChangeMessage = (event: any) => {
    setMessageType(CONSTANTS.CHAT_MESSAGE_TYPES.TEXT);
    setInputMessage(event.target.value);
    if (event.target.value === '') {
      setResetInput(!resetInput);
    }
  };

  const scrollToBottom = () => {
    if (chatEndDivRef && chatEndDivRef.current) {
      chatEndDivRef.current.scrollTop = chatEndDivRef.current.scrollHeight;
    }
  };

  const adjustChatScroll = () => {
    if (chatEndDivRef && chatEndDivRef.current) {
      chatEndDivRef.current.scrollTop = chatEndDivRef.current.scrollHeight - scrollPosition;
    }
  };

  const handleSendMessage = () => {
    switch (messageType) {
    case CONSTANTS.CHAT_MESSAGE_TYPES.TEXT: {
      curretChannel && (curretChannel as GroupChannel).endTyping();
      sendTextMessage(inputMessage);
      setInputMessage('');
      setResetInput(!resetInput);
      break;
    }
    case CONSTANTS.CHAT_MESSAGE_TYPES.FILE:
      sendImageMessage(); 
      break;
    }
  };

  const sendTextMessage = (chatMessage: string | undefined) => {
    console.log('curretChannel',curretChannel);

    if (chatMessage && curretChannel) {
      setScrollToBottomStatus(true);
      const messageDate = formatDate(new Date().toString(), 'dddd LT');
      const messageId = `${chatMessage}-${new Date().getTime()}`;
      conversationContent.chatContent.push({
        type: CONSTANTS.CHAT_MESSAGE_TYPES.TEXT,
        name: 'You',
        role: 'operator',
        message: chatMessage,
        status: 'active',
        time: messageDate,
        url: Images.NoProfilePictureIcon,
        id: messageId,
      });
      setChatContent({...conversationContent});

      const userMessageParams: any = {
        message: chatMessage
      };
      curretChannel.sendUserMessage(userMessageParams)
        .onSucceeded((message) => {
          setInputMessage('');
          const successItemIndex = conversationContent.chatContent.findIndex(
            (x) => x.id === messageId
          );
          if (successItemIndex >= 0) {
            conversationContent.chatContent[successItemIndex].id = message.messageId.toString();
            setChatContent({...conversationContent});
          }
        })
        .onFailed((error) => {
          console.log(error);
          const errorItemIndex = conversationContent.chatContent.findIndex(
            (x) => x.id === messageId
          );
          if (errorItemIndex >= 0) {
            conversationContent.chatContent[errorItemIndex].isError = true;
            setChatContent({...conversationContent});
          }
        });
      scrollToBottom();
    }
  };

  const sendImageMessage = () => {
    if (fileMessage && curretChannel) {

      const params: FileMessageCreateParams = {
        file: fileMessage, // Or .fileUrl = FILE_URL (You can also send a file message with a file URL.)
        fileName: fileMessage.name,
        fileSize: fileMessage.size,
        thumbnailSizes: [{maxWidth: 100, maxHeight: 100}, {maxWidth: 200, maxHeight: 200}],
        // Add the maximum sizes of thumbnail images.
        // Up to three thumbnail images are allowed.
        mimeType: fileMessage.type,
        mentionType: MentionType.USERS, // Either USERS or CHANNEL
      };
      curretChannel.sendFileMessage(params)
        .onSucceeded((message: any) => {
          const messageId = message.messageId;
        });
    }
  };

  const sendChatEndMessage = () => {
    if (curretChannel) {
      setScrollToBottomStatus(true);
      const messageDate = formatDate(new Date().toString(), 'LT');
      const messageId = `chat-end-${new Date().getTime()}`;
      conversationContent.chatContent.push({
        type: CONSTANTS.CHAT_MESSAGE_TYPES.CHAT_END,
        name: '',
        role: '',
        message: CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.CHAT_END,
        status: 'active',
        time: messageDate,
        url: '',
        id: messageId,
      });
      setChatContent({...conversationContent});

      const userMessageParams: any = {
        message: CONSTANTS.CHAT_OPTIONS.TEXT_HOVER.CHAT_END,
        customType: 'CHAT-END',
      };
      curretChannel.sendUserMessage(userMessageParams)
        .onSucceeded((message) => {
          setInputMessage('');
          const successItemIndex = conversationContent.chatContent.findIndex(
            (x) => x.id === messageId
          );
          if (successItemIndex >= 0) {
            conversationContent.chatContent[successItemIndex].id = message.messageId.toString();
            setChatContent({...conversationContent});
          }
        })
        .onFailed((error) => {
          console.log(error);
          const errorItemIndex = conversationContent.chatContent.findIndex(
            (x) => x.id === messageId
          );
          if (errorItemIndex >= 0) {
            conversationContent.chatContent[errorItemIndex].isError = true;
            setChatContent({...conversationContent});
          }
        });
      scrollToBottom();
    }
  };

  const onMessageTyping = (member: Member) => {
    if (chatOperatorId !== member.userId) {
      const existingItem = conversationContent.chatContent.find(
        (i) => i.userId === member.userId && i.message === ''
      );
      if (!existingItem) {
        conversationContent.chatContent.push({
          type: CONSTANTS.CHAT_MESSAGE_TYPES.TEXT,
          name: member.nickname,
          role: 'subscriber',
          message: '',
          status: 'active',
          time: '',
          url: member.userId == subscriberId ? subscriberImage : Images.NoProfilePictureIcon,
          userId: member.userId,
          id: 'typing-message',
        });
        setChatContent({...conversationContent});
        scrollToBottom();
      }
    }
  };

  const onMessageTypingStop = () => {
    conversationContent.chatContent = conversationContent.chatContent.filter((chatEvent) => {
      return chatEvent.id !== 'typing-message' && chatEvent.message !== '';
    });
    setChatContent({...conversationContent});
  };

  const loadChatHistory = (query?: PreviousMessageListQuery) => {
    const previousMessageQuery = query? query:  previousMessageListQuery;
    previousMessageQuery!.load().then((messages: BaseMessage[]) => {
      updateChannelHistory(messages);
    });
  };

  const updateChannelHistory = (messages: BaseMessage[]) => {
    const imageMessages: FileMessage[] = [];
    messages.forEach((message: any) => {
      if (message.messageType === 'user' || message.messageType === 'file') {
        const displayData = message.sender.userId === chatOperatorId ? 
          {name: 'You', role: 'operator', profileImage: Images.NoProfilePictureIcon} :
          {
            name: message.sender.nickname,
            role: 'subscriber',
            profileImage: message.sender.userId == subscriberId ? subscriberImage : Images.NoProfilePictureIcon 
          };
        if (document.visibilityState === 'visible') {
          curretChannel && (curretChannel as GroupChannel).markAsRead();
        }

        switch (message.messageType) {
        case 'user': {
          const textMessage = message as UserMessage;
          if(textMessage.customType && textMessage.customType === 'CHAT-END'){
            conversationContent.chatContent.unshift({
              type: CONSTANTS.CHAT_MESSAGE_TYPES.CHAT_END,
              name: displayData.name,
              role: displayData.role,
              message: textMessage.message,
              status: 'active',
              time: formatDate(new Date(textMessage.createdAt).toString(), 'LT'),
              url: displayData.profileImage,
              id: textMessage.messageId.toString(),
            });
          }else{
            conversationContent.chatContent.unshift({
              type: CONSTANTS.CHAT_MESSAGE_TYPES.TEXT,
              name: displayData.name,
              role: displayData.role,
              message: textMessage.message,
              status: 'active',
              time: formatDate(new Date(textMessage.createdAt).toString(), 'dddd LT'),
              url: displayData.profileImage,
              seen: displayData.role === 'operator' ? true : false,
              id: textMessage.messageId.toString(),
            });
          }
          break;
        }
        case 'file': {
          const fileMessage = message as FileMessage;
          conversationContent.chatContent.unshift({
            type: CONSTANTS.CHAT_MESSAGE_TYPES.FILE,
            name: displayData.name,
            role: displayData.role,
            message: Images.Spinner,
            status: 'active',
            time: formatDate(new Date(fileMessage.createdAt).toString(), 'dddd LT'),
            url: displayData.profileImage,
            id: fileMessage.messageId.toString(),
          });
          imageMessages.push(fileMessage);
          break;
        }
        }
      }
    });
    setChatContent({...conversationContent});
    adjustChatScroll();
    imageMessages.length > 0 && loadImageMessages(imageMessages);
  };

  const loadImageMessages = (imageMessages: FileMessage[]) => {
    imageMessages.forEach((imageMessage) =>{
      const itemIndex = conversationContent.chatContent.findIndex((x) => x.id === imageMessage.messageId.toString());
      if (itemIndex >= 0) {
        conversationContent.chatContent[itemIndex].message = imageMessage.url;
      }
    });
    setChatContent({...conversationContent});
  };

  const onChatMessageReceived = (channel: BaseChannel, message: any) => {
    if(channel.url === conversationId && (message.messageType === 'user' || message.messageType === 'file')){
      if (document.visibilityState === 'visible') {
        (channel as GroupChannel).markAsRead();
      }
      const displayData = message.sender.userId === chatOperatorId ? 
        {name: 'You', role: 'operator', profileImage: Images.NoProfilePictureIcon} :
        {
          name: message.sender.nickname,
          role: 'subscriber',
          profileImage: message.sender.userId == subscriberId ? subscriberImage : Images.NoProfilePictureIcon 
        };

      switch (message.messageType) {
      case 'user': {
        conversationContent.chatContent.push({
          type: CONSTANTS.CHAT_MESSAGE_TYPES.TEXT,
          name: displayData.name,
          role: displayData.role,
          message: message.message,
          status: 'active',
          time: formatDate(new Date(message.createdAt).toString(), 'dddd LT'),
          url: displayData.profileImage,
          id: message.messageId.toString(),
        });
        break;
      }
      case 'file': {
        conversationContent.chatContent.push({
          type: CONSTANTS.CHAT_MESSAGE_TYPES.FILE,
          name: displayData.name,
          role: displayData.role,
          message: Images.Spinner,
          status: 'active',
          time: formatDate(new Date(message.createdAt).toString(), 'dddd LT'),
          url: displayData.profileImage,
          id: message.messageId.toString(),
        });
        break;
      }
      }
      setChatContent({...conversationContent});
      scrollToBottom();
      if(message.messageType === 'file'){
        showImageMessagePreview(message); 
      }  
    }
  };

  const showImageMessagePreview = (message: FileMessage) => {
    const itemIndex = conversationContent.chatContent.findIndex((x) => x.id === message.messageId.toString());
    if (itemIndex >= 0) {
      conversationContent.chatContent[itemIndex].message = message.url;
      setChatContent({...conversationContent});
    }
  };

  const onMessageSeen = (sender: any) => {
    conversationContent.chatContent.map((messEvent)=>{
      if (messEvent.role === 'operator' && chatOperatorId !== sender.userName) {
        messEvent.seen = true;
        messEvent.delivered = false;
        return {...messEvent};
      }
    });
    setChatContent({...conversationContent});
  };

  const onMessageDelivered = (sender: any) => {
    conversationContent.chatContent.map((messEvent)=>{
      if (messEvent.role === 'operator' && chatOperatorId !== sender.userName && !messEvent.seen) {
        messEvent.delivered = true;
        return {...messEvent};
      }
    });
    setChatContent({...conversationContent});
  };

  const groupChannelHandler: GroupChannelHandler = new GroupChannelHandler({
    onMessageReceived: onChatMessageReceived,
    onUserReceivedInvitation: async (channel: GroupChannel, inviter: User, invitees: User[]) => {
      console.log('not implemented');
    },
    onUserDeclinedInvitation: (channel: GroupChannel, inviter: User, invitee: User) => { 
      console.log('not implemented');
    },
    onUndeliveredMemberStatusUpdated: (channel: GroupChannel) => { 
      if(channel.url === conversationId){
        conversationContent.chatContent.map((messEvent)=>{
          if (messEvent.role === 'operator' && !messEvent.seen) {
            messEvent.delivered = true;
            return {...messEvent};
          }
        });
        setChatContent({...conversationContent});
      }
    },
    onUnreadMemberStatusUpdated: (channel: GroupChannel) => { 
      if(channel.url === conversationId){
        conversationContent.chatContent.map((messEvent)=>{
          if (messEvent.role === 'operator') {
            messEvent.seen = true;
            messEvent.delivered = false;
            return {...messEvent};
          }
        });
        setChatContent({...conversationContent});
      }
    },
    onTypingStatusUpdated: (channel: GroupChannel) => { 
      const members: Member[] = channel.getTypingUsers();
      if(members.length > 0){
        const subscriber = members.find(x => x.userId == subscriberId);
        if(subscriber){
          onMessageTyping(subscriber);
        }
      }else{
        onMessageTypingStop();
      }
    }
  });

  const initiateSendbirdChat = async () => {
    console.log('chatOperatorIdchatOperatorIdchatOperatorId',chatOperatorId);

    try {
      console.log('initiateSendbirdChat');
      const sendbird = await SendbirdChat.init({
        appId: appConfig.Chat.sendbirdAppId,
        localCacheEnabled: true,
        modules: [new GroupChannelModule()]
      });
      const user = await sendbird.connect(chatOperatorId);
      const currentChannel = await sendbird.groupChannel.getChannel(conversationId);
      setCurretChannel(currentChannel); 
      sendbird.groupChannel.addGroupChannelHandler('groupEventHandler', groupChannelHandler);
      conversationContent.chatContent = [];
      setChatContent({...conversationContent});
      setChatLoaderStatus(true);
      const previousMessageListQueryParams: PreviousMessageListQueryParams = {
        limit: 50,
        reverse: true,
      };
      const query: PreviousMessageListQuery = currentChannel.createPreviousMessageListQuery(previousMessageListQueryParams);
      setPreviousMessageListQuery(query);
      loadChatHistory(query);
      (currentChannel as GroupChannel).markAsRead();
      setChatLoaderStatus(false);
    } catch (err) {
      console.log('errerrerrerr',err);
      setChatLoaderStatus(false);
    }
  };

  const onChatScroll = (scrollData: any) => {
    setScrollPosition(scrollData.target.scrollHeight);
    if (scrollData.target.scrollTop == 0) {
      loadChatHistory();
    }
  };

  const onKeyPress = (evt: any) => {
    if ((evt.charCode == 10 || evt.charCode == 13) && !evt.shiftKey) {
      evt.preventDefault();
      handleSendMessage();
    } else {
      curretChannel && (curretChannel as GroupChannel).startTyping();
    }
  };

  const onKeyUp = () => {
    if (keyUpTimeout) {
      clearTimeout(keyUpTimeout);
      setKeyUpTimeOut(undefined);
    }

    const typingTimeout = setTimeout(() => {
      curretChannel && (curretChannel as GroupChannel).endTyping();
    }, 3000);
    setKeyUpTimeOut(typingTimeout);
  };

  const onClickFileDownload = async (imageData: ChatContent) => {
    if (imageData && imageData.message) {
      const mainImage = document.getElementsByClassName(`${imageData.id}-main`);
      const mainImageHeight = (mainImage[0] as HTMLElement).offsetHeight;
      const loadingImageList = document.getElementsByClassName(`${imageData.id}-loading`);
      const loadingImage = loadingImageList[0] as HTMLElement;
      loadingImage.style.height = mainImageHeight+'px';
      loadingImage.style.display = 'block'; 
      const response = await fetch(imageData.message);
      const blobImage = await response.blob();
      const localImage = URL.createObjectURL(blobImage);
      const downloadLink = document.createElement('a');
      downloadLink.href = localImage;
      downloadLink.target = '_blank';
      downloadLink.download = '';
      downloadLink.click();
      loadingImage.style.display = 'none'; 
      downloadLink.remove();
    }
  };

  const onEndChat = () => {
    dispatch(setUserTaskEnd());
  };

  const trimCharacters = (value: string) => {
    if (value && value.length > 80) {
      const text = value.replace(value.slice(80, value.length), '...');
      return text;
    }
    return value;
  };

  const onFocus = () => {
    curretChannel && (curretChannel as GroupChannel).markAsRead();
    // unseenTextEvents.current.forEach(function (textEvent) {
    //   textEvent.seen();
    // });
    // unseenImageEvents.current.forEach(function (imageEvent) {
    //   imageEvent.seen();
    // });
    // unseenTextEvents.current = [];
    // unseenImageEvents.current = [];
  };

  useEffect(() => {
    // console.log('conversationId',conversationId,userTask);
    if (conversationId) {
      window.addEventListener('focus', onFocus);
      conversationContent.chatContent = [];
      setChatContent({...conversationContent});
      setChatLoaderStatus(true);
      initiateSendbirdChat();
    }
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [conversationId, userTask.isConnected]);

  useEffect(() => {
    if (scrollToBottomStatus) {
      setScrollToBottomStatus(false);
      scrollToBottom();
    }
    if (userTask.isConnected && conversationContent.chatContent.length > 0) {
      if (chatTimeout) {
        clearTimeout(chatTimeout);
        setChatTimeout(undefined);
      }
      const endChatTimeOut = +appConfig.Interactions.automaticChatEndDuration * 1000;
      const idleTimeout = setTimeout(() => {
        onEndChat();
      }, endChatTimeOut);
      setChatTimeout(idleTimeout);
    }
    if (conversationContent.chatContent.length > 0
      && conversationContent.chatContent[conversationContent.chatContent.length - 1].type === CONSTANTS.CHAT_MESSAGE_TYPES.CHAT_END) {
      scrollToBottom();
    }
  }, [conversationContent]);

  useEffect(() => {
    if (userTask.isEnded) {
      chatTimeout && clearTimeout(chatTimeout);
      setChatTimeout(undefined);
      setInputMessage('');
      setResetInput(!resetInput);
      sendChatEndMessage();
    }
  }, [userTask.isEnded]);

  return (
    <div className="conversation-style">
      <div className="conversation-content" onScroll={onChatScroll} ref={chatEndDivRef}>
        {showChatLoader && (
          <div className="chat-spinner-wrap">
            <CustomImage width="150px" url={Images.Spinner} />
          </div>
        )}
        {conversationContent.chatContent &&
          conversationContent.chatContent.map((item, index) => (
            <div key={index}>
              {item.type !== CONSTANTS.CHAT_MESSAGE_TYPES.CHAT_END ? (
                <div
                  className={`chat-item ${
                    item.role === CONSTANTS.ROLES.OPERATOR ? 'operator' : 'sub'
                  }`}
                >
                  {item.role !== CONSTANTS.ROLES.OPERATOR && (
                    <div className="avatar-active">
                      <CircleImage borderWidth="0px" size="30px" url={item.url || ''} />
                    </div>
                  )}
                  <div className="message">
                    {item.type !== CONSTANTS.CHAT_MESSAGE_TYPES.CHAT_END && (
                      <div className="message-header">
                        <Text fontSize={14} fontWeight="600" color={Colors.NeutralGrey1}>
                          {item.name}
                        </Text>
                        <div className="message-header-time">
                          <Text
                            fontSize={12}
                            fontWeight="400"
                            color={Colors.NeutralGrey2}
                            className="time-text"
                          >
                            {item.time}
                          </Text>
                          {item.seen ? <SeenIcon width={20} height={20}/>  : null}
                          {item.delivered ? <DeliveredIcon width={20} height={20}/>  : null}
                        </div>
                      </div>
                    )}
                    {item.type === CONSTANTS.CHAT_MESSAGE_TYPES.TEXT ? (
                      item.message ? (
                        <div className="message-wrapper">
                          {item.isError && (
                            <div className="message-error">
                              <CustomImage width="18px" url={Images.ChatErrorIcon} />
                            </div>
                          )}
                          <div
                            className={`message-content-text message-content-${
                              item.role === CONSTANTS.ROLES.OPERATOR ? 'operator' : 'sub'
                            }`}
                          >
                            {item.message}
                          </div>
                        </div>
                      ) : (
                        <div className="typing-message">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      )
                    ) : (
                      <div className="chat-image-warpper">
                        <CustomImage
                          className={item.id+'-main'}
                          width="150px"
                          url={item.message}
                          onClick={() => onClickFileDownload(item)}
                        />
                        <CustomImage
                          className={item.id+'-loading downlod-image-loader'}
                          width="150px"
                          url={Images.Spinner}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="chat-end-message">
                  <div>{item.time}</div>
                  <div>{item.message}</div>
                </div>
              )}
              {item.callRef && (
                <div className="call-ref">
                  <Text margin="0px 8px" fontSize={15} fontWeight="400" color={Colors.NeutralGrey2}>
                    {item.callRef}
                  </Text>
                  <div className="line-chat"></div>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="conversation-footer">
        {
          <div className="conversation-footer-style">
            <div className="conversation-action">
              <div className="conversation-input">
                <textarea
                  className={`input-message ${
                    inputMessage || inputMessageHover ? 'has-value' : ''
                  }`}
                  value={inputMessage}
                  onChange={handleChangeMessage}
                  onKeyPress={onKeyPress}
                  onKeyUp={onKeyUp}
                  style={inputMessage ? {border: borderStyle} : {}}
                  placeholder="Start typing..."
                  maxLength={5000}
                />
                {inputMessageHover && (
                  <div className="text-highlighted">{trimCharacters(inputMessageHover)}</div>
                )}
              </div>
              <div
                className={`btn-send ${!inputMessage ? 'disabled' : ''}`}
                onClick={handleSendMessage} 
              >
                <CustomImage width="44px" url={Images.ImgSend} />
              </div>
            </div>
            <div className="chat-option">
              <ChatOptions
                automaticResponse={(value: string) => setInputMessage(value)}
                automaticResponseHover={(value: string) => setInputMessageHover(value)}
                reset={resetInput}
                onEndChat={onEndChat}
                isConnect={userTask.isConnected}
                callerName={callerName}
                operatorName={operatorName}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};
