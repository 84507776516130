import {InteractionsData} from '../../organisms/CallsTable/CallsTable.props';

export interface TableRowProps {
  rowData: InteractionsData;
  showAdditionalData?: boolean;
}

export enum TableRowEnum {
  INCOMING = 'incoming',
  VIDEO = 'video',
  CALL = 'call',
  ALERT = 'alert',
  MESSAGE = 'message'
}
export enum TableRowCallState {
  INCOMING = 'incoming',
  ACTIVE = 'active',
  ENDCALL= 'endcall', 
  OPEN = 'open',
  CLOSE = 'close'
}
export enum TableRowButtonEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
} 
  