import React, {useState} from 'react';
import {MobileCallControlProps, MobileCallControlVariant} from './MobileCallControl.props';
import './MobileCallControl.styles.css';
import {ReactComponent as Pause} from '../../../assets/images/pause.svg';
import {ReactComponent as VideoCameraSlash} from '../../../assets/images/video-camera-slash.svg';
import {ReactComponent as DeviceMobile} from '../../../assets/images/device-mobile.svg';
import {ReactComponent as UserPlus} from '../../../assets/images/user-plus.svg';
import {ReactComponent as PhoneDisconnect} from '../../../assets/images/phone-disconnect.svg';
import CameraFlip from '../../../assets/images/camera-flip.svg';
import Microphone from '../../../assets/images/microphone.svg';
import SpeakerHigh from '../../../assets/images/speaker-high.svg';
import VideoCameraSlashIcon from '../../../assets/images/video-camera-slash.svg';
import {Image} from '../../atoms/Image';
import {Text} from '../../atoms/Text';
import {Colors} from '../../../configs/colors';
import {Button, ButtonVariant} from '../../atoms/Button';
import {ContactCallerControl} from '../../organisms/ContactCallerControl';

export const MobileCallControl: React.FC<MobileCallControlProps> = ({
  variant,
  isHold = false,
  isCamera = true,
  isDeviceCamera = true,
  isDeviceMic = true,
  onAddClick,
  onEndClick,
  onHoldClick,
  onVideoClick,
  onCameraFlipClick,
  onDeviceVideoClick,
  onMicrophoneClick,
  onSpeakerClick,
  onShowDevice,
  isDeviceControlTextVisible = false,
  emergencyContacts = [],
  isCallEndButtonDisabled = false,
  isHoldButtonDisabled = false,
}) => {
  const [isDevice, setIsDevice] = useState(false);
  const isAudioCall = MobileCallControlVariant.AudioCall === variant;
  const deviceControlBUttonWidth = isDeviceControlTextVisible ? '95.75px' : '69.75px';
  const [isContactsOpen, setContactsOpen] = useState(false);

  const handleOnDevice = () => {
    setIsDevice(!isDevice);
    onShowDevice && onShowDevice(!isDevice);
  };

  return (
    <div className="call-control-wrap">
      {isContactsOpen && (
        <div style={{width: '100%', padding: '10px'}}>
          <ContactCallerControl
            fullName="Emergency 000"
            userType="Service"
            backgroundColor={Colors.LavaRed}
            onAudioCallClick={() => undefined}
          />
          {emergencyContacts.map((item, index) => (
            <ContactCallerControl
              fullName={item.contactName}
              userType={item.type}
              backgroundColor={Colors.NeutralGrey1}
              onAudioCallClick={() => onAddClick && onAddClick(item.mobile)}
              key={index}
            />
          ))}
        </div>
      )}
      {isDevice && (
        <div className="mobile-call-top-button-wrap">
          <Button
            height="44px"
            width={deviceControlBUttonWidth}
            variant={ButtonVariant.Round}
            onClick={onCameraFlipClick}
            textColor={Colors.LSWhite}
            color={Colors.NeutralGrey1}
          >
            <Image width="24px" url={CameraFlip} />
            {isDeviceControlTextVisible && (
              <Text margin="0 0 0 4px" fontSize={12} fontWeight="400" color={Colors.LSWhite}>
                Flip
              </Text>
            )}
          </Button>

          <Button
            height="44px"
            width={deviceControlBUttonWidth}
            variant={ButtonVariant.Round}
            onClick={onDeviceVideoClick}
            textColor={Colors.LSWhite}
            color={isDeviceCamera ? Colors.NeutralGrey1 : Colors.LSTeal}
          >
            <Image width="24px" url={VideoCameraSlashIcon} />
            {isDeviceControlTextVisible && (
              <Text margin="0 0 0 4px" fontSize={12} fontWeight="400" color={Colors.LSWhite}>
                Video
              </Text>
            )}
          </Button>

          <Button
            height="44px"
            width={deviceControlBUttonWidth}
            variant={ButtonVariant.Round}
            onClick={onMicrophoneClick}
            textColor={Colors.LSWhite}
            color={isDeviceMic ? Colors.NeutralGrey1 : Colors.LSTeal}
          >
            <Image width="24px" url={Microphone} />
            {isDeviceControlTextVisible && (
              <Text margin="0 0 0 4px" fontSize={12} fontWeight="400" color={Colors.LSWhite}>
                Mic
              </Text>
            )}
          </Button>

          <Button
            height="44px"
            width={deviceControlBUttonWidth}
            variant={ButtonVariant.Round}
            onClick={onSpeakerClick}
            textColor={Colors.LSWhite}
            color={Colors.NeutralGrey1}
            disabled={true}
          >
            <Image width="24px" url={SpeakerHigh} />
            {isDeviceControlTextVisible && (
              <Text margin="0 0 0 4px" fontSize={12} fontWeight="400" color={Colors.LSWhite}>
                Volume
              </Text>
            )}
          </Button>
        </div>
      )}
      <div className="mobile-call-bottom-button-wrap">
        <Button
          text="Hold"
          height="50px"
          width="50px"
          variant={ButtonVariant.Icon}
          onClick={onHoldClick}
          textColor={Colors.LSWhite}
          color={isHold ? Colors.LSTeal : Colors.NeutralGrey1}
          disabled={isHoldButtonDisabled}
        >
          <Pause stroke={Colors.LSWhite} width={32} height={32} />
        </Button>

        <Button
          text="Video"
          height="50px"
          width="50px"
          variant={ButtonVariant.Icon}
          onClick={onVideoClick}
          textColor={Colors.LSWhite}
          color={isCamera ? Colors.NeutralGrey1 : Colors.LSTeal}
          disabled={isAudioCall || isHold}
        >
          <VideoCameraSlash stroke={Colors.LSWhite} width={32} height={32} />
        </Button>

        <Button
          text="Add"
          height="50px"
          width="50px"
          variant={ButtonVariant.Icon}
          onClick={() => setContactsOpen(!isContactsOpen)}
          textColor={Colors.LSWhite}
          color={isContactsOpen ? Colors.LSTeal : Colors.NeutralGrey1}
          disabled={true}
        >
          <UserPlus stroke={Colors.LSWhite} width={32} height={32} />
        </Button>

        <Button
          text="Device"
          height="50px"
          width="50px"
          variant={ButtonVariant.Icon}
          onClick={handleOnDevice}
          textColor={Colors.LSWhite}
          color={isDevice ? Colors.LSTeal : Colors.NeutralGrey1}
          disabled={isAudioCall || isHold}
        >
          <DeviceMobile stroke={Colors.LSWhite} width={32} height={32} />
        </Button>

        <div id="mobile-call-end-button">
          <Button
            text="End"
            height="50px"
            width="50px"
            variant={ButtonVariant.Icon}
            onClick={onEndClick}
            textColor={Colors.LSWhite}
            color={Colors.LavaRed}
            disabled={isCallEndButtonDisabled || isHold}
          >
            <PhoneDisconnect stroke={Colors.LSWhite} width={32} height={32} />
          </Button>
        </div>
      </div>
    </div>
  );
};
