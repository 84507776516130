import React, {useEffect, useState} from 'react';
import {Nav, CallerNavigationsProps} from '.';
import {CallerNavigation} from '../../atoms/CallerNavigation';
import './CallerNavigations.styles.css';

export const CallerNavigations: React.FC<CallerNavigationsProps> = ({
  navigations,
  onNavigations,
  navName
}) => {
  const [activeNav, setActiveNav] = useState<string>(navigations[0].label);

  useEffect(() => {
    setActiveNav(navName || navigations[0].label);
  }, [navName]);

  const handleActiveNav = (nav: Nav) => {
    setActiveNav(nav.label);
    onNavigations(nav.id);
  };

  return (
    <div className="caller-navigations">
      {navigations.map((nav, index) => {
        return (
          <CallerNavigation
            activeNav={activeNav}
            key={index}
            label={nav.label}
            onClick={() => handleActiveNav(nav)}
          />
        );
      })}
    </div>
  );
};
