import React, {useEffect, useState} from 'react';
import './DropdownSelect.styles.css';
import {DropdownSelectProps, OptionsList} from './DropdownSelect.props';
import {ReactComponent as CaretDown} from '../../../assets/images/caret-down.svg';
import {ReactComponent as CaretUp} from '../../../assets/images/caret-up.svg';
import {ReactComponent as Check} from '../../../assets/images/check-icon.svg';
import {Image} from '../../atoms/Image';
import {Text} from '../../atoms/Text';
import {Colors} from '../../../configs/colors';

export const DropdownSelectIncident: React.FC<DropdownSelectProps> = ({
  height = 'auto',
  width = 'auto',
  padding = '8px 20px',
  backgroundColor = '',
  borderColor = '',
  fontSizeTitle = 15,
  fontWeightTitle = '500',
  optionsList = [],
  selectImage = true,
  title = '',
  onSelected,
  textColor = '#000000',
  isResetOptionChecked,
}) => {
  const [showOptionList, setShowOptionList] = useState(false);
  const [selectedValue, setSelectedValue] = useState<OptionsList>();
  const [listContent, setListContent] = useState<OptionsList[]>([]);

  const defaultOption = (selectedOption?: OptionsList) => {
    const selectedItem: OptionsList | undefined = selectedOption;
    return (
      <div className="select-options-default">
        {renderPlaceHolder(selectedItem)}
        {selectedItem && (
          <>
            {selectImage && (
              <Image width={selectedItem.widthImgOption} url={selectedItem.urlImgOption} />
            )}
            <Text
              fontSize={fontSizeTitle}
              margin="0 0 0 15px"
              fontWeight={fontWeightTitle}
              color={textColor}
            >
              {selectedItem.name}
            </Text>
          </>
        )}
      </div>
    );
  };

  const renderPlaceHolder = (selectedItem: OptionsList | undefined) => {
    return (
      <>
        {selectImage && !selectedItem && (
          <Image width={optionsList[0].widthImgOption} url={optionsList[0].urlImgOption} />
        )}
        <div className={`select-placeholder ${selectedItem ? 'selected' : ''}`}>
          <Text
            fontSize={selectedItem ? 10 : 15}
            margin="0 0 0 15px"
            fontWeight={selectedItem ? '500' : '400'}
            color={selectedItem ? Colors.LSDarkTeal : Colors.NeutralGrey2}
          >
            {optionsList[0].name}
          </Text>
          <span className="star">*</span>
        </div>
      </>
    );
  };

  const viewContent = (option: OptionsList) => {
    return (
      <div className="select-options-default">
        {selectImage && <Image width={option.widthImgOption} url={option.urlImgOption} />}
        <Text fontSize={fontSizeTitle} margin="0 15px 0" fontWeight={fontWeightTitle}>
          {option.name}
        </Text>
        {option.check && (
          <div className="check-icon">
            <Check />
          </div>
        )}
      </div>
    );
  };

  const handleOptionClick = (option: OptionsList) => {
    setSelectedValue(option);
    onSelected(option.value);
    const arr = listContent.map((i: OptionsList) => {
      if (i.value !== option.value) {
        i.check = false;
      }
      return i;
    });
    setListContent(arr);
  };

  useEffect(() => {
    const arr = listContent.map((i: OptionsList) => {
      i.check = false;
      return i;
    });
    setListContent(arr);
  }, [isResetOptionChecked]);

  useEffect(() => {
    if (optionsList.length > 1) {
      const list = optionsList.slice(1, optionsList.length);
      setListContent(list);
    }
  }, [optionsList, isResetOptionChecked]);

  const onMouseOver = (option: OptionsList) => {
    const arr = listContent.map((i: OptionsList) => {
      if (i.value === option.value) {
        i.check = true;
      }
      return i;
    });
    setListContent(arr);
  };

  const onMouseOut = () => {
    const arr = listContent.map((i: OptionsList) => {
      if (i.value !== selectedValue?.value) {
        i.check = false;
      }
      return i;
    });
    setListContent(arr);
  };

  return (
    <div
      style={{width, height, backgroundColor, borderColor, padding}}
      className={`dropdown-select-style ${showOptionList ? 'active' : ''}`}
      onClick={() => setShowOptionList(!showOptionList)}
    >
      {title && <span className="dropdown-select-title">{title}</span>}
      {defaultOption(selectedValue)}
      <span className="dropdown-img">
        <> | </>
        {showOptionList ? (
          <CaretUp width={'30px'} height={'16px'} stroke={Colors.LSDarkTeal} />
        ) : (
          <CaretDown width={'30px'} height={'16px'} stroke={Colors.LSDarkTeal} />
        )}
      </span>
      {showOptionList && (
        <ul className={`select-options ${showOptionList ? 'active' : ''}`}>
          {listContent.map((option, index) => {
            return (
              <li
                className="custom-select-option"
                data-name={option.name}
                key={index}
                onClick={() => handleOptionClick(option)}
                onMouseOver={() => onMouseOver(option)}
                onMouseOut={onMouseOut}
              >
                {viewContent(option)}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
