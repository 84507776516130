import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {initiateDialoutCall} from '../api/interactions';

export interface PhoneNumber {
  countryCode: string;
  number: string;
}

export interface DialOutRequest {
  sessionId?: string,
  numberToDial: PhoneNumber;
}

export interface DialOutCallObjectState {
  data?: any;
  isLoading: boolean;
  error?: string;
}

const initialState: DialOutCallObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const initiateDialOutCall = createAsyncThunk('initiateDialOutCall', async (data: DialOutRequest) => {
  const response = await initiateDialoutCall(data);
  return response.lifestreamResponse.data;
});

const dialOutCallSlice = createSlice({
  name: 'dialOutCall',
  initialState,
  reducers: {
    setDialOutCallData: (state, action: PayloadAction<any | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initiateDialOutCall.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(initiateDialOutCall.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(initiateDialOutCall.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setDialOutCallData
} = dialOutCallSlice.actions;

export const dialOutCallDataSelector = (state: RootState) => state.dialOutCall;

export default dialOutCallSlice.reducer;
