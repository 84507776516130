import React, {useEffect} from 'react';
import {DetailSectionProps} from './DetailSection.props';
import {InfoPanel} from '../../molecules/InfoPanel';
import './DetailSection.styles.css';
import {CONSTANTS} from '../../../constants/constants';
import {InputLabel} from '../../molecules/InputLabel';
import {CheckBoxField} from '../../molecules/CheckBoxField';

export const DetailSection: React.FC<DetailSectionProps> = ({detailSection}) => {
  return (
    <div className="caller-overview-style" key={detailSection?.toString()}>
      <InfoPanel height="36px" width="100%" title={detailSection?.sectionHeading} />
      <div className="caller-overview">
        {detailSection?.fields.map(
          (ele: any, i: number) =>
            ele.display && (
              <div key={i.toString()} className="detail-subSection">
                {ele.dataType === 'P' ? (
                  <InputLabel
                    type="text"
                    disabledInput={true}
                    label={ele.title}
                    value={`${ele.value?.countryCode || ''} ${ele.value?.number || ''}`}
                    onChangeInput={() => {
                      null;
                    }}
                    widthInput="calc(100% - 116px)"
                  />
                ) : ele.dataType === 'MC' ? (
                  ele.selectionOptions.map((condition: any, index: number) => (
                    <div key={index} className="multipleOptionsItem">
                      <CheckBoxField
                        disabled={true}
                        value={ele.value && ele.value.includes(condition)}
                        title={condition}
                        onChange={() => {
                          null;
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <InputLabel
                    type="text"
                    disabledInput={true}
                    label={ele.title}
                    value={
                      ele.dataType === 'D'
                        ? ele.value
                          ? new Date(ele.value).toLocaleString('en-AU', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })
                          : null
                        : Array.isArray(ele.value)
                          ? ele.value.join(', ')
                          : ele.value
                    }
                    onChangeInput={() => {
                      null;
                    }}
                    widthInput={
                      (typeof ele.value === 'string' && ele.value.length > 40) ||
                (ele.dataType === 'AM' && ele.value?.length > 1)
                        ? '1000px'
                        : 'calc(100% - 116px)'
                    }
                  />
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
};
