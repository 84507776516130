import React, {useCallback, useEffect, useState} from 'react';
import {MapControlProps, MapType} from './MapControl.props';
import Map from '../../atoms/Map/Map';
import {Text} from '../../atoms/Text';
import {ReactComponent as VideoCamera} from '../../../assets/images/video-camera.svg';
import {ReactComponent as CCTV} from '../../../assets/images/cctv-round-icon.svg';
import {ReactComponent as ChatCircle} from '../../../assets/images/chat-circle-icon.svg';

import {Colors} from '../../../configs/colors';
import './MapControl.styles.css';
import {Icon} from '../../atoms/Icon';
import {TrackingTool} from '../../molecules/TrackingTool';
import {CONSTANTS} from '../../../constants/constants';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {
  userLocationFetch,
  userLocationHostoryFetch,
} from '../../../redux/userLocationSlice';
import {useLoadScript} from '@react-google-maps/api';
import appConfig from '../../../configs/appConfig.json';
import {StreetViewDialog} from '../../molecules/StreetViewDialog';
import {Image} from '../../atoms/Image';
import {Images} from '../../../configs/images';
import {setTrackingLocation} from '../../../redux/trackingSlice';
import {getTimeStamp} from '../../../utils/dateTimeHelper';
import {userTaskSelector} from '../../../redux/userTaskSlice';
import {MapLocation} from '../../atoms/Map';

const initialMapPosition = {
  lat: CONSTANTS.MAP_DEFAULT_LOCATION.Latitude,
  lng: CONSTANTS.MAP_DEFAULT_LOCATION.Longitude,
};

export const MapControl: React.FC<MapControlProps> = ({
  type,
  onChangeLocation,
  zoomLevel = CONSTANTS.MAP_ZOOMS.Locate,
  noOfVideoItems,
  noOfChatItems,
  noOfCctvItems,
  heightMap = '100%',
  tabName,
  path = [],
  mapMarkerTracking = [],
  onDoubleClickDot,
  coordinate,
  lastLocationInfo,
  subscriberId,
  fullscreenControl,
  isFullScreenControl,
  originLocationInfo,
  timeOfInteraction,
  dataContacts,
  onToggleInfo,
}) => {
  const dispatch = useAppDispatch();
  const userTask = useAppSelector(userTaskSelector);
  const [isTracking, setIsTracking] = useState<boolean>(false);
  const [isLocate, setIsLocate] = useState<boolean>(false);
  const [isLastKnown, setIsLastKnown] = useState<boolean>(false);
  const [map, setMap] = useState<any>(null);
  const [streetView, setStreetView] = useState<boolean>(false);
  const [streetViewAvailable, setStreetViewAvailable] =
    useState<boolean>(false);
  const [streetViewMessage, setStreetViewMessageVisible] =
    useState<boolean>(false);
  const [isFull, setIsFull] = useState<boolean>(false);
  const [streetViewService, setStreetViewService] =
    useState<google.maps.StreetViewService>();
  const [currentZoom, setCurrentZoom] = useState<number>(zoomLevel);
  const [positionCenter, setPositionCenter] =
    useState<MapLocation>(initialMapPosition);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isDrag, setIsDrag] = useState<boolean>(false);

  const onDirecting = () => {
    // Handle directing
  };

  const autoZoomAndPanto = () => {
    if (map && path.length > 0) {
      map.panTo(path[path.length - 1]);
      map.setZoom(CONSTANTS.MAP_ZOOMS.Locate);
      map.setCenter(path[path.length - 1]);
      setPositionCenter(path[path.length - 1]);
    }
  };

  const fitBound = () => {
    if (path.length > 0) {
      let noFitBound = false;
      const newBounds = new google.maps.LatLngBounds();
      const currentBounds = map.getBounds();
      const locationsInBound = [];

      path.forEach((positions) => {
        if (positions.lat && positions.lng) {
          const location = new google.maps.LatLng(positions.lat, positions.lng);
          if (!currentBounds.contains(location)) {
            noFitBound = true;
          }
          newBounds.extend(location);
          currentBounds.extend(location);
          locationsInBound.push(location);
        }
      });

      if (locationsInBound.length < 2) {
        autoZoomAndPanto();
      } else if (noFitBound) {
        map.fitBounds(newBounds, 30);
      } else {
        map.fitBounds(currentBounds, 0);
      }
    }
  };

  useEffect(() => {
    if ((isLastKnown || isTracking) && !userTask.isConnected) {
      fitBound();
      setIsBlocked(true);
    } else if (userTask.isConnected && (isLastKnown || isTracking) && !isDrag) {
      autoZoomAndPanto();
      setIsBlocked(true);
    } else if (
      userTask.isConnected &&
      !isLastKnown &&
      !isTracking &&
      path.length > 0 &&
      !isBlocked
    ) {
      setIsBlocked(true);
      setPositionCenter(path[0]);
    }
  }, [isLastKnown, path, isTracking]);

  const onLastKnown = () => {
    setIsLastKnown(!isLastKnown);
    if (!isLastKnown) {
      subscriberId && dispatch(userLocationFetch(subscriberId));
      setIsDrag(false);
      setIsBlocked(true);
    } else {
      if (
        timeOfInteraction &&
        timeOfInteraction.endTime &&
        !userTask.isConnected
      ) {
        dispatch(
          userLocationHostoryFetch({
            subscriberId: subscriberId,
            startTime: getTimeStamp(timeOfInteraction.startTime),
            endTime: getTimeStamp(timeOfInteraction.endTime),
          })
        );
      }
    }
  };

  const onLocate = () => {
    setIsLocate(!isLocate);
    if (map && path.length > 0) {
      map.panTo(path[0]);
      map.setZoom(CONSTANTS.MAP_ZOOMS.Locate);
      map.setCenter(path[0]);
      setPositionCenter(path[0]);
    }
    if (userTask.isConnected) {
      autoZoomAndPanto();
      setIsDrag(false);
    }
  };

  const handleTracking = () => {
    setIsTracking(!isTracking);
    if (!isTracking) {
      setIsBlocked(true);
      setIsDrag(false);
    }
    const payload = {
      isTracking: !isTracking,
      map,
    };
    dispatch(setTrackingLocation(payload));
  };

  const onStreetViewButtonClick = () => {
    if (streetViewAvailable) {
      setStreetView(!streetView);
    } else {
      setStreetViewMessageVisible(true);
    }
  };

  useEffect(() => {
    if (streetViewService && path.length > 0) {
      streetViewService.getPanorama(
        {
          location: path[path.length - 1],
          radius: 50,
        },
        (data: any, status: string) => {
          setStreetViewAvailable(status === 'OK');
        }
      );
    }
    if (!isBlocked && path.length > 0 && !userTask.isConnected) {
      setPositionCenter(path[0]);
    }
  }, [path]);

  const onLoadStreetView = (streetViewService: any) => {
    setStreetViewService(streetViewService);
  };

  type Libraries = (
    | 'drawing'
    | 'geometry'
    | 'localContext'
    | 'places'
    | 'visualization'
  )[];
  const libraries: Libraries = ['places'];
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: appConfig.Google.apiKey,
    libraries,
  });

  const onZoomChanged = () => {
    if (map) {
      setCurrentZoom(map.getZoom());
    }
  };

  const onZoomResetClick = () => {
    setStreetView(false);
    if (currentZoom <= CONSTANTS.MAP_ZOOMS.QuickZoom) {
      map.setZoom(CONSTANTS.MAP_ZOOMS.Locate);
      if (map && path.length > 0) {
        map.panTo(path[0]);
        map.setCenter(path[0]);
      }
    } else {
      map.setZoom(CONSTANTS.MAP_ZOOMS.QuickZoom);
      map.panTo(path[0]);
      map.setCenter(path[0]);
    }
  };

  const onDrag = useCallback(() => {
    if (userTask.isConnected) {
      setIsDrag(true);
    }
  },[]);

  return (
    <div
      className={`map-control map-container-detail ${
        isFull ? 'full-screen' : ''
      } ${streetView ? 'map-street-view' : ''}`}
    >
      {isLoaded ? (
        <Map
          tabName={tabName}
          mapCenter={
            type === MapType.Large ? positionCenter : initialMapPosition
          }
          dataContacts={dataContacts}
          zoomLevel={zoomLevel}
          width="100%"
          height={heightMap}
          path={path}
          mapMarkerTracking={mapMarkerTracking}
          onDoubleClickDot={onDoubleClickDot}
          onChangeLocation={onChangeLocation}
          coordinate={coordinate}
          lastLocationInfo={lastLocationInfo}
          originLocationInfo={originLocationInfo}
          isTracking={isTracking}
          isLocate={isLocate}
          isLastKnown={isLastKnown}
          onLoad={(map) => setMap(map)}
          streetView={streetView}
          streetViewOffCallback={() => setStreetView(false)}
          fullscreenControl={fullscreenControl}
          onLoadStreetview={onLoadStreetView}
          onZoomChanged={onZoomChanged}
          onToggleInfo={onToggleInfo}
          onDrag={onDrag}
        />
      ) : (
        <>Loading ...</>
      )}
      {type === MapType.Large && (
        <>
          {streetViewMessage && (
            <StreetViewDialog
              closeCallback={() => setStreetViewMessageVisible(false)}
            />
          )}
          <div className="map-custom">
            <div className={`tracking-tool ${tabName}`}>
              <TrackingTool
                tabName={tabName}
                onDirecting={onDirecting}
                onLastKnown={onLastKnown}
                onLocate={onLocate}
                onTracking={handleTracking}
                isTracking={isTracking}
                isLocate={isLocate}
                isLastKnown={isLastKnown}
              />
            </div>
            {isFullScreenControl && (
              <div
                className="custom-full-map"
                onClick={() => setIsFull(!isFull)}
              >
                <Image
                  width="16px"
                  height="16px"
                  url={isFull ? Images.MiniGoogleMap : Images.MapFullScreen}
                />
              </div>
            )}
          </div>
          <div className="street-view-button">
            <Image
              width="20px"
              height="20px"
              url={streetView ? Images.MapTrifold : Images.PegMan}
              onClick={onStreetViewButtonClick}
            />
          </div>
          <div className="map-zoom-button">
            <Image
              width="17px"
              height="17px"
              url={
                currentZoom <= CONSTANTS.MAP_ZOOMS.QuickZoom
                  ? Images.MapIcon
                  : Images.AustraliaMap
              }
              onClick={onZoomResetClick}
            />
          </div>
        </>
      )}
      {type === MapType.Small && (
        <div className="map-stats">
          <Text
            fontSize={14}
            fontWeight="50"
            color={Colors.LSWhite}
            fontFamily="Inter"
          >
            Last 24hrs:
          </Text>
          <div className="image-text-style">
            <Icon
              backgroundColor={Colors.RCSuccessBG}
              iconColor={Colors.RCSuccess}
              IconImage={VideoCamera}
            />
            <Text
              fontSize={14}
              fontWeight="600"
              color={Colors.LSWhite}
              fontFamily="Inter"
              margin="5px"
            >
              {noOfVideoItems}
            </Text>
          </div>
          <div className="image-text-style">
            <Icon
              backgroundColor={Colors.RCAlertBG}
              iconColor={Colors.RCAlert}
              IconImage={ChatCircle}
            />
            <Text
              fontSize={14}
              fontWeight="600"
              color={Colors.LSWhite}
              fontFamily="Inter"
              margin="5px"
            >
              {noOfChatItems}
            </Text>
          </div>
          <div className="image-text-style">
            <Icon
              backgroundColor={Colors.RCCriticalBG}
              iconColor={Colors.RCCritical}
              IconImage={CCTV}
            />
            <Text
              fontSize={14}
              fontWeight="600"
              color={Colors.LSWhite}
              fontFamily="Inter"
              margin="5px"
            >
              {noOfCctvItems}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};
