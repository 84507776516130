import {DataContacts} from '../../../pages/Caller';

export interface TelephoneNumber {
  number: string;
  countryCode: string;
}

export interface MobileCallControlProps {
  variant: MobileCallControlVariant;
  isHold?: boolean;
  isCamera?: boolean;
  isDeviceCamera?: boolean;
  isDeviceMic?: boolean;
  deviceVolume?: number;
  onHoldClick?: () => void;
  onVideoClick?: () => void;
  onAddClick?: (number?:TelephoneNumber) => void;
  onEndClick?: () => void;
  onCameraFlipClick?: () => void;
  onDeviceVideoClick?: () => void;
  onMicrophoneClick?: () => void;
  onSpeakerClick?: () => void;
  onShowDevice?: (value: boolean) => void
  isDeviceControlTextVisible?: boolean;
  emergencyContacts?: DataContacts[];
  isCallEndButtonDisabled?: boolean;
  isHoldButtonDisabled?: boolean;
  isAddCallButtonDisabled?: boolean;
}

export enum MobileCallControlVariant {
  AudioCall = 'audio',
  VideoCall = 'video',
  VideoCallDeviceControl = 'VideoDeviceControl'
}
