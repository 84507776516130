import React from 'react';
import ReactDOM from 'react-dom';
import '../src/configs/global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import appConfig from './configs/appConfig.json';

const msalInstance = new PublicClientApplication(appConfig.msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
