import {OverlayView} from '@react-google-maps/api';
import React from 'react';
import {MakerType, MarkerInfoProps} from '.';
import {Colors} from '../../../../configs/colors';
import {MapMarkerInfo} from '../../../organisms/MapMarkerInfo';
import {CircleImage} from '../../CircleImage';
import {MapMarker} from '../../MapMarker';
import './MarkerInfo.styles.css';
import {Text} from '../../../atoms/Text';
import {Image} from '../../../atoms/Image';
import ClockIcon from '../../../../assets/images/icon-clock-counter-clockwise-inactive.svg';

export const MarkerInfo: React.FC<MarkerInfoProps> = ({
  locationInfo,
  path,
  stroke,
  isShowBroadcast = true,
  isShowTime = true,
  type = MakerType.Full,
}) => {
  return (
    <OverlayView position={path} mapPaneName={OverlayView.MAP_PANE}>
      {type === MakerType.Full ? (
        <>
          <MapMarker stroke={stroke} isShowBroadcast={isShowBroadcast} />
          <MapMarkerInfo
            isShowTime={isShowTime}
            imageUrl={locationInfo?.imageUrl || ''}
            nameText={locationInfo?.fullName}
            dateText={locationInfo?.date}
            timeText={locationInfo?.time}
          ></MapMarkerInfo>
        </>
      ) : (
        <div className="current-location-marker">
          <div className="current-location-info">
            <div className="circle-image">
              <CircleImage
                url={locationInfo?.imageUrl || ''}
                borderColor={Colors.LSWhite}
                size="50px"
                borderWidth="3px"
              />
            </div>
            <div className="current-time">
              <Image url={ClockIcon} width="12px" margin="2px" />
              <div className="time-text-value">
                <Text fontSize={12} color={Colors.NeutralGrey2}>{locationInfo?.time}</Text>
              </div>
            </div>
            <div className="pointer"></div>
          </div>
          <MapMarker stroke={stroke} isShowBroadcast={false} />
        </div>
      )}
    </OverlayView>
  );
};
