import React from 'react';
import {Colors} from '../../../configs/colors';
import {Button, ButtonVariant} from '../../atoms/Button';
import {Text} from '../../atoms/Text';
import {CompleteProcedureOptionsProps} from './CompleteProcedureOptions.props';
import './CompleteProcedureOptions.styles.css';
import {ReactComponent as CircleDashed} from '../../../assets/images/circle-dashed.svg';

export const CompleteProcedureOptions: React.FC<CompleteProcedureOptionsProps> = ({onCompleteClick}) => {
  return <div className="complete-procedure-options-wrap">
    <div className="complete-procedure-options-text">
      <Text color={Colors.LSWhite} margin="0px 0px 10px" fontWeight="600" fontSize={16}>Press Complete once procedure has been completed</Text>
      <Text color={Colors.LSWhite} fontSize={15}>You cannot take incoming calls until completed.</Text>
    </div>
    <div className="complete-procedure-options-button">
      <Button variant={ButtonVariant.Icon} onClick={onCompleteClick} color={Colors.LSDarkTeal} margin="5px">
        <CircleDashed stroke={Colors.LSWhite} width={40} height={40} />
      </Button>
      <Text color={Colors.LSWhite} fontSize={12}>Complete</Text>
    </div>
  </div>;
};