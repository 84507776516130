import React, {useEffect, useState} from 'react';
import {TableRowProps} from './TableRow.props';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image/Image';
import {Tooltip} from '../../atoms/Tooltip/Tooltip';
import {ReactComponent as RightArrow} from '../../../assets/images/caret-right.svg';
import './TableRow.styles.css';
import {CONSTANTS} from '../../../constants/constants';
import {Colors} from '../../../configs/colors';
import {Images} from '../../../configs/images';
import {getWaitingSeconds, getNameInIntials} from '../../../utils/dataPreparationHelper';

const styleRow = {
  active: {
    background: Colors.LSTealBackground,
    border: Colors.LSDarkTeal,
    color: Colors.LSWhite,
    backgroundCallOp: Colors.LSDarkTeal,
  },
  incoming: {
    background: 'linear-gradient(0deg, rgba(220, 53, 69, 0.1), rgba(220, 53, 69, 0.1)), #FFFFFF',
    border: Colors.RCCritical,
    color: Colors.LSWhite,
    backgroundCallOp: Colors.RCCritical,
  },
  completed: {
    background: Colors.LSWhite,
    border: Colors.NeutralGrey4,
    color: Colors.LSBlack,
    backgroundCallOp: Colors.NeutralGrey4,
  },
};

export const TableRow: React.FC<TableRowProps> = ({
  rowData,
  showAdditionalData = false
}) => {
  const [waitingSeconds, setWaitingSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWaitingSeconds(value => value + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getIcon = () => {
    switch (rowData.type) {
    case CONSTANTS.CALL_TYPES.VOICE_CALL:
    case CONSTANTS.CALL_OUT_CHANNELS.PSTNOut:
      return Images.ImgIncomingCall;
    case CONSTANTS.CALL_TYPES.VIDEO_CALL:
    case CONSTANTS.CALL_OUT_CHANNELS.SOSVideoCallOut:
      return Images.VideoIcon;
    case CONSTANTS.CALL_TYPES.WATCH_ME:
      return Images.WatchMeIcon;
    case CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN:
      return Images.AlertIcon;
    case CONSTANTS.CALL_TYPES.MESSAGE:
    case CONSTANTS.CALL_OUT_CHANNELS.ChatOut:
      return Images.MessageIcon;
    case CONSTANTS.CALL_TYPES.CCTV_CALL:
      return Images.CCTVIcon;
    default:
      return Images.VideoIcon;
    }
  };

  const getCallState = () => {
    switch (rowData.state) {
    case CONSTANTS.INTERACTION_SUBSTATUS.InProgress:
      return styleRow.active;
    case CONSTANTS.INTERACTION_SUBSTATUS.Landed:
      return styleRow.incoming;
    case CONSTANTS.INTERACTION_SUBSTATUS.Ended:
      return styleRow.completed;
    default:
      return styleRow.completed;
    }
  };

  const getCallStateRow = getCallState();

  return (
    <div className="table-row-content" style={{background: getCallStateRow.background, borderColor: getCallStateRow.border}}>
      <div className="header-title type">        
        {showAdditionalData ?
          <Tooltip position="right" content={`Main Status:${rowData.mainStatus}, SubStatus: ${rowData.state}`}><Image url={getIcon()} width="24px" /></Tooltip> :
          <Image url={getIcon()} width="24px" />
        }        
      </div>
      <div className="header-title from">
        <Text fontSize={14} color={Colors.NeutralBlack}><Tooltip position="top" content={rowData.from || ''}><div className="ellipsis">{rowData.from}</div></Tooltip></Text>
      </div>
      <div className="header-title contact">
        <Text fontSize={14} color={Colors.NeutralBlack}><Tooltip position="top" content={rowData.contact || ''}><div className="ellipsis">{rowData.contact}</div></Tooltip></Text>
      </div>
      <div className="header-title location">
        <Text fontSize={14} color={Colors.NeutralBlack}><Tooltip position="top" content={rowData.location || ''}><div className="ellipsis">{rowData.location}</div></Tooltip></Text>
      </div>
      <div className="header-title received">
        <Text fontSize={14} color={Colors.NeutralBlack}><Tooltip position="top" content={rowData.received || ''}><div className="ellipsis">{rowData.received}</div></Tooltip></Text>
      </div>
      <div className="header-title duration">
        <Text fontSize={14} color={Colors.NeutralBlack}>
          <Tooltip position="top" content={rowData.duration || ''}><div className="ellipsis">{rowData.duration}</div></Tooltip>
        </Text>
      </div>
      <div className="call-op" style={{color: getCallStateRow.color, background: getCallStateRow.backgroundCallOp}}>
        {
          // CONSTANTS.CALL_STATE.INCOMING === CONSTANTS.CALL_STATE.INCOMING ?
          //   <Tooltip position="top" content={rowData.timer?.toString() || ''}>
          //     <div className="ellipsis">{waitingSeconds}</div>
          //   </Tooltip> : <div className="ellipsis">{getNameInIntials(rowData.callOp)}</div>
        }
        <div key={waitingSeconds} className="ellipsis">{rowData.state === 'LANDED' ? getWaitingSeconds(rowData.received) : rowData.status === 'open' ? getNameInIntials(rowData.callOp) : 'N/A'}</div>
      </div>
      <div>
        <RightArrow stroke={rowData.state === CONSTANTS.CALL_STATE.ACTIVE ? Colors.NeutralGrey3 : Colors.LSDarkTeal} width={16} height={16} />
      </div>
    </div>
  );
};