/* eslint-disable @typescript-eslint/no-explicit-any */
import {CONSTANTS} from '../constants/constants';
import {HttpMethod, httpClient} from './httpClient';

export const getLastLocation = (subscriberId: string): Promise<any> => {
  return httpClient(`/locations/getlastlocation?subscriberId=${subscriberId}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.LOCATIONS);
};

export const getLocationInTimePeriod = (subscriberId: string, startTime: number, endTime: number): Promise<any> => {
  return httpClient(`/locations/getlocationsintimeperiod?subscriberId=${subscriberId}&startTime=${startTime}&endTime=${endTime}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.LOCATIONS);
};

export const getLastKnownLocation = (subscriberIds: string): Promise<any> => {
  return httpClient(`/locations/tracking/lastknownlocation/${subscriberIds}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.LOCATIONS);
};

export const getUserLocations = (subscriberIds: string): Promise<any> => {
  return httpClient(`/locations/tracking/users/${subscriberIds}`, HttpMethod.Get, null, CONSTANTS.SERVICE_TYPES.LOCATIONS);
};
