import {ReactNode} from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  ButtonImage = 'button-image',
  Cancel = 'cancel',
  OnlyImage = 'only-image',
  TableRow = 'table-row',
  Profile = 'profile',
  Icon = 'icon',
  Round = 'round',
  Square = 'square',
  ButtonImageRound = 'button-image-round'
}

export interface ButtonProps {
  children?: ReactNode | string;
  text?: string;
  margin?: string;
  onClick?: () => void;
  color?: string;
  width?: string;
  height?: string;
  variant: string;
  textColor?: string
  disabled?: boolean;
  fontSize?: number;
  fontWeight?: number;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
}
