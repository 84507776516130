import React, {useEffect, useState} from 'react';
import {CallerDetailsProps} from './CallerDetails.props';
import {InfoPanel} from '../../molecules/InfoPanel';
import './CallerDetails.styles.css';
import {CONSTANTS} from '../../../constants/constants';
import {InputLabel} from '../../molecules/InputLabel';

export const CallerDetails: React.FC<CallerDetailsProps> = ({
  callerDetails
}) => {
  // const [isEdit, setIsEdit] = useState<boolean>(true);
  const [homeNo, setHomeNo] = useState<string>(callerDetails.home || '');
  const [mobileNo, setMobileNo] = useState<string>(callerDetails.mobile || '');
  const [workNo, setWorkNo] = useState<string>(callerDetails.work || '');
  const [email, setEmail] = useState<string>(callerDetails.email || '');
  const [address, setAddress] = useState<string>(callerDetails.address || '');

  const onChangeInput = (event: any, type: string) => {
    switch (type) {
    case CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.HOME:
      callerDetails.home = event.target.value;
      setHomeNo(event.target.value);
      break;
    case CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.MOBILE:
      callerDetails.mobile = event.target.value;
      setMobileNo(event.target.value);
      break;
    case CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.WORK:
      callerDetails.work = event.target.value;
      setWorkNo(event.target.value);
      break;
    case CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.EMAIL:
      callerDetails.email = event.target.value;
      setEmail(event.target.value);
      break;
    case CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.ADDRESS:
      callerDetails.address = event.target.value;
      setAddress(event.target.value);
      break;

    default:
      break;
    }
    console.log(callerDetails);
    // use callerDetails to submit data
  };

  useEffect(() => {
    setHomeNo(callerDetails.home??'');
    setMobileNo(callerDetails.mobile??'');
    setWorkNo(callerDetails.work??'');
    setEmail(callerDetails.email??'');
    setAddress(callerDetails.address??'');
  }, [callerDetails]);

  return (
    <div className="caller-detail-style">
      <InfoPanel width="100%" title={CONSTANTS.CALLERS.CALLER_DETAILS} />
      <div className="caller-detail-phone">
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_DETAILS.LABEL.MOBILE}
          value={mobileNo}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.MOBILE)}
          widthInput="calc(100% - 106px)"
          widthInputLabel="90px"
          textColor={true}
        />
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_DETAILS.LABEL.HOME}
          value={homeNo}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.HOME)}
          textColor={true}
          widthInput="calc(100% - 106px)"
          widthInputLabel="90px"
        />
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_DETAILS.LABEL.WORK}
          value={workNo}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.WORK)}
          widthInput="calc(100% - 106px)"
          widthInputLabel="90px"
          textColor={true}
        />
      </div>
      <div className="caller-detail-address">
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_DETAILS.LABEL.ADDRESS}
          value={address}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.ADDRESS)}
          widthInput="calc(100% - 106px)"
          widthInputLabel="90px"
        />

      </div>
      <div className="caller-detail-email">
        <InputLabel
          type="text"
          disabledInput={true}
          label={CONSTANTS.CALLER_DETAILS.LABEL.EMAIL}
          value={email}
          onChangeInput={(event) => onChangeInput(event, CONSTANTS.CALLER_DETAILS.TYPE_CHANGE.EMAIL)}
          widthInput="calc(100% - 106px)"
          widthInputLabel="90px"
          textColor={true}
        />
      </div>
    </div>
  );
};
