/* eslint-disable react/jsx-key */
import React, {useEffect, useState} from 'react';
import './Caller.styles.css';
import {Colors} from '../../configs/colors';
import appConfig from '../../configs/appConfig.json';
import {ReactComponent as CaretDown} from '../../assets/images/caret-down.svg';
import {ReactComponent as CaretUp} from '../../assets/images/caret-up.svg';
import {ReactComponent as Info} from '../../assets/images/info-icon.svg';
import {TableHeader} from '../../components/molecules/TableHeader';
import {CONSTANTS} from '../../constants/constants';
import {Images} from '../../configs/images';
import {Text} from '../../components/atoms/Text';
import {Image} from '../../components/atoms/Image';
import {Tooltip} from '../../components/atoms/Tooltip/Tooltip';
import {ReactComponent as RightArrow} from '../../assets/images/caret-right.svg';
import {HistoryAndNotesProps} from '.';
import {IncidentInteractionHistory, InteractionHistory} from './Caller.props';
import {SortDirection} from '../../components/molecules/SearchResult';
import {getNameInIntials} from '../../utils/dataPreparationHelper';
import {CheckBox} from '../../components/atoms/CheckBox';
import {useAppSelector} from '../../redux/hooks';
import {interactionHistorySelector} from '../../redux/interactionHistorySlice';
const rotate: { [key: string]: SortDirection } = {asc: 'desc', desc: 'asc'};

export const HistoryAndNotes: React.FC<HistoryAndNotesProps> = ({
  setTabName,
  onClickRowShowNotes,
  showNotes,
  subscriberId = '',
  interactionHistory,
  selectedInteractionId = '',
  onDoubleClickRow
}) => {
  const interactionsHistorySelect = useAppSelector(interactionHistorySelector);
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [interactionHistoryData, setInteractionHistoryData] = useState<InteractionHistory[] | any>([]);
  const [isAdditionalDataEnable, setAdditionalDataEnableStatus] = useState<boolean>(false);

  const getIcon = (type: string) => {
    switch (type) {
    case CONSTANTS.CALL_TYPES.VOICE_CALL:
      return Images.ImgIncomingCall;
    case CONSTANTS.CALL_TYPES.VIDEO_CALL:
      return Images.VideoIcon;
    case CONSTANTS.CALL_TYPES.WATCH_ME:
      return Images.WatchMeIcon;
    case CONSTANTS.CALL_TYPES.MESSAGE:
      return Images.MessageIcon;
    case CONSTANTS.CALL_OUT_CHANNELS.ChatOut:
      return Images.MessageIcon;
    case CONSTANTS.CALL_OUT_CHANNELS.SOSVideoCallOut:
      return Images.VideoIcon;
    case CONSTANTS.CALL_OUT_CHANNELS.PSTNOut:
      return Images.ImgIncomingCall;
    case CONSTANTS.CALL_TYPES.CCTV_CALL:
      return Images.CCTVIcon;
    case CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN:
      return Images.AlertIcon;
    default:
      return Images.VideoIcon;
    }
  };

  const getIconStatus = (status: string) => {
    switch (status) {
    case CONSTANTS.CALL_STATUS.OPEN:
      return Images.ImgGreenDot;
    case CONSTANTS.CALL_STATUS.CLOSED:
      return Images.ImgRedDot;
    case CONSTANTS.CALL_STATUS.BUSY:
      return Images.ImgRedDot;

    default:
      return Images.ImgGreenDot;
    }
  };

  const onRotate = () => {
    setSortDirection(rotate[sortDirection]);
    const sortedList = interactionHistoryData.reverse();
    setInteractionHistoryData([...sortedList]);
  };

  const renderSort = (direction: SortDirection) => {
    switch (direction) {
    case 'desc':
      return (
        <CaretDown stroke={Colors.LSDarkTeal} width={16} height={16} onClick={() => onRotate()}/>
      );
    case 'asc':
      return (
        <CaretUp stroke={Colors.LSDarkTeal} width={16} height={16} onClick={() => onRotate()}/>
      );
  
    default:
      return (
        <CaretDown stroke={Colors.LSDarkTeal} width={16} height={16} onClick={() => onRotate()}/>
      );
    }
  };

  const getClassName = (data: InteractionHistory) => {
    let className = '';
    if(data.interactionId == selectedInteractionId && !data.interactionsForIncident){
      className = 'active';
    }
    if (data.interactionsForIncident) {
      className = 'row-incident';
    }

    return className;
  };

  const dataHeader = [
    // Commenting service column since the service data is not returned from the API
    // {
    //   type: 'Service',
    //   showIcon: false,
    //   widthType: '8%',
    // },
    {
      type: 'Type',
      showIcon: false,
      widthType: '5%',
    },
    {
      type: 'Contact',
      showIcon: false,
      widthType: '12%',
    },
    {
      type: 'Location',
      showIcon: false,
      widthType: '35%',
    },
    {
      type: 'Received',
      showIcon: true,
      widthType: '15%',
      Icon: renderSort(sortDirection),
    },
    {
      type: 'Duration',
      showIcon: false,
      widthType: '10%',
    },
    {
      type: '',
      showIcon: true,
      Icon: (
        <div className="info-icon">
          <Info stroke={Colors.LSBlack} width={18} height={18} />
        </div>
      ),
      widthType: '10%',
    },
    {
      type: 'Call Ref',
      showIcon: false,
      widthType: '13%',
    },
  ];

  const rowClickEvent = (data: InteractionHistory, index: number, type: string, indexForParent?: number) => {
    localStorage.setItem('subscriberId', data.subscriberId);
    localStorage.setItem('interactionId', data.interactionId);
    onClickRowShowNotes(data,false);
    const elms = document.querySelectorAll('.row-container');
    if (type === 'parent') {
      Array.from(elms).map((elem, idx: number) => {
        if (idx === index) {
          Array.from(elem.children).map((childEl, childIdx) => {
            if (childIdx === 0) {
              childEl.classList.add('active');
            } else {
              childEl.classList.remove('active');
            }
          });
        } else {
          Array.from(elem.children).map(el => el.classList.remove('active'));
        }
      });
    } else {
      Array.from(elms).map((elem, idx: number) => {
        if (idx === indexForParent) {
          Array.from(elem.children).map((childEl, childIdx) => {
            if (index + 1 === childIdx) {
              childEl.classList.add('active');
            } else {
              childEl.classList.remove('active');
            }
          });
        } else {
          Array.from(elem.children).map(el => el.classList.remove('active'));
        }
      });
    }
  };

  useEffect(() => {
    if (interactionHistory.length > 0) {
      const interactionsList = interactionHistory.map((i:IncidentInteractionHistory) => i.interactions);
      const dataRepair = interactionsList.map((item)=> {
        if (item.length > 1) {
          const parentInteractions = item.slice(0, 1 );
          const childInteractions = item.slice(1, item.length);
          parentInteractions.unshift({
            interactions: childInteractions,
            interactionId: parentInteractions[0].interactionId,
            incidentId: parentInteractions[0].incidentId,
            service: '',
            type: parentInteractions[0].type,
            contact: parentInteractions[0].contact,
            location: parentInteractions[0].location,
            received: parentInteractions[0].received,
            status: parentInteractions[0].status,
            subStatus: parentInteractions[0].subStatus,
            callRef: parentInteractions[0].callRef,
            callOp: parentInteractions[0].callOp,
            interactionStartDateTime: parentInteractions[0].interactionStartDateTime,
            interactionEndDateTime: parentInteractions[0].interactionEndDateTime,
            subscriberId: parentInteractions[0].subscriberId,
            externalSessionData: parentInteractions[0].externalSessionData,
            duration: parentInteractions[0].duration,
            resolution: parentInteractions[0].resolution,
            notes: parentInteractions[0].notes,
            interactionsForIncident: false,
          });
          return [parentInteractions.shift()];
        } else if (item.length === 1) {
          return item.map(i => ({...i, interactionsForIncident: false}));
        }
        return item;
      });

      const interactions = dataRepair.reduce((arr, e) => arr.concat(e));
      setInteractionHistoryData(interactions);
    }
  }, [interactionHistory]);

  const renderRow = (call: InteractionHistory, index: number, type: string, indexForParent?: number) => {
    return (
      <div
        key={index}
        className={`table-row-content ${getClassName(call)}`}
        onClick={() => rowClickEvent(call, index, type, indexForParent)}
        onDoubleClick={() => onDoubleClickRow && onDoubleClickRow(call)}
      >
        <div className="header-title type">
          <Image url={getIcon(call.type || '')} width="20px" />
        </div>
        <div className="header-title contact">
          <Text fontSize={14} color={Colors.NeutralBlack}>
            <Tooltip
              position={`${index === 0 && type !=='child' ? 'bottom' : 'top'}`}
              content={call.contact || ''}
            >
              <div className="ellipsis">{call.contact}</div>
            </Tooltip>
          </Text>
        </div>
        <div className="header-title location">
          <Text fontSize={14} color={Colors.NeutralBlack}>
            <Tooltip
              position={`${index === 0 && type !=='child' ? 'bottom' : 'top'}`}
              content={call.location || ''}
            >
              <div className="ellipsis">{call.location}</div>
            </Tooltip>
          </Text>
        </div>
        <div className="header-title received">
          <Text fontSize={14} color={Colors.NeutralBlack}>
            <Tooltip
              position={`${index === 0 && type !=='child' ? 'bottom' : 'top'}`}
              content={call.received || ''}
            >
              <div className="ellipsis">{call.received}</div>
            </Tooltip>
          </Text>
        </div>
        <div className="header-title duration">
          <Text fontSize={14} color={Colors.NeutralBlack}>{call.duration}</Text>
          {isAdditionalDataEnable ?
            <Tooltip
              position={`${index === 0 && type !== 'child' ? 'bottom' : 'top'}`}
              content={call.subStatus || ''}
            >
              <Image url={getIconStatus(call.status || '')} width="20px" />
            </Tooltip> :
            <Image url={getIconStatus(call.status || '')} width="20px" />}
        </div>
        <div className="header-title info">
          <Text fontSize={14} color={Colors.NeutralBlack}>
            <Tooltip
              position={`${index === 0 && type !=='child' ? 'bottom' : 'top'}`}
              content={call.callOp || ''}
            >
              <div className="ellipsis">{getNameInIntials(call.callOp)}</div>
            </Tooltip>
          </Text>
        </div>
        <div className="header-title call-ref">
          <Text fontSize={14} color={Colors.NeutralBlack}>{call.callRef}</Text>
          <RightArrow onClick={() => onClickRowShowNotes(call, true)} stroke={Colors.LSDarkTeal} width={16} height={16} />
        </div>
      </div>
    );
  };

  const renderAllRow = (call: InteractionHistory, index: number) => {
    return (
      <div className="row-container" key={index}>
        {renderRow(call, index, 'parent')}
        {call.interactions.map((inter: InteractionHistory, idx: number) =>
          renderRow(inter, idx, 'child', index)
        )}
      </div>
    );

  };

  const onCheckBoxChange = (value: boolean) => {
    setAdditionalDataEnableStatus(value);
    console.log(value);
  };

  return (
    <div className="history-notes-style">
      {appConfig.Debug.lifestreamData === 'true' && <div className="data-toggle">
        <span>Show Additional Data</span>
        <CheckBox value={false} onChange={onCheckBoxChange}></CheckBox>
      </div>}
      {interactionsHistorySelect.data === undefined ? <div className="interaction-history-spinner-wrap">
        <Image width="150px" url={Images.Spinner} />
      </div> : <div className="history-notes-table scroll-custom">
        <TableHeader dataHeader={dataHeader} />
        <div className="table-content">
          {interactionHistoryData?.map((call: InteractionHistory, index: number) =>
            renderAllRow(call, index)
          )}
        </div>
      </div>}
    </div>
  );
};
