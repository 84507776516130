import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {updateInteractionsStatus} from '../api/interactions';

export interface InteractionStatusUpdateObjectState {
  data?: any;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionStatusUpdateObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const interactionStatusUpdate = createAsyncThunk('interactionStatusUpdate/ById', async (interactionStatusParams: any) => {
  const response = await updateInteractionsStatus(interactionStatusParams.InteractionId, interactionStatusParams.MainStatus, interactionStatusParams.SubStatus);
  return response.lifestreamResponse.data;
});

const interactionStatusUpdateSlice = createSlice({
  name: 'interactionStatusUpdate',
  initialState,
  reducers: {
    setInteractionStatusUpdateData: (state, action: PayloadAction<any | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(interactionStatusUpdate.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionStatusUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload as any;
      state.error = undefined;
    });

    builder.addCase(interactionStatusUpdate.rejected, (state, action) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInteractionStatusUpdateData
} = interactionStatusUpdateSlice.actions;

export const interactionStatusUpdateSelector = (state: RootState) => state.interactionStatusUpdate;

export default interactionStatusUpdateSlice.reducer;
