import React from 'react';
import {MapDotsTrackingProps} from '.';
import './MapDotsTracking.styles.css';
import {CONSTANTS} from '../../../constants/constants';
import {Marker} from '@react-google-maps/api';
import {Images} from '../../../configs/images';

export const MapDotsTracking: React.FC<MapDotsTrackingProps> = ({
  mapMarkerTracking = [],
  onDoubleClickDot
}) => {

  const getIconFlash = (callerType: string) => {
    switch (callerType) {
    case CONSTANTS.CALL_TYPES.SOS_VIDEO_CALL:
      return Images.GreenFlash;
    case CONSTANTS.CALL_TYPES.MESSAGE:
      return Images.OrangeFlash;
    case CONSTANTS.CALL_TYPES.CCTV_CALL:
      return Images.RedFlash;
    case CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN:
      return Images.BlueFlash;

    default:
      return Images.GreenFlash;
    }
  };

  return (
    <>
      {
        mapMarkerTracking.length > 0 && mapMarkerTracking.map((item, index)=>
          <Marker
            onDblClick={onDoubleClickDot}
            icon={{
              url: getIconFlash(item.callerType),
              scaledSize: new window.google.maps.Size(15, 15)
            }}
            position={item.position}
            key={index}
          >
          </Marker>
        )
      }
    </>
  );
};
