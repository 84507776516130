import React, {useEffect, useState} from 'react';
import {NoteHeader} from '../../molecules/NoteHeader';
import {NoteControlProps} from '.';
import './NoteControl.styles.css';
import {Button, ButtonVariant} from '../../atoms/Button';
import UpdateNoteImg from '../../../assets/images/check-square-offset.svg';
import {Image} from '../../atoms/Image';
import {Text} from '../../atoms/Text';
import {Colors} from '../../../configs/colors';

export const NoteControl: React.FC<NoteControlProps> = ({noteInformation}) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const [readonly, setReadonly] = useState<boolean>(true);
  const [value, setValue] = useState<string>('');
  const [oldValue, setOldValue] = useState<string>('');

  useEffect(() => {
    setValue(noteInformation.note);
    setOldValue(noteInformation.note);
  }, [noteInformation]);

  const handleClickEditNote = () => {
    setReadonly(false);
    setOpen(true);
  };
  const handleOnchangeValue = (event: any) => {
    setValue(event.target.value);

  };
  const handleSubmitNote = () => {
    // call API submit note, set state value
    setValue(value);
    setOldValue(value);
    setReadonly(true);
  };
  const handleCancelSubmit = () => {
    setValue(oldValue);
    setReadonly(true);
  };

  const handleClickOpenNote = () => {
    if (isOpen === true) {
      setReadonly(true);
      setValue(oldValue);
    }
    setOpen(!isOpen);
  };

  return (
    <div>
      <div className="note-controls">
        <NoteHeader
          noteInformation={noteInformation}
          isOpen={isOpen}
          onClickOpenContent={handleClickOpenNote}
          onClickEditNote={handleClickEditNote}
        />
        {
          isOpen &&
          <textarea
            value={value}
            readOnly={readonly}
            onChange={handleOnchangeValue}
            cols={30}
          />
        }
        {
          !readonly &&
          <div className="btn-grs-submit">
            <Button
              variant={ButtonVariant.ButtonImage}
              width="auto"
              height="40px"
              color={Colors.LSDarkTeal}
              onClick={handleSubmitNote}>
              <Image margin="0 8px 0 0" width="24px" url={UpdateNoteImg} />
              <Text fontSize={15} fontWeight="600" color={Colors.LSWhite} >Update note</Text>
            </Button>
            <Button
              variant={ButtonVariant.Cancel}
              width="auto"
              height="40px"
              onClick={handleCancelSubmit}
            >Cancel</Button>
          </div>
        }
      </div>

    </div>
  );
};
