import {MapLocation, MarkerData} from '../../atoms/Map/Map.props';
import {ReactNode} from 'react';
import {AvatarStatus} from '../../organisms/MapAvatar';
import {SearchLocation} from '../../molecules/SearchLocationInput';
import {TimeOfIncident, DataContacts} from '../../../pages/Caller/Caller.props';

export interface MapAvatarProps {
  url: string;
  name: string;
  status: AvatarStatus,
}

export enum MapType {
  Small,
  Large,
}

export interface MapMarkerTracking {
  callerType: string;
  position: MapLocation;
}

export interface MapControlProps {
  type: MapType,
  onChangeLocation: (data: SearchLocation) => void;
  coordinate?: MapLocation;
  zoomLevel?: number;
  noOfVideoItems?: number,
  noOfChatItems?: number,
  noOfCctvItems?: number,
  mapMarkerView?: ReactNode,
  mapMarkerInfoView?: ReactNode,
  markerData?: Array<MarkerData>,
  mapAvatarData?: Array<MapAvatarProps>;
  heightMap?: string;
  tabName?: string;
  path?: Array<MapLocation>,
  mapMarkerTracking?: Array<MapMarkerTracking>;
  onDoubleClickDot?: () => void;
  lastLocationInfo?: LastLocationInfo;
  subscriberId?: string;
  fullscreenControl?: boolean;
  isFullScreenControl?: boolean;
  originLocationInfo?: LastLocationInfo;
  timeOfInteraction?: TimeOfIncident;
  dataContacts?: DataContacts[]
  onToggleInfo?: (value: DataContacts) => void;
}

export interface MapCenterInfo {
  date: string;
  time: string;
  fullName: string;
  imageUrl: string;
}

export interface LastLocationInfo {
  date: string;
  time: string;
  fullName: string;
  imageUrl: string;
  timeCounter: number;
  firstName?: string;
}
