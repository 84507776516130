import React from 'react';
import {Colors} from '../../../configs/colors';
import {Button, ButtonVariant} from '../../atoms/Button';
import {Text} from '../../atoms/Text';
import {ContactCallerControlProps} from './ContactCallerControl.props';
import {ReactComponent as PhoneOutgoing} from '../../../assets/images/phone-outgoing.svg';
import './ContactCallerControl.styles.css';

export const ContactCallerControl: React.FC<ContactCallerControlProps> = ({
  onAudioCallClick,
  userType,
  fullName,
  backgroundColor
}) => {
  return (
    <div className="contact-caller-control-wrap" style={{backgroundColor: backgroundColor}}>
      <div>
        <Text color={Colors.LSWhite} margin="0px 0px 10px 0px">{userType}</Text>
        <Text color={Colors.LSWhite} fontWeight="bold" fontSize={15}>{fullName}</Text>
      </div>
      <Button height="50px" width="50px" variant={ButtonVariant.Icon} onClick={onAudioCallClick} textColor={Colors.LSWhite} color="#1E1E1EB2" >
        <PhoneOutgoing stroke={Colors.LSWhite} width={32} height={32} />
      </Button>
    </div>
  );
};