export enum AvatarStatus {
  Active,
  Inactive,
  Busy,
}

export interface MapAvatarProps {
  imageUrl: string;
  name: string;
  size?: string;
  borderWidth?: string;
  onClick?: () => void;
  borderColor?: string;
}
