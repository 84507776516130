import React from 'react';
import {BatteryProps} from '.';
import {Text} from '../../atoms/Text';
import './Battery.styles.css';
import {Colors} from '../../../configs/colors';
import {ReactComponent as ExclamationMark} from '../../../assets/images/exclamation-mark.svg';
import {ReactComponent as BatteryCharging} from '../../../assets/images/battery-charging.svg';

export const Battery: React.FC<BatteryProps> = ({
  currentBattery,
}) => {

  return (
    <div className={`battery-styles ${currentBattery <=5 ? 'battery-background' : ''}`}>
      <div className="battery-icon">
        <div className="battery-body">
          {currentBattery > 0 && currentBattery <= 5 && <ExclamationMark height={6}/>}
          {currentBattery > 5 && <div className="first"></div>}
          {currentBattery > 25 && <div className="second"></div>}
          {currentBattery > 50 && <div className="three"></div>}
          {currentBattery > 75 && <div className="four"></div>}
        </div>
        <div className="battery-head"></div>
      </div>
      <div className="battery-percent">
        <Text fontSize={12} fontWeight="500" color={Colors.LSWhite}>{currentBattery}%</Text>
      </div>
    </div>
  );
};
