import React, {useState, useEffect} from 'react';
import {Colors} from '../../../configs/colors';
import {InputField, InputFieldVariant} from '../../atoms/InputField';
import {SearchResultsProps} from './SearchResults.props';
import {ReactComponent as Search} from '../../../assets/images/search-icon.svg';
import {SearchResultItem, SortDirection} from '../../molecules/SearchResult';
import './SearchResults.styles.css';
import {Images} from '../../../configs/images';
import {Image} from '../../atoms/Image';
import {TableHeader} from '../../molecules/TableHeader';
import {Text} from '../../atoms/Text';
import {ReactComponent as ArrowUpDown} from '../../../assets/images/arrows-down-up.svg';
import {ReactComponent as ArrowUp} from '../../../assets/images/arrow-up.svg';
import {ReactComponent as ArrowDown} from '../../../assets/images/arrow-down.svg';
import {ReactComponent as CaretRight} from '../../../assets/images/caret-right.svg';
import {Tooltip} from '../../atoms/Tooltip/Tooltip';
import {convertGender} from '../../../utils/commonFunction';

const rotate: { [key: string]: SortDirection } = {asc: 'desc', desc: '', '': 'asc'};

export const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  onOpenItemClick,
  isFetching,
}) => {
  const [filteredResults, setFilteredResults] = useState<SearchResultItem[]>([]);
  const [searchText, setSearchText] = useState('');
  const [directionAge, setDirectionAge] = useState<SortDirection>('');
  const [directionName, setDirectionName] = useState<SortDirection>('');

  const onRotate = (typeSort: string) => {
    let sortList = filteredResults;
    if (typeSort === 'name') {
      setDirectionName(rotate[directionName]);
      switch (rotate[directionName]) {
      case 'asc':
        sortList = [...sortList].sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'desc':
        sortList = [...sortList].sort((a, b) => b.name.localeCompare(a.name));
        break;

      default:
        sortList = results;
        break;
      }
    } else {
      setDirectionAge(rotate[directionAge]);
      switch (rotate[directionAge]) {
      case 'asc':
        sortList = [...sortList].sort((a, b) => a.age - b.age);
        break;
      case 'desc':
        sortList = [...sortList].sort((a, b) => b.age - a.age);
        break;

      default:
        sortList = results;
        break;
      }
    }
    setFilteredResults([...sortList]);
  };

  const renderSort = (direction: SortDirection, typeSort: string) => {
    switch (direction) {
    case 'desc':
      return (
        <ArrowUp
          onClick={() => onRotate(typeSort)}
          stroke={Colors.NeutralGrey1}
          width={16}
          height={13}
          className="search-result-header-arrow"
        />
      );
    case 'asc':
      return (
        <ArrowDown
          onClick={() => onRotate(typeSort)}
          stroke={Colors.NeutralGrey1}
          width={16}
          height={13}
          className="search-result-header-arrow"
        />
      );

    default:
      return (
        <ArrowUpDown
          onClick={() => onRotate(typeSort)}
          stroke={Colors.NeutralGrey1}
          width={16}
          height={16}
          className="search-result-header-arrow"
        />
      );
    }
  };

  const dataHeader = [
    {
      type: 'Name',
      showIcon: true,
      widthType: '20%',
      Icon: renderSort(directionName, 'name'),
    },
    {
      type: 'Age',
      showIcon: true,
      widthType: '10%',
      Icon: renderSort(directionAge, 'age'),
    },
    {
      type: 'Sex',
      showIcon: false,
      widthType: '10%',
    },
    {
      type: 'Contact',
      showIcon: false,
      widthType: '15%',
    },
    {
      type: 'Home Address',
      showIcon: false,
      widthType: '45%',
    },
  ];

  useEffect(() => {
    setFilteredResults(results);
  }, [results]);

  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
    const search = !event.currentTarget.value
      ? results
      : results.filter(
        (item) =>
          item.name.toLowerCase().includes(event.currentTarget.value.toLowerCase()) ||
            item.contact.includes(event.currentTarget.value)
      );
    setFilteredResults(search);
  };

  return (
    <div className="search-result-wrap">
      {!isFetching && (
        <>
          <InputField
            variant={InputFieldVariant.Labelled}
            label="Filter by name or number"
            labelColor={Colors.NeutralGrey2}
            type="text"
            alt=""
            placeholder="Filter by name or number"
            value={searchText}
            onChange={handleSearch}
            Icon={Search}
            width="50%"
            height="46px"
          />
          {filteredResults.length > 0 && <div className="search-results-wrap">
            <div className="search-table-container">
              <TableHeader dataHeader={dataHeader} />
              <div className="search-table-content scroll-custom">
                {
                  filteredResults.map((item: SearchResultItem, index: number) => (
                    <div
                      className={item.isDeleted? 'search-table-row record-deleted': 'search-table-row'}
                      key={index}
                      onDoubleClick={() => onOpenItemClick(item.subscriberId)}
                    >
                      <div
                        className="search-table-column name"
                        style={{width: dataHeader[0].widthType}}
                      >
                        <Tooltip position={index === 0 ? 'bottom' : 'top'} content={item.name}>
                          <div className="ellipsis">{item.name}</div>
                        </Tooltip>
                      </div>
                      <div
                        className="search-table-column age"
                        style={{width: dataHeader[1].widthType}}
                      >
                        <Text fontSize={14} fontWeight="400" color="#000000">
                          {item.age}
                        </Text>
                      </div>
                      <div
                        className="search-table-column sex"
                        style={{width: dataHeader[2].widthType}}
                      >
                        <Text fontSize={14} fontWeight="400" color="#000000">
                          {convertGender(item?.sex)}
                        </Text>
                      </div>
                      <div
                        className="search-table-column contact"
                        style={{width: dataHeader[3].widthType}}
                      >
                        <Text fontSize={14} fontWeight="400" color="#000000">
                          {item.contact}
                        </Text>
                      </div>
                      <div
                        className="search-table-column home-address"
                        style={{width: dataHeader[4].widthType}}
                      >
                        <Text fontSize={14} fontWeight="400" color="#000000">
                          <Tooltip
                            position={index === 0 ? 'bottom' : 'top'}
                            content={item.homeAddress}
                          >
                            <div className="ellipsis">{item.homeAddress}</div>
                          </Tooltip>
                        </Text>
                      </div>
                      <CaretRight
                        onClick={() => onOpenItemClick(item.subscriberId)}
                        stroke={Colors.LSDarkTeal}
                        width={16}
                        height={16}
                        className="search-result-right-arrow"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>}
        </>
      )}
    </div>
  );
};
