export interface ChatOptionsProps {
  automaticResponse: (value: string) => void;
  automaticResponseHover: (value:string) => void;
  reset?: boolean;
  onEndChat: (value: string) => void;
  isConnect?: boolean;
  callerName?:string;
  operatorName?:string;
}

export interface ActiveClass {
  handWaving: string;
  pause: string;
  firstAid: string;
  anythingElse: string;
  chatEnd: string;
  siren: string;
  location: string;
}

export enum TypeOptions {
  HandWaving,
  AnythingElse,
  FirstAid,
  Location,
  ChatEnd,
  Pause,
  Siren
}