import React, {useState} from 'react';
import {CallerNavigationProps} from './CallerNavigation.props';
import {Colors} from '../../../configs/colors';
import {Text} from '../../atoms/Text';
import './CallerNavigation.styles.css';

export const CallerNavigation: React.FC<CallerNavigationProps> = ({
  label,
  activeNav,
  onClick,
}) => {
  let className = 'caller-navigation-item';

  if (activeNav === label) {
    className += ' active';
  }
  return <div
    className={className}
    onClick={() => onClick(label)}
  >
    <Text color="#2C2E2E" fontSize={15} fontWeight="400">{label}</Text>
  </div>;
};