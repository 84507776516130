import {MapLocation} from '../../../atoms/Map';
import {LastLocationInfo} from './../../../layouts/MapControl/MapControl.props';
export interface MarkerInfoProps {
  stroke?: string;
  locationInfo?: LastLocationInfo;
  path: MapLocation,
  isShowBroadcast?: boolean;
  isShowTime?: boolean;
  type?: number;
}

export enum MakerType {
  Full,
  Current,
}