import React from 'react';
import {Colors} from '../../../configs/colors';
import {Text} from '../../atoms/Text';
import {WatchMeBulletListItem} from '../WatchMeBulletListItem';
import './MissedCheckinProcedure.styles.css';
import {MissedCheckinProcedureProps} from './MissedCheckinProcedure.props';

export const MissedCheckinProcedure: React.FC<MissedCheckinProcedureProps> = ({items}) => {
  return (
    <div className="missed-checkin-procedure-wrap">
      <div className="missed-checkin-procedure-header">
        <Text color={Colors.LSTealAccent} fontSize={12} fontWeight="500px">
          MISSED CHECK-IN PROCEDURE
        </Text>
      </div>
      {items.map((item, key) => (
        <WatchMeBulletListItem {...item} key={key} />
      ))}
    </div>
  );
};
