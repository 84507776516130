import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './store';
import {
  getCallStatistics,
  activeInteractions,
  openInteractions
} from '../api/interactions';
import {CONSTANTS} from '../constants/constants';
import {formatDate, getTimeDuration} from '../utils/dateTimeHelper';
import {InteractionsData} from '../components/organisms/CallsTable';


export interface InteractionStatisticsObject {
  chatCount: number;
  videoCallCount: number;
  cctvCount: number;
  activeInteractions: InteractionsData[];
  openInteractions: InteractionsData[];
}

export interface InteractionHistoryObjectState {
  data?: InteractionStatisticsObject;
  isLoading: boolean;
  error?: string;
}

const initialState: InteractionHistoryObjectState = {
  data: undefined,
  isLoading: false,
  error: undefined
};

export const sortInteractionsData = (data: InteractionsData[], sortDirection:string): InteractionsData[] => {
  switch (sortDirection) {
  case 'asc':
    return data.sort((a, b) => +new Date(a.interactionStartDateTime) - +new Date(b.interactionStartDateTime));
  case 'desc':
    return data.sort((a, b) => +new Date(b.interactionStartDateTime) - +new Date(a.interactionStartDateTime));

  default:
    return data.sort((a, b) => +new Date(a.interactionStartDateTime) - +new Date(b.interactionStartDateTime));
  }
};

export const interactionStatisticsGetCallStatistics = createAsyncThunk('interactionStatistics/getCallStatistics', async (date: string) => {
  const response = await getCallStatistics(date);
  const data = response.lifestreamResponse.data;
  const chatObject = data.find((i:any) => i.channel == CONSTANTS.CALL_TYPES.MESSAGE);
  const videoCallbject = data.find((i:any) => i.channel == CONSTANTS.CALL_TYPES.VIDEO_CALL);
  const cctvbject = data.find((i:any) => i.channel == CONSTANTS.CALL_TYPES.CCTV_CALL);
  return {
    chatCount: chatObject? chatObject.numberOfInteractions : 0,
    videoCallCount: videoCallbject? videoCallbject.numberOfInteractions : 0,
    cctvCount: cctvbject? cctvbject.numberOfInteractions : 0
  };
});

export const interactionStatisticsActiveInteractions = createAsyncThunk('interactionStatistics/activeInteractions', async () => {
  const response = await activeInteractions();
  const data = response.lifestreamResponse.data.data;
  return sortInteractionsData(data.map((item: any) => {
    return {
      interactionId: item.interactionId,
      subscriberId: item.subscriberId,
      type:item.channel,
      service: '',
      from: item.subscriberName,
      location: item.address || '',
      contact: `${item.contactNumber.countryCode}${item.contactNumber.number}`,
      received: formatDate(item.interactionStartDateTime, 'DD/MM/yy HH:mm:ss'),
      status:item.mainStatus.toLowerCase(),
      callOp: item.operator || 'N/A',
      duration: null,
      state: item.subStatus,
      interactionStartDateTime: item.interactionStartDateTime,
      mainStatus: item.mainStatus
    };
  }), 'desc');
});

export const interactionStatisticsOpenInteractions = createAsyncThunk('interactionStatistics/openInteractions', async () => {
  const response = await openInteractions();
  const data = response.lifestreamResponse.data.data;
  return sortInteractionsData(data.map((item: any) => {
    return {
      interactionId: item.interactionId,
      subscriberId: item.subscriberId,
      type:item.channel,
      service: '',
      from: item.subscriberName,
      location: item.address || '',
      contact: `${item.contactNumber.countryCode}${item.contactNumber.number}`,
      received: formatDate(item.interactionStartDateTime, 'DD/MM/yy HH:mm:ss'),
      status:item.mainStatus.toLowerCase(),
      callOp: item.operator || 'N/A',
      duration: item.interactionEndDateTime ? getTimeDuration(item.interactionStartDateTime, item.interactionEndDateTime):getTimeDuration(item.interactionStartDateTime, new Date()),
      state: item.subStatus,
      interactionStartDateTime: item.interactionStartDateTime,
      mainStatus: item.mainStatus
    };
  }), 'desc');
});

const interactionStatisticsSlice = createSlice({
  name: 'interactionStatistics',
  initialState,
  reducers: {
    setInteractionStatisticsData: (state, action: PayloadAction<InteractionStatisticsObject | undefined>) => {
      state.isLoading = false;
      state.data = action.payload as InteractionStatisticsObject;
      state.error = undefined;
    },
    setInteractionStatisticsClear: (state) => {
      state.isLoading = false;
      state.data = undefined;
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(interactionStatisticsGetCallStatistics.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionStatisticsGetCallStatistics.fulfilled, (state, action) => {
      const updatedData = {
        ...state.data,
        chatCount: action.payload.chatCount,
        videoCallCount: action.payload.videoCallCount,
        cctvCount: action.payload.cctvCount,
      } as InteractionStatisticsObject;

      state.isLoading = false;
      state.data = updatedData;
      state.error = undefined;
    });

    builder.addCase(interactionStatisticsGetCallStatistics.rejected, (state, action) => {
      const updatedData = {
        ...state.data,
        chatCount: 0,
        videoCallCount: 0,
        cctvCount: 0,
      } as InteractionStatisticsObject;

      state.isLoading = false;
      state.data = updatedData;
      state.error = action.payload as string;
    });

    builder.addCase(interactionStatisticsActiveInteractions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionStatisticsActiveInteractions.fulfilled, (state, action) => {
      const updatedData = {
        ...state.data,
        activeInteractions: action.payload,
      } as InteractionStatisticsObject;

      state.isLoading = false;
      state.data = updatedData,
      state.error = undefined;
    });

    builder.addCase(interactionStatisticsActiveInteractions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });

    builder.addCase(interactionStatisticsOpenInteractions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(interactionStatisticsOpenInteractions.fulfilled, (state, action) => {
      const updatedData = {
        ...state.data,
        openInteractions: action.payload,
      } as InteractionStatisticsObject;

      state.isLoading = false;
      state.data = updatedData,
      state.error = undefined;
    });

    builder.addCase(interactionStatisticsOpenInteractions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  setInteractionStatisticsData,
  setInteractionStatisticsClear,
} = interactionStatisticsSlice.actions;

export const interactionStatisticsSelector = (state: RootState) => state.interactioStatistics;

export default interactionStatisticsSlice.reducer;
