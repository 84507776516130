import moment from 'moment';

export const getDateTimeDifference = (givenDateTime: Date): number => {
  return givenDateTime? moment().diff(givenDateTime, 'years'): 0;
};

export const formatDate = (strDateTime: string, format:string): string => {
  return moment(strDateTime).format(format);
};

export const getDateTime = (dateObj?: Date): string => {
  if (dateObj) {
    return moment(dateObj).format('LT');
  }
  return 'n/a';
};

export const getTimeDuration = (startDateTime: Date, endDateTime: Date): string => {
  const timeDiff = moment(endDateTime).diff(moment(startDateTime));
  const timeDiffParts = new Date(timeDiff).toISOString().substring(11, 19).split(':');

  if(timeDiffParts[0] == '00'){
    timeDiffParts.shift();
  }
  return timeDiffParts.join(':');
};

export const getCurrentIsoDate = (): any => {
  return {
    from: moment().startOf('day').toISOString(),
    to: moment().endOf('day').toISOString(),
  };
};

export const getTimeStamp = (date: Date): number => {
  return moment(date).unix();
};

export const getFormattedDuration = (seconds: number): string => {
  if (seconds) {
    const formattedvalue = new Date(seconds * 1000).toISOString();
    return `${formattedvalue.substring(11, 13)} hr ${formattedvalue.substring(14, 16)} mins`;
  } else {
    return '00 hr 00 mins';
  }
};

export const getMinutesStartToNow = (startTime: Date): number => {
  const today = moment().unix();
  const start = getTimeStamp(startTime);
  const duration = (today - start) / 60;

  return duration;
};


export const getCurrentDate = (): string => {
  return moment().format('DD/MM/yy HH:mm:ss');
};
