import Geocode from 'react-geocode';
import {CONSTANTS} from './../constants/constants';
import {InteractionsData} from '../components/organisms/CallsTable';
import {Images} from '../configs/images';
import {MapLocation, ResultTracking} from '../components/atoms/Map';
import {UserLocationState} from '../redux/userLocationSlice';
import {DataContacts} from '../pages/Caller';

Geocode.setApiKey('AIzaSyC1NdNRCdXP2BBusO6Z5_8N9OrdpLebX_w');
Geocode.setLanguage('en');

export function getRingTime(call: InteractionsData | any) {
  let timer = 0;
  const operatorObj = call.operator || getFirstParticipant(call.participants);
  switch (call.state) {
  case CONSTANTS.CALL_STATE.NOT_SYNCED:
  case CONSTANTS.CALL_STATE.INCOMING:
  case CONSTANTS.CALL_STATE.OUTGOING:
  case CONSTANTS.CALL_STATE.FAILED:
    timer = 0;
    break;
  case CONSTANTS.CALL_STATE.ACTIVE:
  case CONSTANTS.CALL_STATE.COMPLETED:
    if (operatorObj && call.type !== CONSTANTS.CALL_TYPES.CCTV_CALL) {
      const joinedTime = parseFloat(operatorObj.joinTimeStamp || operatorObj.joinTime);
      timer = joinedTime - call.received;
    } else if (call.endTime) {
      timer = call.endTime - call.received;
    } else {
      timer = 0;
    }
    break;
  default:
    timer = 0;
  }
  timer = timer < 0 ? 0 : timer;
  return timer;
}

export function getFirstParticipant(participants: any) {
  let joinTime: any = null;
  let participant = null;

  if (participants) {
    participants.map((item: any) => {
      if (item.joinTime !== null) {
        const participantJoinTime = typeof item.joinTime === 'function' ? item.joinTime.unix() : parseFloat(item.joinTime);
        if (joinTime === null) {
          joinTime = participantJoinTime;
          participant = item;
        } else if (participantJoinTime < joinTime) {
          joinTime = participantJoinTime;
          participant = item;
        }
      }
    });
    return participants;
  }

  return participant;
}

export const convertGender = (gender: string) => {
  if (gender) {
    return gender === 'Male' ? 'M' : 'F';
  }
  return null;
};

export const getAddressFromGeoLocation = (latitude: string, longitude: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        resolve(address);
      },
      (error) => {
        console.error(error);
        reject('n/a');
      }
    );
  });
};

export const incomingTextButton = (callerType: string) => {
  switch (callerType) {
  case CONSTANTS.CALL_TYPES.SOS_VIDEO_CALL:
    return 'Incoming call';
  case CONSTANTS.CALL_TYPES.MESSAGE:
    return 'Incoming message';
  case CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN:
    return 'Missed check-in';

  default:
    return 'Incoming call';
  }
};

export const incomingIconTypeCaller = (callerType: string) => {
  const obj = {
    icon: '',
    text: ''
  };

  switch (callerType) {
  case CONSTANTS.CALL_TYPES.SOS_VIDEO_CALL:
    obj.icon = Images.InComingCall;
    obj.text = 'Incoming call...';
    break;
  case CONSTANTS.CALL_TYPES.MESSAGE:
    obj.icon = Images.InComingMessage;
    obj.text = 'Incoming chat...';
    break;
  case CONSTANTS.CALL_TYPES.WATCH_ME_MISSED_CHECKIN:
    obj.icon = Images.BellRiging;
    obj.text = 'Missed check-in...';
    break;

  default:
    obj.icon = Images.InComingCall;
    obj.text = 'Incoming call...';
    break;
  }

  return obj;
};

export const getFirstNameFromFullName = (fullName: string | undefined) => {
  if (fullName) {
    return fullName.split(' ')[0];
  }
  return fullName;
};

const result: ResultTracking = {
  animatedPolylines: [],
  isTracking: false,
  endedVideo: false
};
export const drawPolyline = (positionList: MapLocation[], map: any, player: HTMLVideoElement | null, isTracking: boolean) => {
  result.isTracking = isTracking;
  
  const promise = new Promise((resolve, reject) => {
    if (positionList.length > 0) {
      if (player != null) {
        if (!isTracking && (result.endedVideo || player.paused)) {
          clearDrawingLinePart();
        } else {
          animatePolyline(positionList, map, player);
        }
        player.onplay = () => {
          result.endedVideo = false;
          clearDrawingLinePart();
          setTimeout(() => {
            if (result.isTracking) {
              animatePolyline(positionList, map, player);
            } else {
              clearDrawingLinePart();
            }
          }, 150);
        };

        player.onseeked = () => {
          result.endedVideo = false;
          clearDrawingLinePart();
          setTimeout(() => {
            if (result.isTracking) {
              animatePolyline(positionList, map, player);
            } else {
              clearDrawingLinePart();
            }
          }, 150);
        };

        player.ontimeupdate = (event: any) => {
          const currentTime = event.target.currentTime;
          const durationBetweenEachPosition = player.duration / (positionList.length - 1);
          const currentPosition = Math.floor(currentTime / durationBetweenEachPosition);
          animatePolyline(positionList, map, player);
          if (result.isTracking) {
            if (currentPosition == 0) return;
            if (currentPosition < positionList.length) {
              if (typeof result.animatedPolylines[currentPosition] === 'undefined') {
                if (!player.paused) {
                  drawLinePart(positionList, currentPosition, map);
                }
              }
            }
          } else {
            clearDrawingLinePart();
          }
        };

        player.onended = () => {
          console.log('onended');
          result.endedVideo = true;
        };
      }
      resolve(result);
    }
  });
  return promise;
};

const animatePolyline = (positionList: MapLocation[], map: any, player: HTMLVideoElement) => {
  const durationBetweenEachPosition = player.duration / (positionList.length - 1);
  const currentPosition = Math.floor(player.currentTime / durationBetweenEachPosition);

  for (let pos = 1; pos <= currentPosition; pos++) {
    drawLinePart(positionList, pos, map);
  }
};

const drawLinePart = (positionList: MapLocation[], currentPosition: number, map: any) => {
  const part = [];
  part.push(positionList[currentPosition - 1]);
  part.push(positionList[currentPosition]);

  const polyline = new google.maps.Polyline({
    path: part,
    strokeColor: CONSTANTS.MAP_STYLES_CONFIG.POLYLINE_REPLAY.STROKE_COLOR,
    strokeOpacity: CONSTANTS.MAP_STYLES_CONFIG.POLYLINE_REPLAY.STROKE_OPACITY,
    strokeWeight: CONSTANTS.MAP_STYLES_CONFIG.POLYLINE_REPLAY.STROKE_WEIGHT,
    zIndex: CONSTANTS.MAP_STYLES_CONFIG.POLYLINE_REPLAY.Z_INDEX,
    map,
  });
  result.animatedPolylines.push(polyline);
};

const clearDrawingLinePart = () => {
  if (result.animatedPolylines.length > 0) {
    for (let i = 0; i < result.animatedPolylines.length; i++) {
      result.animatedPolylines[i].setMap(null);
    }
  }
  result.animatedPolylines = [];
};

export const getPositionList = (userLocation: UserLocationState | undefined) => {
  if (userLocation && userLocation.pathData && userLocation.pathData.length > 0) {
    const list = userLocation.pathData.map((item) => {
      return {
        lng: item.longitude,
        lat: item.latitude,
        timestamp: item.timestamp,
        createdDate: item.createdDate,
      };
    });
    return list;
  }
  return [];
};

export const sortDistance = (data: DataContacts[],): DataContacts[] => {
  return data.filter((item) => item.location).sort((a, b) => (a.distanceValue - b.distanceValue));
};