import {DataContacts} from '../../../pages/Caller';

export interface ContactInformationPanelProps {
  MobileButtonIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  HomeButtonIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  VideoButtonIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  VideoButtonWhiteIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  WorkButtonIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  OrangDot?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  RedDot?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  GreenDot?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  videoText?: string;
  mobileText?: string;
  homeText?: string;
  workText?: string;
  buttoText?: string;
  size?: string;
  buttonColor?: string;
  onMobileClick?: (number: string, subscriberId?:string) => void;
  onVideoClick?: (contact: DataContacts) => void;
  onChatClick?: () => void;
  variant?: ProfileVariant;
  children?: string;
  onClose?: () => void;
  onLocationUpdateClick?: () => void;
  dataContact: DataContacts;
  isInteractionButtonDisabled?: boolean;
}

export enum ProfileVariant {
  NonMember = 'non-member',
  Member = 'member',
  ActiveCall = 'active-call',
}
