import React, {useEffect, useRef, useState} from 'react';
import './CallerLocation.styles.css';
import {CallerLocationProps} from './CallerLocation.props';
import {Text} from '../../atoms/Text';
import appConfig from '../../../configs/appConfig.json';
import {
  ConvertTo3waClient,
  ConvertTo3waOptions,
  LocationJsonResponse,
} from '@what3words/api';
import {Images} from '../../../configs/images';
import {Image} from '../../atoms/Image';

export const CallerLocation: React.FC<CallerLocationProps> = ({
  callerLocation = {
    fullName: 'n/a',
    time: 'n/a',
    lat: undefined,
    lng: undefined,
    location: 'n/a',
  },
}) => {
  const navRef = useRef<any>(null);
  const API_KEY = appConfig.What3Words.apiKey;
  const client: ConvertTo3waClient = ConvertTo3waClient.init(API_KEY);
  const [w3wValue, setW3wValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getW3w = () => {
    if (callerLocation.lat && callerLocation.lng) {
      setIsLoading(true);
      const options: ConvertTo3waOptions = {
        coordinates: {
          lat: callerLocation.lat,
          lng: callerLocation.lng,
        },
      };
      client
        .run({...options, format: 'json'}) // { format: 'json' } is the default response
        .then((res: LocationJsonResponse) => {
          setW3wValue(res.words);
          setIsLoading(false);
        });
    } else {
      setW3wValue('duck.caramel.letter');
    }
  };
  const onAddClick = () => {
    navRef.current.classList.add('w3w-show');
    getW3w();
  };

  const onRemoveClick = () => {
    navRef.current.classList.remove('w3w-show');
  };

  useEffect(() => {
    if (navRef && navRef.current.className.includes('w3w-show')) {
      getW3w();
    }
  }, [callerLocation]);

  return (
    <div className="bottom-left">
      <div className="bottom-left-col">
        <Text fontSize={9} fontWeight="400" color="#626566">
          Caller
        </Text>
        <Text fontSize={15} fontWeight="600">
          {callerLocation.fullName}
        </Text>
      </div>
      <div className="bottom-left-col">
        <Text fontSize={9} fontWeight="400" color="#626566">
          Time of call
        </Text>
        <Text fontSize={15} fontWeight="600">
          {callerLocation.time}
        </Text>
      </div>
      <div className="bottom-left-col">
        <Text fontSize={9} fontWeight="400" color="#626566">
          Current location
        </Text>
        <Text fontSize={15} fontWeight="600">
          {callerLocation.location || 'n/a'}
        </Text>
      </div>
      <div className="bottom-left-col" ref={navRef}>
        <Text fontSize={9} fontWeight="400" color="#626566">
          Current location
          <span className="lat-long" onClick={onRemoveClick}>
            Lat,Long
          </span>
          <span className="w3w" onClick={onAddClick}>
            {'/// W3W'}
          </span>
        </Text>
        <Text className="lat-long-result" fontSize={15} fontWeight="600">
          {callerLocation.lat && callerLocation.lng
            ? `${callerLocation.lat.toFixed(5)}, ${callerLocation.lng.toFixed(
              5
            )}`
            : 'n/a'}
        </Text>
        {isLoading ? (
          <div className="spinner-wrap">
            <Image width="20px" url={Images.Spinner} />
          </div>
        ) : (
          <Text className="w3w-result" fontSize={15} fontWeight="600">
            {w3wValue}
          </Text>
        )}
      </div>
    </div>
  );
};
