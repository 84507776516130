import GreenDot from '../assets/images/green-dot.svg';
import RedDot from '../assets/images/red-dot.svg';
import GreyDot from '../assets/images/grey-dot.svg';
import Siren from '../assets/images/icon-siren.svg';
import SirenActive from '../assets/images/icon-siren-active.svg';
import Alarm from '../assets/images/icon-alarm.svg';
import AlarmActive from '../assets/images/icon-alarm-active.svg';
import ClockCounter from '../assets/images/icon-clock-counter-clockwise.svg';
import ClockCounterActive from '../assets/images/icon-clock-counter-clockwise-active.svg';
import ClockCounterInactive from '../assets/images/icon-clock-counter-clockwise-inactive.svg';
import FirstAidKit from '../assets/images/icon-first-aid-kit.svg';
import FirstAidKitActive from '../assets/images/icon-first-aid-kit-active.svg';
import FirstAid from '../assets/images/icon-first-aid.svg';
import FirstAidActive from '../assets/images/icon-first-aid-active.svg';
import Prohibit from '../assets/images/icon-prohibit.svg';
import ProhibitActive from '../assets/images/icon-prohibit-active.svg';
import UserTalk from '../assets/images/icon-user-talk.svg';
import UserTalkActive from '../assets/images/icon-user-talk-active.svg';
import Chat from '../assets/images/chat-icon.svg';
import Paperclip from '../assets/images/paperclip.svg';
import AssistText from '../assets/images/assist-text-icon.svg';
import Send from '../assets/images/send-icon.svg';
import PhotoDefault from '../assets/images/profile-image.svg';
import CaretGeyRight from '../assets/images/caret-grey-right.svg';
import CaretGeyLeft from '../assets/images/caret-grey-left.svg';
import Gauge from '../assets/images/gauge-icon.svg';
import Waiting from '../assets/images/timer-icon.svg';
import InOutCall from '../assets/images/phone-incall.svg';
import Active from '../assets/images/active.svg';
import AvgRing from '../assets/images/static.svg';
import Total from '../assets/images/total-users.svg';
import Load from '../assets/images/load-icon.svg';
import LoggedIn from '../assets/images/logged-in.svg';
import Available from '../assets/images/avaliable.svg';
import Unavailable from '../assets/images/unavaliable-static.svg';
import LunchBreak from '../assets/images/lunch-break-icon.svg';
import Close from '../assets/images/close-icon.svg';
import HomeIcon from '../assets/images/home-icon.svg';
import IncidentIcon from '../assets/images/warning-circle.svg';
import CallerIcon from '../assets/images/user-focus.svg';
import ActiveGauge from '../assets/images/active-gauge.svg';
import ActiveCCTV from '../assets/images/active-cctv.svg';
import ActivePhone from '../assets/images/active-phone.svg';
import ActiveAgents from '../assets/images/active-agent.svg';
import Logo from '../assets/images/logo.svg';
import Search from '../assets/images/search-icon.svg';
import Grid from '../assets/images/grid-style.svg';
import NotePencilWhite from '../assets/images/note-pencil-white.svg';
import ActiveClose from '../assets/images/x-active.svg';
import MapFullScreen from '../assets/images/map-fullscreen-icon.svg';
import MiniGoogleMap from '../assets/images/mini-google-map.svg';
import VideoIcon from '../assets/images/video-icon.svg';
import CCTVIcon from '../assets/images/cctv-icon.svg';
import VoiceIcon from '../assets/images/audio-icon.svg';
import ProfileImage from '../assets/images/profile-image.svg';
import ProfileIcon from '../assets/images/contact-information-profile-logo-icon.svg';
import NoProfilePictureIcon from '../assets/images/no-profile-picture.svg';
import AlertIcon from '../assets/images/alert-icon.svg';
import WatchMeIcon from '../assets/images/watch-me-icon.svg';
import ImgIncomingCall from '../assets/images/incoming-call.svg';
import MessageIcon from '../assets/images/message-icon.svg';
import InComingCall from '../assets/images/phone-call.svg';
import InComingMessage from '../assets/images/incoming-chat.svg';
import InComingMissedCheckIn from '../assets/images/missed-check-in.svg';
import LocateOnMap from '../assets/images/locate-on-map.svg';
import DirectionsOnMap from '../assets/images/directions-on-map.svg';
import LastKnownOnMap from '../assets/images/last-known-on-map.svg';
import TrackOnMap from '../assets/images/track-on-map.svg';
import Spinner from '../assets/images/spinner.svg';
import GreenFlash from '../assets/images/dots/g-green-flash.gif';
import OrangeFlash from '../assets/images/dots/g-orange-flash.gif';
import RedFlash from '../assets/images/dots/g-red-flash.gif';
import BlueFlash from '../assets/images/dots/g-blue-flash.gif';
import ChatErrorIcon from '../assets/images/chat-error.svg';
import PegMan from '../assets/images/pegman.svg';
import MapTrifold from '../assets/images/map-trifold.svg';
import AustraliaMap from '../assets/images/australia-map.svg';
import MapIcon from '../assets/images/map-icon.svg';
import Timer from '../assets/images/timer.svg';
import ReadMarker from '../assets/images/red-marker.svg';
import GreenMarker from '../assets/images/green-marker.svg';
import GreenBroadcast from '../assets/images/green-broadcast.svg';
import RedBroadcast from '../assets/images/red-broadcast.svg';
import ListDashes from '../assets/images/list-dashes.svg';
import EndVideo from '../assets/images/end-icons/end-video.svg';
import Question from '../assets/images/question-icon.svg';
import HandShake from '../assets/images/hand-shake.svg';
import PhoneOutgoing from '../assets/images/phone-outgoing-icon.svg';
import ChatCentered from '../assets/images/chat-centere-text.svg';
import SmileySad from '../assets/images/smiley-sad.svg';
import Warning from '../assets/images/warning.svg';
import BellRiging from '../assets/images/icon-bell-ringing.svg';
import ContactInfo from '../assets/images/contact-info-icon.svg';
import CTA from '../assets/images/cta-icon.svg';
import CtaLocation from '../assets/images/cta-location-icon.svg';
import Clusterer from '../assets/images/clusterer.svg';
import SmileyWink from '../assets/images/SmileyWink.svg';
import SmileyNervous from '../assets/images/SmileyNervous.svg';
import PhoneHistory from '../assets/images/PhoneHistory.svg';

export const Images = {
  ImgGreenDot: GreenDot,
  ImgRedDot: RedDot,
  ImgGreyDot: GreyDot,
  ImgSiren: Siren,
  ImgAlarm: Alarm,
  ImgClockCounter: ClockCounter,
  ImgFirsAidKit: FirstAidKit,
  ImgFirstAid: FirstAid,
  ImgProhibit: Prohibit,
  ImgUserTalk: UserTalk,
  ImgSirenActive: SirenActive,
  ImgAlarmActive: AlarmActive,
  ImgClockCounterActive: ClockCounterActive,
  ClockCounterInactive,
  ImgFirstAidKitActive: FirstAidKitActive,
  ImgFirstAidActive: FirstAidActive,
  ImgProhibitActive: ProhibitActive,
  ImgUserTalkActive: UserTalkActive,
  ImgChat: Chat,
  ImgPaperclip: Paperclip,
  ImgAssistText: AssistText,
  ImgSend: Send,
  PhotoDefault: PhotoDefault,
  ImgCaretGeyRight: CaretGeyRight,
  ImgCaretGeyLeft: CaretGeyLeft,
  ImgGauge: Gauge,
  ImgWaiting: Waiting,
  InOutCall,
  ImgActive: Active,
  ImgAvgRing: AvgRing,
  ImgTotal: Total,
  ImgLoad: Load,
  ImgLoggedIn: LoggedIn,
  ImgAvailable: Available,
  ImgUnavailable: Unavailable,
  ImgLunchBreak: LunchBreak,
  ImgClose: Close,
  HomeIcon,
  CallerIcon,
  IncidentIcon,
  ActiveGauge,
  ActiveCCTV,
  ActivePhone,
  ActiveAgents,
  Logo,
  Search,
  Grid,
  NotePencilWhite,
  ActiveClose,
  MapFullScreen,
  MiniGoogleMap,
  VideoIcon,
  CCTVIcon,
  WatchMeIcon,
  VoiceIcon,
  ProfileImage,
  ProfileIcon,
  NoProfilePictureIcon,
  AlertIcon,
  ImgIncomingCall,
  MessageIcon,
  InComingCall,
  InComingMessage,
  InComingMissedCheckIn,
  LocateOnMap,
  DirectionsOnMap,
  LastKnownOnMap,
  TrackOnMap,
  Spinner,
  GreenFlash,
  OrangeFlash,
  RedFlash,
  BlueFlash,
  ChatErrorIcon,
  PegMan,
  AustraliaMap,
  MapIcon,
  Timer,
  ReadMarker,
  GreenMarker,
  GreenBroadcast,
  RedBroadcast,
  ListDashes,
  EndVideo,
  Question,
  HandShake,
  PhoneOutgoing,
  ChatCentered,
  SmileyWink,
  SmileyNervous,
  PhoneHistory,
  SmileySad,
  Warning,
  BellRiging,
  MapTrifold,
  ContactInfo,
  CTA,
  CtaLocation,
  Clusterer
};
