import React from 'react';
import {TabMenuItemProps} from './TabMenuItem.props';
import {NavLink} from 'react-router-dom';
import './TabMenuItem.styles.css';
import {Text} from '../../atoms/Text';
import {Image} from '../../atoms/Image';
import {Colors} from '../../../configs/colors';

export const TabMenuItem: React.FC<TabMenuItemProps> = (props: TabMenuItemProps) => {
  const {
    itemsNav = [],
  } = props;
  return (
    <div className="header-navigation">
      {itemsNav.map((item, index) => (
        <div key={index} className={item.disabled? 'link navlink-disable': 'link'}>
          <NavLink className="link" to={item.url} >
            {item.icon &&  <Image margin="0px 10px 0px 0px" width="24px" url={item.icon } />}
            <Text fontSize={15} fontWeight="600" color={Colors.LSBlack}>{item.text}</Text>
          </NavLink>
        </div>
      ))}
    </div>
  );
};
