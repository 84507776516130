import React, {useState} from 'react';
import {
  ContactInformationPanelProps,
  ProfileVariant,
} from './ContactInformationPanel.props';
import {Image} from '../../atoms/Image';
import {CircleImage} from '../../molecules/CircleImage';
import {Text} from '../../atoms/Text';
import {Button, ButtonVariant} from '../../atoms/Button';
import Xicon from '../../../assets/images/x-icon.svg';
import ContactInformationImage from '../../../assets/images/contact-information-image.svg';
import NonMemberImage from '../../../assets/images/contact-information-second-image.svg';
import ProfileIcon from '../../../assets/images/contact-information-profile-logo-icon.svg';
import {ReactComponent as HomeCallButton} from '../../../assets/images/phone-home-icon.svg';
import {ReactComponent as VideoCallButton} from '../../../assets/images/video-camera-icon.svg';
import {ReactComponent as VideoCallButtonWhite} from '../../../assets/images/video-camera-white-icon.svg';
import {ReactComponent as WorkCallButton} from '../../../assets/images/phone-work-icon.svg';
import {ReactComponent as MobileCallButton} from '../../../assets/images/phone-mobile-icon.svg';
import {ContactInformationPanelWrap} from '../../atoms/ContactInformationPanelWrap';
import {ReactComponent as orangeDot} from '../../../assets/images/dot-xl-orang-icon.svg';
import {ReactComponent as redDot} from '../../../assets/images/dot-xl-red-icon.svg';
import {ReactComponent as greenDot} from '../../../assets/images/dot-xl-green-icon.svg';
import {Colors} from '../../../configs/colors';
import CtaMobile from '../../../assets/images/cta-mobile-icon.svg';
import CtaHome from '../../../assets/images/cta-home-icon.svg';
import CtaVideo from '../../../assets/images/cta-video-icon.svg';
import './ContactInformationPanel.styles.css';
import {Images} from '../../../configs/images';
export const ContactInformationPanel: React.FC<ContactInformationPanelProps> =
  ({
    onVideoClick,
    MobileButtonIcon = MobileCallButton,
    HomeButtonIcon = HomeCallButton,
    VideoButtonIcon = VideoCallButton,
    VideoButtonWhiteIcon = VideoCallButtonWhite,
    WorkButtonIcon = WorkCallButton,
    OrangDot = orangeDot,
    RedDot = redDot,
    GreenDot = greenDot,
    videoText = 'video',
    mobileText = 'mobile',
    homeText = 'home',
    workText = 'work',
    buttonColor,
    size = '96px',
    variant,
    onMobileClick,
    onClose,
    onLocationUpdateClick,
    dataContact,
    isInteractionButtonDisabled = false
  }) => {
    const [active, setActive] = useState<string>(videoText);

    const handleSetActive = (actionType: string) => {
      setActive(actionType);
    };

    const handleContactVideoCallButtonClick = (actionType: string) => {
      setActive(actionType);
      onVideoClick && onVideoClick(dataContact);
    };

    const handleContactMobileCallButtonClick = (actionType: string, mobileNumber: string) => {
      setActive(actionType);
      onMobileClick && onMobileClick(mobileNumber, dataContact.contactSubscriberId);
    };

    // varinat
    const getProfileImage = () => {
      switch (variant) {
      case ProfileVariant.ActiveCall:
        return ContactInformationImage;
      case ProfileVariant.Member:
        return ContactInformationImage;
      case ProfileVariant.NonMember:
        return NonMemberImage;
      default:
        return ProfileIcon;
      }
    };

    return (
      <>
        <div className="contact-information-style">
          {!dataContact.contactSubscriberId ? (
            <div
              className="contact-information-top-style"
              style={{
                background:
                  'linear-gradient(74.21deg, #252161 32.31%, #29247A 71%)',
              }}
            >
              <span className="contact-information-image-style">
                <Image width="24px" url={Xicon} onClick={onClose} />
              </span>
            </div>
          ) : (
            <div className="contact-information-top-style">
              <span className="contact-information-image-style">
                <Image width="24px" url={Xicon} onClick={onClose} />
              </span>
            </div>
          )}
          <div>
            <span className="contact-information-profile-image-style">
              <CircleImage
                size={size}
                borderColor="#FFFFFF"
                position="relative"
                background="#fff"
                zIndex="99"
                border="none"
                url={dataContact.url}
              />
              {variant === ProfileVariant.NonMember ? (
                ''
              ) : (
                <span className="contact-information-profile-logo-icon">
                  <Image width="16px" url={ProfileIcon} />
                </span>
              )}
            </span>
          </div>

          <div className="contact-informaion-middle-style">
            <div className="contact-information-middle-one-style">
              <span className="contact-information-text-style">
                <Text fontSize={24}>{dataContact.contactName}</Text>
              </span>
            </div>
            <div>
              {variant === ProfileVariant.Member ? (
                <Button
                  variant={ButtonVariant.ButtonImageRound}
                  width="61px"
                  height="21px"
                  text="Away"
                  color="#F6DDCC"
                  textColor="#DC7835"
                >
                  {OrangDot && <OrangDot />}
                </Button>
              ) : variant === ProfileVariant.NonMember ? (
                <Button
                  variant={ButtonVariant.ButtonImageRound}
                  width="61px"
                  height="21px"
                  text="Offline"
                  color="#FFCBCE"
                  textColor="#DC3545"
                >
                  {RedDot && <RedDot />}
                </Button>
              ) : (
                <Button
                  variant={ButtonVariant.ButtonImageRound}
                  width="61px"
                  height="21px"
                  text="Active"
                  color="#D0F3C6"
                  textColor="#02A57D"
                >
                  {GreenDot && <GreenDot />}
                </Button>
              )}
            </div>
            <div className="contact-information-button-main-style">
              {!dataContact.contactSubscriberId ? (
                <>
                  <div className="contact-information-button-style">
                    {mobileText && (
                      <Button
                        variant={ButtonVariant.Square}
                        disabled={isInteractionButtonDisabled}
                        onClick={() => handleContactMobileCallButtonClick(mobileText, dataContact.contact)}
                        width="64px"
                        height="64px"
                        text={mobileText}
                        textColor={
                          active === mobileText ? '#FFFFFF' : buttonColor
                        }
                        color={
                          active === mobileText
                            ? Colors.LSDarkTeal
                            : buttonColor
                        }
                      >
                        {active === mobileText
                          ? MobileButtonIcon && (
                            <MobileButtonIcon
                              stroke={Colors.LSWhite}
                              width={24}
                              height={24}
                            />
                          )
                          : MobileButtonIcon && (
                            <MobileButtonIcon width={24} height={24} />
                          )}
                      </Button>
                    )}
                  </div>
                  {dataContact.home && <div className="contact-information-button-style">
                    {homeText && (
                      <Button
                        disabled={isInteractionButtonDisabled}
                        variant={ButtonVariant.Square}
                        onClick={() => handleContactMobileCallButtonClick(homeText, dataContact.home??'')}
                        width="64px"
                        height="64px"
                        text={homeText}
                        textColor={
                          active === homeText ? '#FFFFFF' : buttonColor
                        }
                        color={
                          active === homeText ? Colors.LSDarkTeal : buttonColor
                        }
                      >
                        {active === homeText
                          ? HomeButtonIcon && (
                            <HomeButtonIcon
                              stroke={Colors.LSWhite}
                              width={24}
                              height={24}
                            />
                          )
                          : HomeButtonIcon && (
                            <HomeButtonIcon width={24} height={24} />
                          )}{' '}
                      </Button>
                    )}
                  </div>}
                  {dataContact.work && <div className="contact-information-button-style">
                    {workText && (
                      <Button
                        disabled={isInteractionButtonDisabled}
                        variant={ButtonVariant.Square}
                        onClick={() => handleContactMobileCallButtonClick(workText, dataContact.work??'')}
                        width="64px"
                        height="64px"
                        text={workText}
                        textColor={
                          active === workText ? '#FFFFFF' : buttonColor
                        }
                        color={
                          active === workText ? Colors.LSDarkTeal : buttonColor
                        }
                      >
                        {active === workText
                          ? HomeButtonIcon && (
                            <HomeButtonIcon
                              stroke={Colors.LSWhite}
                              width={24}
                              height={24}
                            />
                          )
                          : HomeButtonIcon && (
                            <HomeButtonIcon width={24} height={24} />
                          )}{' '}
                      </Button>
                    )}
                  </div>}
                </>
              ) : (
                <>
                  <div
                    className="contact-information-button-style">
                    {videoText && (
                      <Button
                        disabled={isInteractionButtonDisabled}
                        variant={ButtonVariant.Square}
                        onClick={() => handleContactVideoCallButtonClick(videoText)}
                        width="64px"
                        height="64px"
                        text={videoText}
                        textColor={
                          active === videoText ? '#FFFFFF' : buttonColor
                        }
                        color={
                          active === videoText ? Colors.LSDarkTeal : buttonColor
                        }
                      >
                        {active === videoText
                          ? VideoButtonWhiteIcon && (
                            <VideoButtonWhiteIcon width={24} height={24} />
                          )
                          : VideoButtonIcon && (
                            <VideoButtonIcon width={24} height={24} />
                          )}
                      </Button>
                    )}
                  </div>
                  <div
                    className="contact-information-button-style">
                    {mobileText && (
                      <Button
                        disabled={isInteractionButtonDisabled}
                        variant={ButtonVariant.Square}
                        onClick={() => handleContactMobileCallButtonClick(mobileText, dataContact.contact)}
                        width="64px"
                        height="64px"
                        text={mobileText}
                        textColor={
                          active === mobileText ? '#FFFFFF' : buttonColor
                        }
                        color={
                          active === mobileText
                            ? Colors.LSDarkTeal
                            : buttonColor
                        }
                      >
                        {active === mobileText
                          ? MobileButtonIcon && (
                            <MobileButtonIcon
                              stroke={Colors.LSWhite}
                              width={24}
                              height={24}
                            />
                          )
                          : MobileButtonIcon && (
                            <MobileButtonIcon width={24} height={24} />
                          )}
                      </Button>
                    )}
                  </div>
                  {dataContact.home && (
                    <div
                      className="contact-information-button-style">
                      {homeText && (
                        <Button
                          disabled={isInteractionButtonDisabled}
                          variant={ButtonVariant.Square}
                          onClick={() => handleContactMobileCallButtonClick(homeText, dataContact.home??'')}
                          width="64px"
                          height="64px"
                          text={homeText}
                          textColor={
                            active === homeText ? '#FFFFFF' : buttonColor
                          }
                          color={
                            active === homeText
                              ? Colors.LSDarkTeal
                              : buttonColor
                          }
                        >
                          {active === homeText
                            ? HomeButtonIcon && (
                              <HomeButtonIcon
                                stroke={Colors.LSWhite}
                                width={24}
                                height={24}
                              />
                            )
                            : HomeButtonIcon && (
                              <HomeButtonIcon width={24} height={24} />
                            )}{' '}
                        </Button>
                      )}
                    </div>
                  )}
                  {dataContact.work && (
                    <div
                      className="contact-information-button-style">
                      {workText && (
                        <Button
                          disabled={isInteractionButtonDisabled}
                          variant={ButtonVariant.Square}
                          onClick={() => handleContactMobileCallButtonClick(workText, dataContact.work??'')}
                          width="64px"
                          height="64px"
                          text={workText}
                          textColor={
                            active === workText ? '#FFFFFF' : buttonColor
                          }
                          color={
                            active === workText
                              ? Colors.LSDarkTeal
                              : buttonColor
                          }
                        >
                          {active === workText
                            ? WorkButtonIcon && (
                              <WorkButtonIcon
                                stroke={Colors.LSWhite}
                                width={24}
                                height={24}
                              />
                            )
                            : WorkButtonIcon && (
                              <WorkButtonIcon width={24} height={24} />
                            )}
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="contact-information-content">
              {variant === ProfileVariant.NonMember ? (
                <>
                  <div className="contact-information-ls-contact-numbers-style">
                    <ContactInformationPanelWrap>
                      <div
                        style={{
                          display: 'flex',
                          fontFamily: 'helvetica',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div className="contact-information-detail-style">
                          <Text
                            fontSize={12}
                            margin="0px 0px"
                            color={Colors.NeutralGrey1}
                          >
                            mobile
                          </Text>
                          <Text
                            fontSize={15}
                            margin="0px 0px"
                            lineHeight="1.5"
                            color={Colors.LSDarkTeal}
                          >
                            {dataContact.contact}
                          </Text>
                        </div>

                        <div className="watch-me-timer-session-column-button-wrap">
                          <Image
                            width="32px"
                            margin="0 15px 0 0"
                            url={CtaMobile}
                          />
                        </div>
                      </div>
                    </ContactInformationPanelWrap>
                    {dataContact.home && (
                      <ContactInformationPanelWrap>
                        <div className="contact-information-container">
                          <div className="contact-information-detail-style">
                            <Text
                              fontSize={12}
                              margin="0px 0px"
                              color={Colors.NeutralGrey1}
                            >
                              home
                            </Text>
                            <Text
                              fontSize={15}
                              margin="0px 0px"
                              lineHeight="1.5"
                              color={Colors.LSDarkTeal}
                            >
                              {dataContact.home}
                            </Text>
                          </div>

                          <div className="watch-me-timer-session-column-button-wrap">
                            <Image
                              width="32px"
                              margin="0 15px 0 0"
                              url={CtaHome}
                            />
                          </div>
                        </div>
                      </ContactInformationPanelWrap>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <ContactInformationPanelWrap>
                    {dataContact.contactSubscriberId && <div className="contact-information-container">
                      <div className="contact-information-detail-style">
                        <Text
                          fontSize={12}
                          margin="0px 0px"
                          color={Colors.NeutralGrey1}
                        >
                          current location
                        </Text>
                        <Text
                          fontSize={15}
                          margin="0px 0px"
                          lineHeight="1.5"
                          color={Colors.LSDarkTeal}
                        >
                          {dataContact.currentLocation}
                        </Text>
                      </div>

                      <div className="watch-me-timer-session-column-button-wrap">
                        <Image
                          width="32px"
                          margin="0 15px 0 0"
                          url={Images.CTA}
                          onClick={() => onLocationUpdateClick && onLocationUpdateClick()}
                        />
                      </div>
                    </div>}
                  </ContactInformationPanelWrap>
                  <ContactInformationPanelWrap>
                    {dataContact.contactSubscriberId && <div className="contact-information-container">
                      <div className="contact-information-detail-style">
                        <Text
                          fontSize={12}
                          margin="0px 0px"
                          color={Colors.NeutralGrey1}
                        >
                          distance
                        </Text>
                        <Text
                          fontSize={15}
                          margin="0px 0px"
                          lineHeight="1.5"
                          color={Colors.LSDarkTeal}
                        >
                          {dataContact.distance}
                        </Text>
                      </div>

                      <div className="watch-me-timer-session-column-button-wrap">
                        <Image
                          width="32px"
                          margin="0 15px 0 0"
                          url={Images.CtaLocation}
                        />
                      </div>
                    </div>}
                  </ContactInformationPanelWrap>
                  <div className="contact-information-ls-contact-numbers-style">
                    <ContactInformationPanelWrap>
                      {dataContact.contactSubscriberId && <div className="contact-information-container">
                        <div className="contact-information-detail-style">
                          <Text
                            fontSize={12}
                            margin="0px 0px"
                            color={Colors.NeutralGrey1}
                          >
                            Lifestream
                          </Text>
                          <Text
                            fontSize={15}
                            margin="0px 0px"
                            lineHeight="1.5"
                            color={Colors.LSDarkTeal}
                          >
                            {dataContact.contactName}
                          </Text>
                        </div>

                        <div className="watch-me-timer-session-column-button-wrap contact-info-btn">
                          <Button variant={ButtonVariant.ButtonImage} disabled={isInteractionButtonDisabled}>
                            <Image
                              width="32px"
                              margin="0 15px 0 0"
                              url={CtaVideo}
                              onClick={() => onVideoClick && onVideoClick(dataContact)}
                            />
                          </Button>
                        </div>
                      </div>}
                    </ContactInformationPanelWrap>

                    <ContactInformationPanelWrap>
                      <div className="contact-information-container">
                        <div className="contact-information-detail-style">
                          <Text
                            fontSize={12}
                            margin="0px 0px"
                            color={Colors.NeutralGrey1}
                          >
                            mobile
                          </Text>
                          <Text
                            fontSize={15}
                            margin="0px 0px"
                            lineHeight="1.5"
                            color={Colors.LSDarkTeal}
                          >
                            {dataContact.contact}
                          </Text>
                        </div>

                        <div className="watch-me-timer-session-column-button-wrap contact-info-btn">
                          <Button variant={ButtonVariant.ButtonImage} disabled={isInteractionButtonDisabled}>
                            <Image
                              width="32px"
                              margin="0 15px 0 0"
                              url={CtaMobile}
                              onClick={() => onMobileClick && onMobileClick(dataContact.contact, dataContact.contactSubscriberId)}
                            />
                          </Button>
                        </div>
                      </div>
                    </ContactInformationPanelWrap>

                    {dataContact.home && (
                      <ContactInformationPanelWrap>
                        <div className="contact-information-container">
                          <div className="contact-information-detail-style">
                            <Text
                              fontSize={12}
                              margin="0px 0px"
                              color={Colors.NeutralGrey1}
                            >
                              home
                            </Text>
                            <Text
                              fontSize={15}
                              margin="0px 0px"
                              lineHeight="1.5"
                              color={Colors.LSDarkTeal}
                            >
                              {dataContact.home}
                            </Text>
                          </div>

                          <div className="watch-me-timer-session-column-button-wrap contact-info-btn">
                            <Button variant={ButtonVariant.ButtonImage} disabled={isInteractionButtonDisabled}>
                              <Image
                                width="32px"
                                margin="0 15px 0 0"
                                url={CtaHome}
                                onClick={() => onMobileClick && onMobileClick(dataContact.home??'', dataContact.contactSubscriberId)}
                              />
                            </Button>
                          </div>
                        </div>
                      </ContactInformationPanelWrap>
                    )}

                    {dataContact.work && (
                      <ContactInformationPanelWrap>
                        <div className="contact-information-container">
                          <div className="contact-information-detail-style">
                            <Text
                              fontSize={12}
                              margin="0px 0px"
                              color={Colors.NeutralGrey1}
                            >
                              work
                            </Text>
                            <Text
                              fontSize={15}
                              margin="0px 0px"
                              lineHeight="1.5"
                              color={Colors.LSDarkTeal}
                            >
                              {dataContact.work}
                            </Text>
                          </div>

                          <div className="watch-me-timer-session-column-button-wrap contact-info-btn">
                            <Button variant={ButtonVariant.ButtonImage} disabled={isInteractionButtonDisabled}>
                              <Image
                                width="32px"
                                margin="0 15px 0 0"
                                url={CtaHome}
                                onClick={() => onMobileClick && onMobileClick(dataContact.work??'', dataContact.contactSubscriberId)}
                              />
                            </Button>
                          </div>
                        </div>
                      </ContactInformationPanelWrap>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
